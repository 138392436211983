import * as React from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { BinaryUploadFoldersFormFields } from "../../../types";
import { uploaderBridge } from "../../../../../../../../services";
import { BinaryUploadField } from "../../../../../components";
import { FolderSelector } from "../../../../../../../../components";

export const AppLaunchField: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<
    BinaryUploadFoldersFormFields
  >();
  const { t } = useTranslation();

  const { binaryFolder } = values;

  const onSelect = async () => {
    const [uploadFilePath] = await uploaderBridge.openFileDialog(
      "",
      binaryFolder
    );

    setFieldValue("appLaunch", uploadFilePath);
  };

  const validateAppLaunch = React.useCallback(
    (appLaunchPath: string) => {
      if (!Boolean(binaryFolder) || !Boolean(appLaunchPath)) {
        return;
      }

      if (appLaunchPath.includes(binaryFolder)) {
        return;
      }

      return t("upload_binaries.folders.errors.app_launch");
    },
    [t, binaryFolder]
  );

  return (
    <BinaryUploadField
      asterisk
      label={t("upload_binaries.folders.app_launch.label")}
      description={t("upload_binaries.folders.app_launch.description")}
    >
      <Field name="appLaunch" validate={validateAppLaunch}>
        {({ field: { value }, form: { errors } }: FieldProps) => {
          const error = String(errors.appLaunch ?? "");

          return (
            <FolderSelector
              value={value}
              onSelect={onSelect}
              error={error}
              inputProps={{
                disabled: true,
                variant: "outlined",
                ...(!Boolean(value) && {
                  label: t("upload_binaries.folders.app_launch.placeholder"),
                }),
              }}
            />
          );
        }}
      </Field>
    </BinaryUploadField>
  );
};
