import * as React from "react";
import axios from "axios";

import { useOffers } from "./useOffers";
import { BulkItems, OfferType, Status } from "../types";
import { bulkGetItems } from "../../../../services/api/offer";

export function useItems(
  sandboxId: string
): {
  status: Status;
  bulkItems: BulkItems;
} {
  const [status, setStatus] = React.useState<Status>(Status.REQUEST);
  const [bulkItems, setBulkItems] = React.useState<BulkItems>({});
  const { offers } = useOffers(sandboxId);
  const source = axios.CancelToken.source();

  React.useEffect(() => {
    if (offers.length === 0 || !sandboxId) return;

    const audienceItemIds = offers
      .filter((offer) => offer.offerType !== OfferType.EDITION)
      .flatMap((offer) => offer.items.map(({ id }) => id));

    setStatus(Status.REQUEST);
    bulkGetItems(sandboxId, {
      includeSubItemDetails: true,
      id: audienceItemIds,
    })
      .then((data: BulkItems) => {
        setBulkItems(data);
        setStatus(Status.SUCCESS);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setStatus(Status.ERROR);
        }
      });

    return () => source.cancel();
  }, [offers, sandboxId]);

  return { status, bulkItems };
}
