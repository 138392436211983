import * as React from "react";
import { useSelector } from "react-redux";

import { selectAppParams } from "../../../../store/selectors/app.selectors";
import { useSandboxTags } from "../hooks/useSandboxTags";
import { useItems } from "../hooks/useItems";
import { useOffers } from "../hooks/useOffers";
import { Offer, BulkItems, UseSandboxTags } from "../types";

export type OffersDataContextProps = {
  offers: Offer[];
  bulkItems: BulkItems;
  sandboxTags: UseSandboxTags;
};

export const OffersDataContext = React.createContext<OffersDataContextProps>(
  {} as any
);

export const OffersDataProvider: React.FC = ({ children }) => {
  const appParams = useSelector(selectAppParams);
  const { sandboxid } = appParams;

  const { bulkItems } = useItems(sandboxid!);
  const { offers } = useOffers(sandboxid!);
  const sandboxTags = useSandboxTags(sandboxid!);

  const context = React.useMemo(
    () => ({
      offers,
      bulkItems,
      sandboxTags,
    }),
    [offers, bulkItems, sandboxTags]
  );

  return (
    <OffersDataContext.Provider value={context}>
      {children}
    </OffersDataContext.Provider>
  );
};

export const useOffersData = (): OffersDataContextProps => {
  const context = React.useContext(OffersDataContext);
  if (context === undefined) {
    throw new Error("useOffersData must be used within an OffersDataProvider");
  }
  return context;
};
