import { createSelector } from "@reduxjs/toolkit";

import { selectAppParams } from "./app.selectors";
import { RootState } from "../reducers";

const selectGame = (state: RootState) => state.game;

export const selectIsGameLoading = createSelector(
  selectGame,
  (game): boolean => game.isLoading
);

export const selectGameTitle = createSelector(
  selectAppParams,
  (params): string => params.title || ""
);
