import { Platform } from "@epic-mod-market/core";

import { AppMode, EntityType } from "../../../models";
import { AppParams } from "./commonBridge";

export class CommonBridgeMockService {
  private accessToken = "accessToken";

  public getAccessToken = async (): Promise<string> => {
    return this.accessToken;
  };

  public getAppParams = async (): Promise<AppParams> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          entitytype: EntityType.MOD,
          action: AppMode.UPLOAD,
          title: "test",
          bisplatformselectionenabled: false,
          supportedplatforms: [Platform.Mac, Platform.Win64],
          buildroot: "C:/Epic/game",
          organizationid: "o-sln4sdc4k3bhat7vu8lb9vlmwfetwp",
          productid: "fbc06ed01115419faaecd5b16f9ef427",
          entityid: "2f97a60350ae49159b849d515fd9c76f",
          locale: "en-US",
        });
      }, 400);
    });
  };
}
