import { LabelName, Platform } from "@epic-mod-market/core";

export interface BinariesLists {
  activeBinaries: Binary[];
  inactiveBinaries: Binary[];
}

export interface Binary {
  artifactId: string;
  buildVersion: string;
  labels: BinaryLabel[];
  updated: Date;
  created: Date;
  description?: string;
}

export type TakenPlatforms = Partial<Record<Platform, Binary["buildVersion"]>>;

export interface BinaryLabel {
  labelName?: LabelName;
  platform: Platform;
  sandboxId?: string;
  buildVersion?: string;
  description?: string;
}

export enum ErrorReferrerPage {
  UPLOAD_BINARIES = "UPLOAD_BINARIES",
  ACTIVATE_BINARY = "ACTIVATE_BINARY",
}

export enum IncomingPlatformValues {
  mac = "mac",
  Mac = "Mac",
  linux = "linux",
  win32 = "win32",
  Win32 = "Win32",
  win64 = "win64",
  Win64 = "Win64",
  Windows = "Windows",
}
enum DisplayedPlatformValues {
  Mac = "Mac",
  Linux = "Linux",
  Win32 = "Win32",
  Windows = "Windows",
}
export const platformsMap = {
  [IncomingPlatformValues.mac]: DisplayedPlatformValues.Mac,
  [IncomingPlatformValues.Mac]: DisplayedPlatformValues.Mac,
  [IncomingPlatformValues.linux]: DisplayedPlatformValues.Linux,
  [IncomingPlatformValues.win32]: DisplayedPlatformValues.Win32,
  [IncomingPlatformValues.Win32]: DisplayedPlatformValues.Win32,
  [IncomingPlatformValues.Win64]: DisplayedPlatformValues.Windows,
  [IncomingPlatformValues.win64]: DisplayedPlatformValues.Windows,
  [IncomingPlatformValues.Windows]: DisplayedPlatformValues.Windows,
};
