import { AlertOutline } from "@epic-ui/icons";
import { Box, Button, Link, Typography } from "@material-ui/core";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { FooterButtons } from "../../../../../components";
import { uploaderBridge, UploadParams } from "../../../../../services";
import { BinaryUploadPage } from "../../../../common";
import { UploadErrorNotification } from "../../components/BinaryUploadHeader.styles";
import { useBinaryUploadData } from "../../data";
import { ErrorReferrerPage } from "../../types";

interface ErrorPageProps {
  startBinaryUpload: (uploadParams: UploadParams) => void;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ startBinaryUpload }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { uploadParams } = useBinaryUploadData();
  const location = useLocation<{
    errorcode: string;
    errormessages: string[];
    supporturl: string;
    referrerPage: ErrorReferrerPage;
    buildVersion?: string;
  }>();
  const {
    errormessages,
    errorcode,
    supporturl,
    referrerPage,
    buildVersion,
  } = location.state;

  const handleCancel = () => {
    history.push("/upload/game/folders");
  };

  const handleRetry = () => {
    startBinaryUpload(uploadParams);
  };

  const handleOpenSupportUrl = () => {
    uploaderBridge.openSupportPage(supporturl);
  };

  const goBackToActivateBinary = () => {
    history.push("/upload/game/activate-binary", { buildVersion });
  };

  return (
    <BinaryUploadPage
      header={
        <>
          <UploadErrorNotification>
            <AlertOutline />
            <Typography variant="body1">
              {referrerPage === ErrorReferrerPage.UPLOAD_BINARIES
                ? t("upload_binaries.error")
                : t("activate_binary.error")}
            </Typography>
          </UploadErrorNotification>

          <Typography color="textPrimary" variant="h2" align="center">
            {referrerPage === ErrorReferrerPage.UPLOAD_BINARIES
              ? t("upload_binaries.title")
              : t("activate_binary.title")}
          </Typography>
        </>
      }
      footer={
        referrerPage === ErrorReferrerPage.UPLOAD_BINARIES ? (
          <FooterButtons
            onClose={handleCancel}
            onConfirm={handleRetry}
            closeLabel={t("upload_binaries.go_back")}
            confirmLabel={t("common.retry")}
            size="large"
          />
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={goBackToActivateBinary}
            size="large"
          >
            {t("upload_binaries.go_back")}
          </Button>
        )
      }
      centeredContent
    >
      <Box textAlign="center">
        <Typography color="textSecondary">
          <Trans
            i18nKey="upload_binaries.folders.error_code"
            values={{
              errorCode: errorcode,
            }}
            components={{
              a: (
                <Link
                  onClick={handleOpenSupportUrl}
                  component="button"
                  underline="hover"
                  color="primary"
                />
              ),
            }}
          />
        </Typography>
        <Typography color="textSecondary">{errormessages}</Typography>
      </Box>
    </BinaryUploadPage>
  );
};
