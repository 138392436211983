import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router";
import { Form, FormikValues, useFormikContext } from "formik";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { InWishlist } from "@epic-ui/icons";
import { Platform } from "@epic-mod-market/core";

import { uploaderBridge } from "../../../../../services";
import { TakenPlatforms } from "../../types";
import { BinaryUploadPage } from "../../../../common";
import { FooterButtons } from "../../../../../components";
import { BinaryUploadField } from "../../components";
import { DistributionGroupSelect, PlatformItem } from "./components";
import {
  StyledDivider,
  PlatformList,
  Description,
  SuccessfulUploadNotification,
} from "./ActiveBinaryForm.styles";
import { useBinaryUploadData } from "../../data";

interface ActivateBinaryFormProps {
  takenPlatforms: TakenPlatforms;
  buildVersion: string;
}

const PLATFORMS_ORDER = [Platform.Win64, Platform.Mac, Platform.Win32];

export const ActivateBinaryForm: React.FC<ActivateBinaryFormProps> = ({
  takenPlatforms,
  buildVersion,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    values,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<FormikValues>();
  const {
    shouldConfirmationPageBeShown,
    setShouldConfirmationPageBeShown,
    setSelectedPlatformsState,
    binaries: { isListLoading },
  } = useBinaryUploadData();

  const { selectedPlatforms } = values;
  const isPlatformSet = selectedPlatforms.length !== 0;

  React.useEffect(() => {
    setShouldConfirmationPageBeShown(isPlatformSet);
    setSelectedPlatformsState(selectedPlatforms);
  }, [selectedPlatforms]);

  const isNextButtonDisabled = !isPlatformSet || isListLoading;

  const handleToggle = React.useCallback(
    (platform: Platform) => {
      const updatedPlatforms = selectedPlatforms.includes(platform)
        ? selectedPlatforms.filter((item) => item !== platform)
        : [...selectedPlatforms, platform];
      setFieldValue("selectedPlatforms", updatedPlatforms);
    },
    [selectedPlatforms, setFieldValue]
  );

  const handleCancel = () => {
    if (shouldConfirmationPageBeShown) {
      history.push("/upload/game/close");
    } else {
      uploaderBridge.onClose();
    }
  };

  return (
    <BinaryUploadPage
      header={
        <>
          <SuccessfulUploadNotification>
            <InWishlist />
            <Typography variant="body1">
              {t("activate_binary.upload_status.success")}
            </Typography>
          </SuccessfulUploadNotification>
          <Typography variant="h2">{t("activate_binary.title")}</Typography>
        </>
      }
      footer={
        <FooterButtons
          disabled={isNextButtonDisabled}
          onClose={handleCancel}
          onConfirm={handleSubmit}
          closeLabel={t("activate_binary.footer_buttons.close")}
          confirmLabel={t("common.next")}
          isLoading={isSubmitting}
        />
      }
    >
      <Form>
        <Box mb={3}>
          <Trans
            i18nKey="activate_binary.description"
            components={{
              text: <Description />,
              span: <span />,
            }}
          />
        </Box>
        <Box mb={6}>
          <DistributionGroupSelect />
        </Box>
        <BinaryUploadField label={t("activate_binary.select_platforms.title")}>
          {isListLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="primary" size={25} />
            </Box>
          ) : (
            <PlatformList aria-label="platform selection">
              {PLATFORMS_ORDER.map((platform: Platform, index: number) => (
                <React.Fragment key={index}>
                  {index !== 0 && <StyledDivider />}
                  <div>
                    <PlatformItem
                      key={index}
                      selected={selectedPlatforms.includes(platform)}
                      nameOfBuildPlatformAssignedTo={takenPlatforms[platform]}
                      platform={platform}
                      onChange={handleToggle}
                      buildVersion={buildVersion}
                    />
                  </div>
                </React.Fragment>
              ))}
            </PlatformList>
          )}
        </BinaryUploadField>
      </Form>
    </BinaryUploadPage>
  );
};
