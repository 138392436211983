import root from "window-or-global";

export enum OS {
  Mac = "Mac",
  Windows = "Windows",
}

export const getOS = (): OS | undefined => {
  if (!root.navigator) {
    return undefined;
  }

  const { userAgent } = root.navigator;

  if (userAgent?.includes(OS.Mac)) {
    return OS.Mac;
  }

  if (userAgent?.includes(OS.Windows)) {
    return OS.Windows;
  }

  return undefined;
};
