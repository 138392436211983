import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import { Close, Pause, Play } from "@epic-ui/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { selectModTitle } from "../../../../store/selectors/mod.selectors";
import { SafeContainer, Title } from "../../../../components";
import { Button } from "../../../../components/epic-ui-components";
import { DownloadState } from "../../../../services/api";
import { Page } from "../../../common";
import { useDownloadState } from "../../DownloadStateProvider";
import { InstallStats, Progress } from "./components";

const ICON_BUTTON_SIZE = 12;

const IconButtonStyled = styled(IconButton)`
  &&:hover {
    background: none;
  }

  &&& .MuiSvgIcon-root {
    width: ${ICON_BUTTON_SIZE}px;
    height: ${ICON_BUTTON_SIZE}px;
  }
`;

const ProgressLabel = styled(Typography)`
  && {
    font-weight: ${(p) => p.theme.typography.fontWeightBold};
  }

  text-transform: uppercase;
`;

export const DownloadProgressPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    updateProgress,
    downloadProgressState,
    cancelDownload,
    pauseDownload,
    resumeDownload,
    resetDownloadState,
  } = useDownloadState();
  const title = useSelector(selectModTitle);

  React.useEffect(() => {
    updateProgress();

    return () => {
      resetDownloadState();
    };
  }, []);

  const { state, downloadProgress } = downloadProgressState;
  const progress = Math.floor(downloadProgress);

  const toggleButton =
    state === DownloadState.Paused ? (
      <IconButtonStyled size="small" onClick={resumeDownload}>
        <Play />
      </IconButtonStyled>
    ) : (
      <IconButtonStyled size="small" onClick={pauseDownload}>
        <Pause />
      </IconButtonStyled>
    );

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <Button variant="contained" color="secondary" onClick={cancelDownload}>
          {t("common.cancel")}
        </Button>
      }
    >
      <SafeContainer justify="flex-start">
        <Typography variant="body1" color="textSecondary">
          {t("download.progress_title")}
        </Typography>
        <Box mt={3}>
          <Typography variant="body1" align="left">
            {t("download.builds")}
          </Typography>
        </Box>
        <Box width="100%" mt={3}>
          <Progress value={progress}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              ml={5}
              mr={4}
            >
              <ProgressLabel variant="caption" color="textPrimary">
                {t("download.progress", { progress })}
              </ProgressLabel>
              <Box display="flex" alignItems="center">
                <IconButtonStyled size="small" onClick={cancelDownload}>
                  <Close />
                </IconButtonStyled>
                <Box display="flex" height={ICON_BUTTON_SIZE} mx={2}>
                  <Divider orientation="vertical" flexItem />
                </Box>
                {toggleButton}
              </Box>
            </Box>
          </Progress>
          <Box mt={6}>
            <Typography variant="body1" align="left">
              {t("download.progress.details")}
            </Typography>
          </Box>
          <InstallStats />
        </Box>
      </SafeContainer>
    </Page>
  );
};
