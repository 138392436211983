import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router";
import { useTranslation } from "react-i18next";

import { SandboxModel } from "@epic-mod-market/core";
import { LoadingPage } from "../../common";
import { uploaderBridge, UploadErrorCode } from "../../../services";
import { fetchModUploadData } from "../../../store/reducers/mod.reducer";
import {
  selectOrganizationId,
  selectLocale,
} from "../../../store/selectors/app.selectors";
import { selectModSlug } from "../../../store/selectors/mod.selectors";
import {
  selectProductId,
  selectProductSandboxId,
} from "../../../store/selectors/product.selectors";
import { useUploadState } from "../../UploadStateProvider";
import { bridgeError, clientError } from "../../../store/reducers/app.reducer";
import { ModSelectionPage } from "./ModSelectionPage/ModSelectionPage";
import {
  isUploadReady,
  selectUploadPlatforms,
} from "../../../store/selectors/upload.selectors";
import { UploadProgressPage, UploadCompletePage } from "../common/pages";
import { ModFolderPage, ModPlatformsPage } from "../mod";

export const ModUploadPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(selectOrganizationId);
  const productId = useSelector(selectProductId);
  const productSandboxId = useSelector(selectProductSandboxId);
  const modSlug = useSelector(selectModSlug);
  const locale = useSelector(selectLocale);
  const platforms = useSelector(selectUploadPlatforms);
  const isLoading = useSelector(isUploadReady);

  const { resetProgress } = useUploadState();

  const history = useHistory();
  const isPrivateProductSandbox = SandboxModel.isPrivateSandbox(
    productSandboxId
  );

  React.useEffect(() => {
    if (modSlug) {
      dispatch(
        fetchModUploadData({
          productId,
          productSandboxId,
          organizationId,
          modSlug,
          locale,
        })
      );
    }
  }, [dispatch, organizationId, productId, productSandboxId, modSlug, locale]);

  React.useEffect(() => {
    // TODO: move to thunk
    uploaderBridge.registerUploadCompleteCallback(
      ({ errorcode, errormessages = [], supporturl }) => {
        if (errorcode === UploadErrorCode.OK) {
          return history.push("/upload/mod/success");
        }

        if (errorcode === UploadErrorCode.CanceledByUser) {
          resetProgress();
          return history.push("/upload");
        }

        if (errormessages.length) {
          resetProgress();
          dispatch(bridgeError({ errorcode, errormessages, supporturl }));
        }
      }
    );
  }, [dispatch, history, resetProgress]);

  const { path } = useRouteMatch();

  if (isLoading) {
    return <LoadingPage description={t("common.data_loading_in_progress")} />;
  }

  // TODO: set correct error message
  if (!modSlug && isPrivateProductSandbox) {
    console.error(
      "The error has been thrown because no entityId was provided for private product sandbox."
    );
    dispatch(
      clientError({
        errorCode: 403,
      })
    );
  }

  return (
    <Switch>
      <Route exact path={`${path}/file-upload`}>
        <UploadProgressPage />
      </Route>

      <Route exact path={`${path}/success`}>
        <UploadCompletePage />
      </Route>

      <Route exact path={`${path}/folder`}>
        <ModFolderPage />
      </Route>

      <Route exact path={`${path}/platforms`}>
        <ModPlatformsPage />
      </Route>

      <Route exact path={`${path}/select`}>
        <ModSelectionPage />
      </Route>

      {!modSlug && <Redirect to="/upload/mod/select" />}

      {!platforms && <Redirect to="/upload/mod/platforms" />}

      <Redirect to="/upload/mod/folder" />
    </Switch>
  );
};
