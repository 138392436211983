import * as React from "react";
import { useTranslation } from "react-i18next";
import { Field, FormikValues, useFormikContext } from "formik";
import { MenuItem, Tooltip } from "@material-ui/core";

import { TextField } from "../../../../../../../components";
import { BinaryUploadField } from "../../../../components";

const DISTRIBUTION_GROUP_LIST = ["Global", "Local"];

export const DistributionGroupSelect: React.FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  return (
    <BinaryUploadField
      label={t("activate_binary.distribution_group_dropdown.title")}
      description={t("activate_binary.distribution_group_dropdown.description")}
    >
      <Tooltip
        title={t<string>(
          "activate_binary.distribution_group_dropdown.disabled_tooltip"
        )}
        placement="bottom"
      >
        <div>
          <Field
            value={values.distribution}
            component={TextField}
            label={t("activate_binary.distribution_group_dropdown.label")}
            variant="outlined"
            name="distribution"
            fullWidth
            select
            disabled
          >
            {DISTRIBUTION_GROUP_LIST.map((value) => (
              <MenuItem
                key={value}
                value={value}
                onClick={() => {
                  setFieldValue("distribution", value);
                }}
              >
                {value}
              </MenuItem>
            ))}
          </Field>
        </div>
      </Tooltip>
    </BinaryUploadField>
  );
};
