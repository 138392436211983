import React from "react";
import { Box, Link as MuiLink, LinkProps, makeStyles } from "@material-ui/core";

const useClasses = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "pre-line",
    color: theme.palette.primary.main,
  },
}));

export const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  const classes = useClasses();

  return (
    <Box
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      clone
    >
      <MuiLink TypographyClasses={classes} {...props}>
        {children}
      </MuiLink>
    </Box>
  );
};
