import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonProps, CircularProgress } from "@material-ui/core";
import { ArrowStrokeLeft, ArrowStrokeRight } from "@epic-ui/icons";

import { Button } from "./epic-ui-components";
import { ButtonWithTooltip } from "./ButtonWithTooltip";

interface FooterButtonsProps {
  onConfirm: () => void;
  onClose?: () => void;
  confirmLabel?: string;
  closeLabel?: string;
  isLoading?: boolean;
  disabled?: boolean;
  nextButtonTooltipText?: string;
  showNextButtonTooltip?: boolean;
  size?: ButtonProps["size"];
}

export const FooterButtons: React.FC<FooterButtonsProps> = ({
  onConfirm,
  onClose,
  confirmLabel,
  closeLabel,
  disabled = false,
  isLoading = false,
  nextButtonTooltipText = "",
  showNextButtonTooltip = false,
  size = "medium",
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center">
      {onClose && (
        <Box mr={4} width="100%">
          <Button
            variant="contained"
            color="secondary"
            startIcon={!closeLabel && <ArrowStrokeLeft />}
            fullWidth
            onClick={onClose}
            size={size}
          >
            {closeLabel || t("common.previous")}
          </Button>
        </Box>
      )}
      <Box width="100%">
        <ButtonWithTooltip
          showTooltip={showNextButtonTooltip}
          tooltipText={nextButtonTooltipText}
          onClick={onConfirm}
          buttonProps={{
            disabled: disabled || isLoading,
            variant: "contained",
            color: "primary",
            endIcon: !isLoading && !confirmLabel && <ArrowStrokeRight />,
            fullWidth: true,
            size,
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            confirmLabel || t("common.next")
          )}
        </ButtonWithTooltip>
      </Box>
    </Box>
  );
};
