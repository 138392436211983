import * as React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Platform } from "@epic-mod-market/core";
import { Box } from "@material-ui/core";

import {
  Title,
  SafeContainer,
  Description,
  FooterButtons,
} from "../../../../components";
import { Page } from "../../../common";
import { MultipleSelect } from "../../../../components";
import { selectEntityTitle } from "../../../../store/selectors/entity.selectors";
import { MisconfiguredPlatformsLabel } from "./MisconfiguredPlatformsLabel";

const PlatformLabel: Record<string, string> = {
  Mac: "Mac",
  Windows: "Win64",
  Win32: "Win32",
};

interface Props {
  handleSelect: (platforms: Platform[]) => void;
  availablePlatforms: Platform[];
  platforms?: Platform[];
  isPlatformsConfigured: boolean;
  handleClose?: () => void;
  closeLabel?: string;
  confirmLabel?: string;
}

export const PlatformSelect: React.FC<Props> = ({
  availablePlatforms,
  platforms = [],
  isPlatformsConfigured,
  handleSelect,
  handleClose,
  closeLabel,
  confirmLabel,
}) => {
  const { t } = useTranslation();
  const title = useSelector(selectEntityTitle);

  const [selectedPlatforms, setSelectedPlatforms] = React.useState<Platform[]>(
    platforms
  );

  const options = availablePlatforms.map((name) => ({
    value: name,
    label: PlatformLabel[name],
  }));

  const isMisconfiguredPlatformsLabelVisible =
    !isPlatformsConfigured && !selectedPlatforms.length;

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <FooterButtons
          onClose={handleClose}
          onConfirm={() => handleSelect(selectedPlatforms)}
          disabled={!selectedPlatforms.length}
          closeLabel={closeLabel}
          confirmLabel={confirmLabel}
        />
      }
    >
      <SafeContainer>
        <Description mb={6}>
          {t("upload.what_are_the_supported_platforms")}
        </Description>
        <MultipleSelect
          options={options}
          label={t("upload.supported_platforms")}
          value={selectedPlatforms}
          onChange={(e) => {
            setSelectedPlatforms(e.target.value as Platform[]);
          }}
        />
        <Box mt={2}>
          <MisconfiguredPlatformsLabel
            isVisible={isMisconfiguredPlatformsLabelVisible}
          />
        </Box>
      </SafeContainer>
    </Page>
  );
};
