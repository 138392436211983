import axios from "axios";
// TODO: think of where Game restrictions type should come from
import { ModRestrictions as GameRestrictions } from "@epic-mod-market/core";

import { APIRequest, bptServiceURL, unwrapResponse } from "../core";

export const gameClient = axios.create({
  baseURL: bptServiceURL,
});

export interface GameMetaParams {
  productId: string;
}

const getRestrictions: APIRequest<GameRestrictions, GameMetaParams> = ({
  productId,
}) => {
  return gameClient.get(`/game/${productId}/restrictions`).then(unwrapResponse);
};

export const gameApi = {
  getRestrictions,
};
