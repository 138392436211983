import * as React from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { BinaryUploadField } from "../../../../../components";
import { FolderSelector } from "../../../../../../../../components";
import { BinaryUploadFoldersFormFields } from "../../../types";

interface BinaryFolderFieldProps {
  onSelect: (name: string) => () => void;
}

export const BinaryFolderField: React.FC<BinaryFolderFieldProps> = ({
  onSelect,
}) => {
  const {
    values: { localCacheFolder },
  } = useFormikContext<BinaryUploadFoldersFormFields>();
  const { t } = useTranslation();

  const validate = React.useCallback(
    (binaryFolder: string) => {
      if (!localCacheFolder) {
        return;
      }

      if (binaryFolder === localCacheFolder) {
        return t(
          "upload_binaries.folders.errors.binary_folder.same_as_local_cache"
        );
      }

      if (binaryFolder.includes(localCacheFolder)) {
        return t(
          "upload_binaries.folders.errors.binary_folder.inside_local_cache"
        );
      }
    },
    [t, localCacheFolder]
  );

  return (
    <BinaryUploadField
      asterisk
      label={t("upload_binaries.folders.binary_folder.label")}
      description={t("upload_binaries.folders.binary_folder.description")}
    >
      <Field name="binaryFolder" validate={validate}>
        {({ field: { value, name }, form: { errors } }: FieldProps) => {
          const error = String(errors[name] ?? "");

          return (
            <FolderSelector
              value={value}
              onSelect={onSelect(name)}
              error={error}
              inputProps={{
                disabled: true,
                variant: "outlined",
                ...(!Boolean(value) && {
                  label: t("upload_binaries.folders.binary_folder.placeholder"),
                }),
              }}
            />
          );
        }}
      </Field>
    </BinaryUploadField>
  );
};
