import { createSelector } from "@reduxjs/toolkit";

import { Platform } from "@epic-mod-market/core";
import { RootState } from "../reducers";
import { ProductState } from "../reducers/product.reducer";
import { selectAppParams } from "./app.selectors";

const selectProductSlice = (state: RootState): ProductState => state.product;

const selectProduct = createSelector(
  selectProductSlice,
  (productSlice) => productSlice.product
);

export const selectIsModMarketSuspended = createSelector(
  selectProduct,
  (product): boolean | undefined => product?.isModMarketSuspended
);

export const selectProductId = createSelector(
  selectProduct,
  selectAppParams,
  (product, appParams): string => product?.id || appParams.productid || ""
);

export const selectProductSandboxId = createSelector(
  selectProduct,
  selectAppParams,
  (product, appParams): string =>
    product?.sandboxId || appParams.productnamespaceid || ""
);

export const selectAvailablePlatforms = createSelector(
  selectProduct,
  (product): Platform[] => product?.platforms || []
);

export const selectIsPlatformsConfigured = createSelector(
  selectAvailablePlatforms,
  (platforms): boolean => platforms.length > 0
);
