import * as React from "react";
import { Tooltip } from "@material-ui/core";
import { GuidelineOutline } from "@epic-ui/icons";
import { TooltipProps } from "@material-ui/core/Tooltip/Tooltip";

import { GuidelineHolder } from "./GuidelineTooltip.styles";

interface GuidelineTooltipProps {
  title: string | React.ReactElement;
  placement?: TooltipProps["placement"];
}

export const GuidelineTooltip: React.FC<GuidelineTooltipProps> = ({
  title,
  placement,
}) => {
  return (
    <Tooltip title={title} placement={placement}>
      <GuidelineHolder>
        <GuidelineOutline />
      </GuidelineHolder>
    </Tooltip>
  );
};
