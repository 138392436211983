import { SignInCompleteCallback } from "./common";
import {
  UploadStateChangeCallback,
  UploadErrorCode,
  UploadParams,
} from "./uploaderBridge";

export class UploadBridgeMockService {
  private signInCompleteCallback: SignInCompleteCallback<
    UploadErrorCode
  > = () => undefined;
  private onStateChange: UploadStateChangeCallback = () => undefined;
  private fileUploadingState = 0;
  private timerID: number | undefined;
  private timeoutID: number | undefined;

  private folder = "C:/Epic/game";

  private doUpload = async () => {
    if (this.fileUploadingState >= 100 || this.fileUploadingState < 0) {
      this.timerID && clearTimeout(this.timerID);
      return;
    }

    await new Promise((resolve) => {
      this.timerID && clearTimeout(this.timerID);

      this.timerID = setTimeout(() => {
        this.fileUploadingState += 10;
        resolve();
        this.onStateChange({
          progress: this.fileUploadingState,
        });
      }, 300);
    });

    this.doUpload();
  };

  private terminateUpload = () => {
    this.timerID && clearTimeout(this.timerID);
    this.fileUploadingState = -1;
  };

  private startUpload = () => {
    this.fileUploadingState = 0;
    this.doUpload();
  };

  public registerSignInCompleteCallback = async (
    signInCompleteCallback: SignInCompleteCallback<UploadErrorCode>
  ): Promise<void> => {
    this.signInCompleteCallback = signInCompleteCallback;

    this.timeoutID && clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      return this.signInCompleteCallback({
        errorcode: UploadErrorCode.OK,
        errormessages: [],
        supporturl: "https://google.com/",
      });
    }, 2000);
  };

  public registerUploadStateChangeCallback = async (
    stateChangeCallback: UploadStateChangeCallback
  ): Promise<void> => {
    this.onStateChange = stateChangeCallback;
  };

  public onBrowseButton = async (): Promise<string> => {
    let input: HTMLInputElement | null = document.createElement("input");
    input.type = "file";
    input.click();
    input = null;

    return this.folder;
  };

  public upload = async (params: UploadParams): Promise<void> => {
    this.doUpload();
  };

  public onCancel = async (): Promise<void> => {
    this.terminateUpload();
  };

  public onClose = async (): Promise<void> => {
    window.location.reload();
  };

  public getModTitle = async (): Promise<string> => {
    return "Daedric Reaper Armor and Sword";
  };

  public getSupportedPlatforms = async (): Promise<string[]> => {
    return ["Mac", "Windows"];
  };

  public setSupportedPlatforms = async (): Promise<void> => {
    return;
  };

  public getSignInState = async (): Promise<number> => {
    return 1;
  };

  public getIsPlatformSelectionEnabled = async (): Promise<boolean> => {
    return false;
  };

  public openSupportPage = async (supportUrl: string) => {
    window.open(supportUrl, "_blank");
  };

  public registerUploadCompleteCallback = async (): Promise<void> => {};

  public openFileDialog = async (...params): Promise<string[]> => {
    return [""];
  };

  public openDirectoryDialog = async (...params): Promise<string> => {
    return "";
  };

  public registerPreExitCallback = async (): Promise<void> => {
    return;
  };

  public preventFromExit = async (): Promise<void> => {
    return;
  };

  public generateCloudDirectory = async (): Promise<string> => {
    return "";
  };
}
