import { AxiosError } from "axios";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Organization } from "@epic-mod-market/core";

import { AppSignInState } from "../../managers";
import {
  commonBridge,
  downloaderBridge,
  uploaderBridge,
  commonApi,
  AppParams,
} from "../../services";
import {
  ErrorType,
  BridgeError,
  ErrorCode,
  ClientError,
} from "../../models/error";
import { selectAppMode } from "../selectors/app.selectors";
import { adaptAppParams } from "../../utils/app.utils";
import { updateFolder as updateUploadFolder } from "./upload.reducer";
import { AppMode } from "../../models";
import {
  GlobalTypes,
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from "./common";

export interface AppError {
  type: ErrorType | "";
  errorCode: ErrorCode;
  errorMessages: string[];
  supportUrl: string;
  reason?: string;
  [param: string]: any;
}

export interface AppParamsState extends AppParams {
  isLoading: boolean;
}

interface AppState {
  signInStatus: AppSignInState;
  params: AppParamsState;
  initialParams: AppParamsState;
  isLoading: boolean;
  error: AppError;
  organizations: {
    items: Organization[];
  };
  organizationId: string;
}

export const appInitialState: AppState = {
  signInStatus: AppSignInState.LOADING,
  params: { action: null, entitytype: null, productid: "", isLoading: true },
  initialParams: {
    action: null,
    entitytype: null,
    productid: "",
    isLoading: true,
  },
  isLoading: false,
  error: {
    errorMessages: [],
    errorCode: "",
    supportUrl: "",
    type: "",
  },
  organizations: {
    items: [],
  },
  organizationId: "",
};

export const fetchOrganizations = createAsyncThunk(
  `${GlobalTypes.GetData}/app/fetchOrganizations`,
  async (_: void, { dispatch }): Promise<Organization[] | never> => {
    try {
      return await commonApi.getOrganizations();
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

const appSlice = createSlice({
  name: "app",
  initialState: appInitialState,
  reducers: {
    signInSuccess(state) {
      state.signInStatus = AppSignInState.LOADED;
    },

    updateParams(state, action) {
      state.params = {
        ...state.params,
        ...action.payload,
        isLoading: false,
      };
    },

    clientError(state, action: PayloadAction<ClientError>) {
      state.error = {
        ...appInitialState.error,
        ...action.payload,
        type: ErrorType.CLIENT,
      };
    },

    updateInitialParams(state, action) {
      state.initialParams = {
        ...state.params,
        ...action.payload,
        isLoading: false,
      };
    },

    bridgeError(state, action: PayloadAction<BridgeError>) {
      const { errormessages = [], supporturl = "", errorcode } = action.payload;

      state.error = {
        errorMessages: errormessages,
        supportUrl: supporturl,
        errorCode: errorcode,
        type: ErrorType.BRIDGE,
      };
    },

    serverError(state, action: PayloadAction<AxiosError>) {
      const errorCode = action.payload.response?.status || 500;

      state.error = {
        ...appInitialState.error,
        ...action.payload.response?.data,
        errorCode,
        type: ErrorType.SERVER,
      };
    },

    clearError(state) {
      state.error = appInitialState.error;
    },

    setOrganizationId(state, action) {
      state.organizationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizations.items = action.payload;
      })
      .addMatcher(isPendingAction(GlobalTypes.GetData), (state) => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilledAction(GlobalTypes.GetData), (state) => {
        state.isLoading = false;
      })
      .addMatcher(isRejectedAction(GlobalTypes.GetData), (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  signInSuccess,
  bridgeError,
  serverError,
  clientError,
  updateParams,
  updateInitialParams,
  clearError,
  setOrganizationId,
} = appSlice.actions;

export const fetchAppParams = () => async (dispatch) => {
  const appParams = await commonBridge.getAppParams();

  // temporary hack since bridge always return entityId
  if (appParams.entityid === "{EntityId}") {
    delete appParams.entityid;
  }

  const adaptedAppParams = adaptAppParams(appParams);

  const { action, buildroot = "" } = adaptedAppParams;

  if (action === AppMode.UPLOAD) {
    dispatch(updateUploadFolder(buildroot));
  }

  dispatch(updateParams(adaptedAppParams));
  dispatch(updateInitialParams(adaptedAppParams));
};

export const closeApp = () => (dispatch, getState) => {
  const appMode = selectAppMode(getState());

  if (appMode === AppMode.DOWNLOAD) {
    downloaderBridge.onClose();
  }

  if (appMode === AppMode.UPLOAD) {
    uploaderBridge.onClose();
  }
};

export const openSupportPage = (supportUrl: string) => (dispatch, getState) => {
  const appMode = selectAppMode(getState());

  if (appMode === AppMode.UPLOAD) {
    uploaderBridge.openSupportPage(supportUrl);
  }

  if (appMode === AppMode.DOWNLOAD) {
    downloaderBridge.openSupportPage(supportUrl);
  }
};

export default appSlice.reducer;
