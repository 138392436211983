import * as React from "react";
import { Tooltip } from "@material-ui/core";
import { ButtonTypeMap } from "@material-ui/core/Button/Button";

import { Button } from "./epic-ui-components";

interface ButtonWithTooltipProps {
  onClick: () => void;
  tooltipText: string;
  showTooltip: boolean;
  buttonProps?: ButtonTypeMap["props"];
  children: React.ReactNode;
}

export const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = ({
  onClick,
  children,
  tooltipText,
  showTooltip,
  buttonProps = {},
}) => {
  const button = (
    <Button onClick={onClick} {...buttonProps}>
      {children}
    </Button>
  );

  return showTooltip ? (
    <Tooltip title={tooltipText}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};
