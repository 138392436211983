import * as React from "react";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import styled from "styled-components";

import { useOverflow } from "../pages/hooks";
import { ellipsis } from "../styles";
import { TextField } from "./epic-ui-components";

const useTooltipClasses = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 250,
    margin: theme.spacing(2, 0),
  },
}));

const Container = styled.div`
  direction: rtl;
  text-align: left;
  ${ellipsis()}
`;

interface FolderPathProps {
  value: string;
}

export const FolderPath: React.FC<FolderPathProps> = ({ value }) => {
  const classes = useTooltipClasses();

  const folderRef: React.RefObject<HTMLDivElement> = React.useRef<
    HTMLDivElement
  >(null);
  const { isXOverflow: shouldFolderPathTooltipOpen } = useOverflow(folderRef);

  return (
    <Tooltip
      title={value}
      classes={classes}
      disableHoverListener={!shouldFolderPathTooltipOpen}
      disableFocusListener
      disableTouchListener
      arrow
    >
      <TextField
        disabled
        variant="filled"
        fullWidth
        InputProps={{
          inputComponent: () => (
            <Box
              display="flex"
              width="100%"
              height="60px"
              alignItems="center"
              pl={3}
              pr={3}
            >
              <Container ref={folderRef}>{value}</Container>
            </Box>
          ),
        }}
      />
    </Tooltip>
  );
};
