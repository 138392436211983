import { createSelector } from "@reduxjs/toolkit";
import { Organization, Platform } from "@epic-mod-market/core";

import { AppSignInState } from "../../managers";
import { AppMode, EntityType } from "../../models";
import { RootState } from "../reducers";
import { AppError, AppParamsState } from "../reducers/app.reducer";

export const selectApp = (state: RootState) => state.app;

export const selectAppParams = createSelector(
  selectApp,
  (app): AppParamsState => app.params
);

export const selectInitialAppParams = createSelector(
  selectApp,
  (app): AppParamsState => app.initialParams
);

export const selectSignInStatus = createSelector(
  selectApp,
  (app): AppSignInState => app.signInStatus
);

export const selectAppIsLoading = createSelector(
  selectApp,
  (app): boolean => app.isLoading
);

export const selectAppParamsIsLoading = createSelector(
  selectAppParams,
  (params): boolean => params.isLoading
);

export const selectAppMode = createSelector(
  selectAppParams,
  (params): AppMode | null => params.action
);

export const selectEntityType = createSelector(
  selectAppParams,
  (params): EntityType | null => params.entitytype
);

export const selectAppError = createSelector(
  selectApp,
  (app): AppError => app.error
);

export const selectSupportsMultiplePlatforms = createSelector(
  selectAppParams,
  (params): boolean => {
    const {
      bisplatformselectionenabled = false,
      supportedplatforms = [],
    } = params;

    return bisplatformselectionenabled && supportedplatforms.length > 1;
  }
);

export const selectSupportedPlatforms = createSelector(
  selectAppParams,
  (params): Platform[] => params.supportedplatforms || []
);

export const selectOrganizationId = createSelector(
  selectApp,
  (app): string => app.organizationId || app.params.organizationid || ""
);

export const selectOrganizations = createSelector(
  selectApp,
  (app): Organization[] => app.organizations.items
);

export const selectLocale = createSelector(
  selectAppParams,
  (params): string => params.locale || "en-US"
);

export const selectSandboxId = createSelector(
  selectAppParams,
  (params): string => params.sandboxid || ""
);

export const selectEntityId = createSelector(
  selectAppParams,
  (params): string => params.entityid || ""
);

export const selectInitialEntityId = createSelector(
  selectInitialAppParams,
  (params): string => params.entityid || ""
);

export const selectTitle = createSelector(
  selectAppParams,
  (params): string => params.title || ""
);
