import { createSelector } from "@reduxjs/toolkit";
import { Platform } from "@epic-mod-market/core";

import { RootState } from "../reducers";
import { UploadParams } from "../reducers/upload.reducer";
import { selectAppIsLoading } from "./app.selectors";
import { selectModSlug } from "./mod.selectors";

const selectUpload = (state: RootState) => state.upload;

export const selectUploadFolder = createSelector(
  selectUpload,
  (upload): string => upload.folder
);

export const selectUploadParams = createSelector(
  selectUpload,
  (upload): UploadParams | undefined => upload.params
);

export const selectIsPlatformSelectionEnabled = createSelector(
  selectUploadParams,
  (params): boolean => params?.isPlatformSelectionEnabled || false
);

export const selectUploadPlatforms = createSelector(
  selectUploadParams,
  (params): Platform[] | undefined => params?.platforms
);

export const selectIsPlatformsConfigured = createSelector(
  selectUploadParams,
  (params): boolean => !!params?.isPlatformsConfigured
);

export const isUploadReady = createSelector(
  selectUploadParams,
  selectModSlug,
  selectAppIsLoading,
  (uploadParams, modSlug, isAppLoading): boolean =>
    (!uploadParams && !!modSlug) || isAppLoading
);
