import { createBridgeCaller, UEBridgeName } from "../BridgeApi";
import {
  InstallerBridge,
  FileOperationView,
  SubscribeCallback,
} from "../installerBridge";

// TODO: handle case with 2 bridges per app
const executeInstallerBridgeCall = createBridgeCaller(
  "installer" as UEBridgeName,
  {
    log: () => {},
  }
);

export const installerLauncher: InstallerBridge = {
  /**
   * Set metadata on launcher about view parameters for install stats
   */
  setFileOperationView: (view: FileOperationView): Promise<void> =>
    executeInstallerBridgeCall("setfileoperationview", [view]),

  /**
   * Subscribe to event
   */
  subscribe: (type: string, callback: SubscribeCallback): Promise<number> =>
    executeInstallerBridgeCall("subscribe", [type, callback]),

  /**
   * Add a given url to the launcher's history stack
   */
  unsubscribe: (subscriptionId: number): Promise<boolean> =>
    executeInstallerBridgeCall("unsubscribe", [subscriptionId]),
};
