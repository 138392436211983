import * as React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { uploaderBridge } from "../../../../../../../services";
import { FooterButtons } from "../../../../../../../components";
import { BinaryUploadFoldersFormFields } from "../../types";
import { useBinaryUploadData } from "../../../../data";

export const BinaryUploadFoldersFooter: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { shouldConfirmationPageBeShown } = useBinaryUploadData();
  const { values, handleSubmit, isValid } = useFormikContext<
    BinaryUploadFoldersFormFields
  >();

  const isRequiredFieldsAbsent = React.useMemo(() => {
    const { binaryFolder, appLaunch, buildVersion } = values;

    return [binaryFolder, appLaunch, buildVersion].some(
      (field) => !Boolean(field)
    );
  }, [values]);

  const disabled = React.useMemo(() => {
    return isRequiredFieldsAbsent || !isValid;
  }, [isRequiredFieldsAbsent, isValid]);

  const nextButtonTooltipText = React.useMemo(() => {
    if (!disabled) {
      return "";
    }

    if (!isValid) {
      return t("upload_binaries.button.next.error.incorrect");
    }

    if (isRequiredFieldsAbsent) {
      return t("upload_binaries.folders.next.tooltip");
    }
  }, [t, disabled, isRequiredFieldsAbsent, isValid]);

  const handleClose = () => {
    if (shouldConfirmationPageBeShown) {
      history.push("/upload/game/close");
    } else {
      uploaderBridge.onClose();
    }
  };

  return (
    <FooterButtons
      onClose={handleClose}
      onConfirm={handleSubmit}
      disabled={disabled}
      showNextButtonTooltip={disabled}
      nextButtonTooltipText={nextButtonTooltipText}
      closeLabel={t("common.close")}
      confirmLabel={t("common.next")}
      size="large"
    />
  );
};
