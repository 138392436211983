import { Platform } from "@epic-mod-market/core";
import axios, { AxiosPromise } from "axios";

import { BinariesLists } from "../../../pages/upload/product-binaries/types";

export const binaryClient = axios.create({
  baseURL: `${process.env.REACT_APP_EPIC_DEVPORTAL_URL}/api/v2`,
});

const productLifecycleUrl = "/services/product-lifecycle";
export const getBinariesWithPlatformHistory = (
  productId: string,
  sandboxId: string,
  artifactId: string
): Promise<BinariesLists> => {
  return binaryClient
    .get(
      `${productLifecycleUrl}/products/${productId}/sandboxes/${sandboxId}/artifacts/${artifactId}/binaries`
    )
    .then((res) => res.data);
};

const artifactUrl = "/self-publishing-tools/artifacts";
export interface UpdateBinaryPlatformsParams {
  organizationId: string;
  productId: string;
  sandboxId: string;
  artifactId: string;
  version: string;
  fields: { platforms: Platform[] };
}

export const updateBinaryPlatforms = (
  params: UpdateBinaryPlatformsParams
): AxiosPromise<void> => {
  const {
    organizationId,
    productId,
    sandboxId,
    artifactId,
    version,
    fields,
  } = params;
  return binaryClient.put<void>(
    `${artifactUrl}/organization/${organizationId}/product/${productId}/sandbox/${sandboxId}/artifact/${artifactId}/buildVersion/${version}/label`,
    fields
  );
};
