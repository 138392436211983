import { createBridgeCaller, resolveBridgeName } from "../BridgeApi";
import { AppParams, CommonBridge } from "../commonBridge";

// C++ does not expose a separate common bridge, following abstraction provided
// for convenience on bpt side
const commonBridgeName = resolveBridgeName();
const executeCommonBridgeCall = createBridgeCaller(commonBridgeName, {
  log: () => {},
});

export const commonLauncher: CommonBridge = {
  getAccessToken: () => executeCommonBridgeCall<string>("queryaccesstoken"),
  getAppParams: () => executeCommonBridgeCall<AppParams>("getappparameters"),
};
