import * as React from "react";
import axios from "axios";

import { Offer, Status } from "../types";
import { getOffersWithPLSStatuses } from "../../../../services/api/offer";
import { hidePrePurchaseOffers, populateSubAndMajorOffers } from "../utils";

export function useOffers(
  sandboxId: string
): {
  status: Status;
  offers: Offer[];
} {
  const [status, setStatus] = React.useState<Status>(Status.REQUEST);
  const [offers, setOffers] = React.useState<Offer[]>([]);
  const source = axios.CancelToken.source();

  const updateOffers = React.useCallback(
    (updatedOffers: Offer[]) => {
      setOffers(
        hidePrePurchaseOffers(populateSubAndMajorOffers(updatedOffers))
      );
    },
    [setOffers]
  );

  const getOffers = async (): Promise<void> => {
    if (!sandboxId) return;

    setStatus(Status.REQUEST);
    return getOffersWithPLSStatuses(sandboxId, source.token)
      .then(({ elements }) => {
        updateOffers(elements);
        setStatus(Status.SUCCESS);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          return setStatus(Status.ERROR);
        }
      });
  };

  React.useEffect(() => {
    getOffers();

    return () => source.cancel();
  }, [sandboxId]);

  return { offers, status };
}
