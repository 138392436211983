import * as React from "react";
import isEmpty from "lodash/isEmpty";

import { BinariesLists, Binary } from "../types";
import { getBinariesWithPlatformHistory } from "../../../../services/api/binary";
import { useSelector } from "react-redux";
import { selectAppParams } from "../../../../store/selectors/app.selectors";
import { AppParams } from "../../../../services";

export interface UseBinariesLists {
  isListLoading: boolean;
  binariesLists: BinariesLists;
  fetchBinariesList: () => void;
  isRequestError: boolean;
}

const filterLabelsForActiveSandbox = (
  binaries: Binary[],
  activeSandboxId: string
) =>
  binaries.map((binary) => {
    return {
      ...binary,
      labels: binary.labels.filter(
        ({ sandboxId }) => sandboxId === activeSandboxId
      ),
    };
  });

export function useBinariesLists(): UseBinariesLists {
  const [binariesLists, setBinariesLists] = React.useState<BinariesLists>({
    activeBinaries: [],
    inactiveBinaries: [],
  });
  const [isListLoading, setIsListLoading] = React.useState(false);
  const [isRequestError, setIsRequestError] = React.useState(false);
  const appParams = useSelector(selectAppParams) as AppParams;
  const {
    organizationid: organizationId,
    productid: productId,
    sandboxid: activeSandboxId,
    artifactid: artifactId,
  } = appParams;

  const fetchBinariesList = async () => {
    try {
      setIsListLoading(true);

      const {
        activeBinaries,
        inactiveBinaries,
      } = await getBinariesWithPlatformHistory(
        productId,
        activeSandboxId as string,
        artifactId as string
      );
      setIsListLoading(false);

      if (isEmpty(activeBinaries) && isEmpty(inactiveBinaries)) {
        return;
      }

      setBinariesLists({
        activeBinaries: filterLabelsForActiveSandbox(
          activeBinaries,
          activeSandboxId as string
        ),
        inactiveBinaries: inactiveBinaries,
      });
    } catch (error) {
      setIsListLoading(false);
      setIsRequestError(true);
    }
  };

  React.useEffect(() => {
    if (!organizationId || !productId || !artifactId) return;

    fetchBinariesList();
  }, [artifactId, organizationId, productId]);

  return {
    binariesLists: {
      activeBinaries: binariesLists.activeBinaries,
      inactiveBinaries: binariesLists.inactiveBinaries,
    },
    isListLoading,
    fetchBinariesList,
    isRequestError,
  };
}
