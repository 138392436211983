export enum ErrorType {
  SERVER = "SERVER",
  BRIDGE = "BRIDGE",
  CLIENT = "CLIENT",
}

export type BridgeError = {
  errorcode: string;
  errormessages?: string[];
  supporturl?: string;
};

export type ClientError = {
  reason?: string;
  errorCode?: ErrorCode;
  [param: string]: any;
};

export type ErrorCode = string | number;
