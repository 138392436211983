import { css } from "emotion";
import React, { Component } from "react";
import PropTypes, { shape } from "prop-types";
import { select } from "d3-selection";
import { scaleLinear, scaleTime } from "d3-scale";
import { axisLeft } from "d3-axis";
import { format } from "d3-format";
import { max, extent } from "d3-array";
import { area, line } from "d3-shape";

const styles = {
  speedline: css({
    opacity: 1,
    strokeWidth: "2px",
  }),
  speedlinetext: css({
    fontWeight: "lighter",
    fontSize: "12px",
    color: "rgba(255, 255, 255, 0.72)",
    fill: "rgba(255, 255, 255, 0.72)",
  }),
  line: css({
    fill: "none",
    strokeWidth: "3px",
  }),
  area: css({
    strokeWidth: 0,
  }),
  focus: css({
    "& circle": {
      fill: "none",
      stroke: "white",
      strokeWidth: "2px",
    },
  }),
};

// TODO: copied from diesel-src/services/diesel-site/node/app/scripts/client/updater-drawer/components/install-stats/charts/SpeedChart.js
export class SpeedChart extends Component {
  static propTypes = {
    id: PropTypes.string,
    colors: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    margin: shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number,
    }),
    data: PropTypes.array,
    timePeriod: PropTypes.number,
  };

  static defaultProps = {
    height: 60,
    margin: {
      top: 5,
      right: 70,
      bottom: 5,
      left: 0,
    },
    colors: ["#a6c6d6", "#90b1cb"],
    data: [],
    timePeriod: 30000,
  };

  constructor(props) {
    super(props);

    const { id, height, width, margin } = props;

    const useableHeight = height - margin.top - margin.bottom;
    const useableWidth = width - margin.left - margin.right;

    this.x = scaleTime().range([0, useableWidth]);
    this.y = scaleLinear().range([useableHeight, 0]);

    // define the line
    this.valueline = line()
      .x((d) => {
        return this.x(d.date);
      })
      .y((d) => {
        return this.y(d[id]);
      });

    // define the area
    this.area = area()
      .x((d) => {
        return this.x(d.date);
      })
      .y0(useableHeight + 1)
      .y1((d) => {
        return this.y(d[id]);
      });

    this.d3formatMemory = format(".3s");
  }

  componentDidMount() {
    const { id, width, height, margin, colors, data } = this.props;

    const svg = select(this._rootNode)
      .append("svg")
      .style("id", id)
      .style("width", width)
      .style("height", height);

    const defs = svg.append("defs");

    const gradient = defs
      .append("linearGradient")
      .attr("id", `areaGradient-${id}`)
      .attr("x1", "100%")
      .attr("x2", "0%")
      .attr("y1", "100%")
      .attr("y2", "95%");

    gradient
      .append("stop")
      .attr("class", "start")
      .attr("offset", "0%")
      .attr("stop-color", colors[1])
      .attr("stop-opacity", 0.5);

    gradient
      .append("stop")
      .attr("class", "end")
      .attr("offset", "90%")
      .attr("stop-color", colors[1])
      .attr("stop-opacity", 0);

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    this.x.domain(
      extent(data, (d) => {
        return d.date;
      })
    );
    this.y.domain([
      0,
      max(data, (d) => {
        return d[id];
      }),
    ]);

    const useableWidth = width - margin.left - margin.right;

    axisLeft(this.y)
      .tickFormat(this.formatSpeedPerSecond)
      .tickSize(-useableWidth);

    g.append("path")
      .classed(styles.line, true)
      .attr("id", `${id}-line`)
      .style("stroke", colors[0])
      .attr("d", this.valueline(data));

    g.append("path")
      .classed(styles.area, true)
      .attr("id", `${id}-area`)
      .style("fill", `url(#areaGradient-${id})`)
      .attr("d", this.area(data));

    g.append("line")
      .classed(styles.speedline, true)
      .attr("id", `${id}-speedline`)
      .style("stroke", colors[0])
      .attr("y1", this.y.range()[0])
      .attr("y2", this.y.range()[1]);

    g.append("text")
      .classed(styles.speedlinetext, true)
      .attr("id", `${id}-speedlinetext`)
      .attr("y", this.y.range()[1] + 10)
      .attr("text-anchor", "start");

    const focus = g
      .append("g")
      .classed(styles.focus, true)
      .attr("id", `${id}-focus`)
      .style("stroke", colors[0])
      .style("display", "none");

    focus.append("circle").attr("r", 4.5);

    focus.append("text").attr("x", 9).attr("dy", ".35em");
  }

  formatSpeed = (d, suffix) => {
    const preformattedMemory = `${this.d3formatMemory(d)}B`;
    return suffix ? preformattedMemory + suffix : preformattedMemory;
  };

  formatSpeedPerSecond = (d) => {
    return this.formatSpeed(d, "/s");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id, timePeriod, data } = nextProps;

    if (!data || !data.length) {
      return;
    }

    const extentDate = new Date(data[0].date);
    const missingTime =
      timePeriod - new Date(data[0].date - data[data.length - 1].date);

    extentDate.setTime(extentDate.getTime() + missingTime);

    this.x.domain([data[data.length - 1].date, extentDate]);
    this.y.domain([
      0,
      max(data, (d) => {
        return d[id];
      }),
    ]);

    select(`#${id}-line`).attr("d", this.valueline(data));
    select(`#${id}-area`).attr("d", this.area(data));

    const speedlinex = this.x(data[0].date);
    select(`#${id}-speedline`).attr("x1", speedlinex).attr("x2", speedlinex);

    const currentspeed = data[0][id];
    select(`#${id}-speedlinetext`)
      .attr("x", speedlinex + 5)
      .text(this.formatSpeedPerSecond(currentspeed));
  }

  shouldComponentUpdate() {
    // Let D3 entirely control dom of things in this component. Prevents component re-rendering via React.
    return false;
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    return (
      <div className="speed-chart-container" ref={this._setRef.bind(this)} />
    );
  }
}
