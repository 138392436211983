import * as React from "react";
import styled from "styled-components";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";

import { Time, TimeFormat } from "../../../../../components";
import { Build } from "../hooks";

const FormControlLabelStyled = styled(FormControlLabel)`
  && {
    align-items: start;
    margin: ${(p) => p.theme.spacing(0, 0, 2, 0)};
    padding-bottom: ${(p) => p.theme.spacing(1)}px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.palette.secondary.light};
  }

  .MuiCheckbox-root {
    padding-top: 0;
    padding-left: 0;
  }
`;

interface BuildListProps {
  builds: Build[];
  isBuildSelected: (id: string) => boolean;
  handleSelectBuild: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BuildList: React.FC<BuildListProps> = ({
  builds,
  isBuildSelected,
  handleSelectBuild,
}) => {
  const { t } = useTranslation();

  return (
    <Box width="80%" m="0 auto">
      <FormGroup>
        {builds.map(({ id, version, platformLabel, created }) => (
          <FormControlLabelStyled
            key={id}
            control={
              <Checkbox
                value={id}
                checked={isBuildSelected(id)}
                onChange={handleSelectBuild}
              />
            }
            label={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography variant="body1">
                  {t("common.build_version", { version })}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <Trans
                    i18nKey="common.uploaded_date"
                    components={[
                      <Time
                        key={0}
                        timestamp={created}
                        format={TimeFormat.datetime}
                      />,
                    ]}
                  />
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {platformLabel}
                </Typography>
              </Box>
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
};
