export function debounce(func: Function, delay: number) {
  let timerId: number | undefined;

  return function (...args) {
    clearTimeout(timerId);
    timerId = undefined;

    // @ts-ignore
    timerId = setTimeout(() => func.apply(this, args), delay);
  };
}
