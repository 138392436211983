import styled from "styled-components";

export const GuidelineHolder = styled.span`
  cursor: pointer;
  opacity: 0.5;
  transition: opacity ${(p) => p.theme.transitions.easing.easeInOut}
    ${(p) => p.theme.transitions.duration.short}ms;

  &:hover {
    opacity: 1;
  }
`;
