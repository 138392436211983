import * as React from "react";
import { IconProps } from "./IconWrapper";
import { ChangesNeededIcon } from "./icons/ChangesNeededIcon";
import { PublishedIcon } from "./icons/PublishedIcon";
import { DraftIcon } from "./icons/DraftIcon";
import { PendingApprovalIcon } from "./icons/PendingApprovalIcon";

const icons: Record<string, React.FC> = {
  changesneeded: ChangesNeededIcon,
  published: PublishedIcon,
  draft: DraftIcon,
  pendingapproval: PendingApprovalIcon,
};

type Ref = HTMLDivElement;

export type IconKey = keyof typeof icons;

interface Props extends Pick<IconProps, Exclude<keyof IconProps, "children">> {
  name: IconKey;
}

interface IconCompoundComponent {
  names?: string[];
}

type IconCompoundType = React.ForwardRefExoticComponent<
  Props & React.RefAttributes<HTMLDivElement>
> &
  IconCompoundComponent;

export const Icon: IconCompoundType = React.forwardRef<Ref, Props>(
  ({ name, ...props }, ref) => {
    const SvgIcon = icons[name];

    if (!SvgIcon) {
      const avaliableIcons = Object.keys(icons).join(", ");
      console.warn(
        `Icon with name '${name}' does not exist. Avaliable icons are: ${avaliableIcons}`
      );

      return null;
    }

    return <SvgIcon forwardedRef={ref} {...props} />;
  }
);

Icon.names = Object.keys(icons);
