import { OfferItem } from "./OfferItem";

export const DEFAULT_OFFERS_COUNTRY = "US";
export const MAX_OFFERS_COUNT = 1000;

export interface UpdateOfferBody {
  tags: number[];
  status?: OfferStatus | ProductLifecycleStatus;
}

export enum OfferType {
  BASE_GAME = "BASE_GAME",
  BUNDLE = "BUNDLE",
  EDITION = "EDITION",
  DEMO = "DEMO",
  ADD_ON = "ADD_ON",
  SEASON_PASS = "DLC",
  CONSUMABLE = "CONSUMABLE",
  DIGITAL_EXTRA = "DIGITAL_EXTRA",
}

enum OfferStatus {
  ACTIVE = "ACTIVE",
  SUNSET = "SUNSET",
  DELETED = "DELETED",
}
enum ProductLifecycleStatus {
  READY_FOR_PROMOTION = "READY_FOR_PROMOTION",
  SENT_TO_STAGE = "SENT_TO_STAGE",
  STAGED = "STAGED",
  BLOCKED = "BLOCKED",
  READY_FOR_REVIEW = "READY_FOR_REVIEW",
  SENT_FOR_REVIEW = "SENT_FOR_REVIEW",
  IN_REVIEW = "IN_REVIEW",
  APPROVED = "APPROVED",
  CHANGES_NEEDED = "CHANGES_NEEDED",
  PUBLISH_PENDING = "PUBLISH_PENDING",
  PUBLISHED = "PUBLISHED",
}

interface SubOfferDependency {
  nsOfferId: string;
}

export interface Offer {
  id: string;
  offerType: OfferType;
  status: OfferStatus | ProductLifecycleStatus;
  items: OfferItem[];
  tags: number[];
  subOfferDependencies: SubOfferDependency[];
  prePurchase: boolean;
}
