import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { Button } from "../../../../../components";
import { BinaryUploadPage } from "../../../../common";
import { uploaderBridge } from "../../../../../services";
import { useHistory } from "react-router";

export const CloseConfirmationPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onCancel = () => {
    history.goBack();
  };

  const onConfirm = () => {
    uploaderBridge.onClose();
  };

  return (
    <BinaryUploadPage
      header={
        <Typography color="textPrimary" variant="h2" align="center">
          {t("upload_binaries.close_confirmation.title")}
        </Typography>
      }
      footer={
        <>
          <Box clone mb={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onConfirm}
              size="large"
            >
              {t("common.yes")}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onCancel}
            size="large"
          >
            {t("common.cancel")}
          </Button>
        </>
      }
      centeredContent
    >
      <Box textAlign="center" whiteSpace="pre-line">
        <Typography color="textSecondary">
          {t("upload_binaries.close_confirmation.text")}
        </Typography>
      </Box>
    </BinaryUploadPage>
  );
};
