import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useClasses = makeStyles((theme) => ({
  root: {
    letterSpacing: "-0.5px",
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: "1em",
  },
}));

type Tag = keyof JSX.IntrinsicElements | React.ComponentType<any>;

interface Props {
  tag?: Tag;
}

export const Title: React.FC<Props> = ({ tag = "h1", children }) => {
  const classes = useClasses();

  return (
    <Box maxWidth="100%">
      <Typography
        variant="h1"
        component={tag}
        color="textPrimary"
        classes={classes}
        noWrap
      >
        {children}
      </Typography>
    </Box>
  );
};
