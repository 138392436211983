import * as React from "react";
import uniq from "lodash/uniq";
import pickBy from "lodash/pickBy";
import { Platform } from "@epic-mod-market/core";

import { TakenPlatforms } from "../types";

export const useBinariesToBeLeftWithoutPlatforms = (
  currentPlatformsState: TakenPlatforms,
  editedBinary: string,
  selectedPlatforms: Platform[]
): string[] => {
  return React.useMemo(() => {
    if (selectedPlatforms.length === 0) {
      return [editedBinary];
    }

    const assignedBinariesLeftAfterUpdate: string[] = [];
    const platformsExcludeEditedBinary = pickBy(
      currentPlatformsState,
      (value) => !(value === editedBinary)
    );
    const currentPlatforms = Object.keys(
      platformsExcludeEditedBinary
    ) as Platform[];

    currentPlatforms.forEach((key: Platform) => {
      if (!selectedPlatforms.includes(key)) {
        assignedBinariesLeftAfterUpdate.push(
          platformsExcludeEditedBinary[key] as string
        );
      }
    });

    const binariesToBeLeftWithoutPlatforms: string[] = [];

    currentPlatforms.forEach((key: Platform) => {
      const currentlyAssignedBinary = platformsExcludeEditedBinary[
        key
      ] as string;

      if (!assignedBinariesLeftAfterUpdate.includes(currentlyAssignedBinary)) {
        binariesToBeLeftWithoutPlatforms.push(currentlyAssignedBinary);
      }
    });

    return uniq(binariesToBeLeftWithoutPlatforms);
  }, [editedBinary, currentPlatformsState, selectedPlatforms]);
};
