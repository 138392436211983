import * as React from "react";
import { Box, Typography } from "@material-ui/core";

import { GuidelineTooltip } from "../../../../../components";
import { TypographyWithAsterisk } from "./BinaryUploadField.styles";

interface BinaryUploadFieldProps {
  label: string;
  children: React.ReactNode;
  asterisk?: boolean;
  description?: string | React.ReactElement;
}

export const BinaryUploadField: React.FC<BinaryUploadFieldProps> = ({
  children,
  label,
  asterisk,
  description,
}) => {
  const LabelComponent = asterisk ? TypographyWithAsterisk : Typography;

  return (
    <>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <LabelComponent variant="body1">{label}</LabelComponent>
        {description && (
          <Box mr={2}>
            <GuidelineTooltip title={description} placement="bottom-end" />
          </Box>
        )}
      </Box>
      {children}
    </>
  );
};
