import * as React from "react";
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps,
} from "@material-ui/lab";
import { makeStyles, fade } from "@material-ui/core";
import { LoadingDots } from "@epic-ui/icons";
import { Mod } from "@epic-mod-market/core";

const useStyles = makeStyles((theme) => ({
  focused: {
    '&[aria-expanded="false"]': {
      border: "none",
      "&&& fieldset": {
        borderColor: theme.palette.common.white,
      },
    },
  },

  menuPaper: {
    maxHeight: 160,
  },

  inputRoot: {
    "&&&&": {
      paddingLeft: theme.spacing(4),
    },
  },

  input: {
    "&&&&": {
      paddingLeft: 0,
    },
  },

  popupIndicator: {
    margin: 0,
    "&&&:hover": {
      backgroundColor: "inherit",
    },
  },

  menuItem: {
    margin: theme.spacing(0, 2),
    paddingLeft: theme.spacing(2),
    borderRadius: 4,
    minHeight: 44,
    "&&&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },

    "&&&:hover svg": {
      color: theme.palette.common.white,
    },

    '&[aria-selected="true"]': {
      backgroundColor: fade(theme.palette.common.white, 0.08),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '&[data-focus="true"]': {
      "&&& svg": {
        color: theme.palette.common.white,
      },
    },
  },
}));

type Props<T extends Mod> = AutocompleteProps<T, boolean, boolean, boolean>;

export const Autocomplete = <T extends Mod>({ ...props }: Props<T>) => {
  const classes = useStyles();

  return (
    <MUIAutocomplete
      autoHighlight
      blurOnSelect
      clearOnEscape
      forcePopupIcon
      openOnFocus
      classes={{
        listbox: classes.menuPaper,
        focused: classes.focused,
        popupIndicator: classes.popupIndicator,
        inputRoot: classes.inputRoot,
        input: classes.input,
        option: classes.menuItem,
        ...props.classes,
      }}
      closeIcon={null}
      loadingText={<LoadingDots fontSize="small" />}
      {...props}
    />
  );
};
