import * as React from "react";

import { IconProps, IconWrapper } from "../IconWrapper";

export const ChangesNeededIcon: React.FC<IconProps> = ({ title, ...props }) => (
  <IconWrapper {...props}>
    <title>{title}</title>
    <path
      d="M198.228955,177.755894 L111.341984,7.40311649 C106.305652,-2.4677055 93.694348,-2.4677055 88.6580156,7.40311649 L1.77104483,177.755894 C-3.2652876,187.626716 3.04036438,200 13.1130292,200 L186.886971,200 C196.959636,200 203.265288,187.626716 198.228955,177.755894 Z M100.019563,59.6491228 C105.593104,59.6491228 111.101115,64.8868824 110.937187,71.1907347 L108.29791,135.897924 C108.174965,140.996628 104.486592,145.02924 99.9785814,145.02924 C95.4705706,145.02924 91.7821982,140.950277 91.6592525,135.897924 L89.0609578,71.1907347 C88.938012,64.8868824 94.4050408,59.6491228 100.019563,59.6491228 Z M100.584795,176.216374 C94.7786132,176.216374 89.0935099,171.059628 89.0935099,164.716374 C89.0935099,158.37312 94.7786132,153.216374 100.584795,153.216374 C106.390977,153.216374 112.093895,158.37312 112.093895,164.716374 C112.093895,171.059628 106.390977,176.216374 100.584795,176.216374 Z"
      fillRule="nonzero"
    />
  </IconWrapper>
);
