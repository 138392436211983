import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { Platform } from "@epic-mod-market/core";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";

import { BinaryUploadPage } from "../../../../common";
import {
  useBinariesToBeLeftWithoutPlatforms,
  useUpdateBinaryPlatforms,
  useUpdateOfferPlatforms,
} from "../../hooks";
import { TakenPlatforms } from "../../types";
import { PlatformsToRemoveFromBinaries } from "./components";
import { useBinaryUploadData } from "../../data";

export const RemovePlatformsNotificationPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const { setShouldConfirmationPageBeShown } = useBinaryUploadData();

  const location = useLocation<{
    platformsToRemove: TakenPlatforms;
    takenPlatforms: TakenPlatforms;
    buildVersion: string;
    selectedPlatforms: Platform[];
  }>();

  const {
    platformsToRemove,
    buildVersion,
    takenPlatforms,
    selectedPlatforms,
  } = location.state;

  const binariesWithoutPlatforms = useBinariesToBeLeftWithoutPlatforms(
    takenPlatforms,
    buildVersion,
    selectedPlatforms
  );
  const handleUpdateBinaryPlatforms = useUpdateBinaryPlatforms(buildVersion);
  const { updateOfferPlatforms } = useUpdateOfferPlatforms();

  const hasBinariesWithoutPlatforms = binariesWithoutPlatforms.length > 0;
  const binariesList = binariesWithoutPlatforms
    .map((version) => `v.${version}`)
    .join(", ");

  const handleRemove = async () => {
    setIsLoading(true);
    setShouldConfirmationPageBeShown(false);
    await updateOfferPlatforms({ platforms: selectedPlatforms });
    handleUpdateBinaryPlatforms(selectedPlatforms);
  };

  const handleCancel = () => {
    history.push("/upload/game/activate-binary", {
      buildVersion,
    });
  };

  return (
    <BinaryUploadPage
      header={
        <Typography variant="h2">
          {t("remove_platform_notification.title")}
        </Typography>
      }
      footer={
        <>
          <Box clone mb={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRemove}
              size="large"
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                t(
                  "remove_platform_notification.platforms.remove_button"
                ).toUpperCase()
              )}
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleCancel}
            size="large"
          >
            {t("common.cancel")}
          </Button>
        </>
      }
    >
      <Box whiteSpace="pre-line">
        <PlatformsToRemoveFromBinaries platformsToRemove={platformsToRemove} />
        {hasBinariesWithoutPlatforms && (
          <Box color="warning.light">
            <Typography align="center">
              <Trans
                i18nKey="remove_platform_notification.platforms.remove_last"
                count={binariesWithoutPlatforms.length}
                values={{ binariesList }}
              />
            </Typography>
          </Box>
        )}
      </Box>
    </BinaryUploadPage>
  );
};
