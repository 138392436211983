import * as React from "react";
import { Mod } from "@epic-mod-market/core";

import { getModStatusIconParams } from "../../utils/mod.utils";
import { Icon } from "../Icon/Icon";
import { IconProps } from "../Icon/IconWrapper";
import { makeStyles } from "@material-ui/core";

interface Props extends IconProps {
  mod: Mod;
}

const useStyles = makeStyles(() => ({
  icon: {
    flexShrink: 0,
  },
}));

export const ModStatusIcon: React.FC<Props> = ({ mod, ...iconProps }) => {
  const iconParams = getModStatusIconParams(mod);
  const classes = useStyles();

  if (!iconParams) {
    return null;
  }

  const { name, color } = iconParams;

  return (
    <Icon className={classes.icon} name={name} color={color} {...iconProps} />
  );
};
