import * as React from "react";

interface ContentProps {
  isXOverflow: boolean;
  isYOverflow: boolean;
}

type RefObject<T extends HTMLElement = HTMLElement> = React.RefObject<T>;

export const useOverflow = (ref: RefObject): ContentProps => {
  const [isXOverflow, setIsXOverflow] = React.useState(false);
  const [isYOverflow, setIsYOverflow] = React.useState(false);

  React.useEffect(() => {
    if (!ref || !ref.current) {
      setIsXOverflow(false);
      return;
    }

    setIsXOverflow(ref.current.scrollWidth > ref.current.clientWidth);
  }, [ref?.current?.innerText]);

  React.useEffect(() => {
    if (!ref || !ref.current) {
      setIsYOverflow(false);
      return;
    }

    setIsYOverflow(ref.current.scrollHeight > ref.current.clientHeight);
  }, [ref?.current?.clientHeight]);

  return { isXOverflow, isYOverflow };
};
