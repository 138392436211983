import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { selectInitialEntityId } from "../../../../store/selectors/app.selectors";
import { selectUploadPlatforms } from "../../../../store/selectors/upload.selectors";
import {
  selectIsPlatformsConfigured,
  selectAvailablePlatforms,
} from "../../../../store/selectors/product.selectors";
import { closeApp } from "../../../../store/reducers/app.reducer";
import { updateUploadParams } from "../../../../store/reducers/upload.reducer";
import { useActionHandler } from "../../../hooks";
import { PlatformSelect } from "../../common/platforms";

export const ModPlatformsPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const availablePlatforms = useSelector(selectAvailablePlatforms);
  const initialEntityId = useSelector(selectInitialEntityId);
  const platforms = useSelector(selectUploadPlatforms);
  const isPlatformsConfigured = useSelector(selectIsPlatformsConfigured);

  const { handleActionWithRedirect, handleAction } = useActionHandler();

  const handleSetPlatforms = (platforms) => {
    handleActionWithRedirect(updateUploadParams({ platforms }));
  };

  const handleClose = () => {
    if (!initialEntityId) {
      handleAction(updateUploadParams({ platforms: undefined }));
      return history.push("/upload/mod/select");
    }
    dispatch(closeApp());
  };

  return (
    <PlatformSelect
      handleSelect={handleSetPlatforms}
      availablePlatforms={availablePlatforms}
      platforms={platforms}
      isPlatformsConfigured={isPlatformsConfigured}
      handleClose={handleClose}
      closeLabel={initialEntityId && t("common.cancel")}
    />
  );
};
