import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { rgba } from "polished";

interface Props {
  header?: React.ReactNode;
  footer: React.ReactNode;
  centeredContent?: boolean;
}

const useClasses = makeStyles((theme) => ({
  footer: {
    position: "relative",

    "&::before": {
      content: "''",
      position: "absolute",
      left: "20px",
      right: "20px",
      bottom: "100%",
      zIndex: "1",
      height: `${theme.spacing(9)}px`,
      background: `linear-gradient(
        to top,
        ${theme.palette.grey[900]} 0%,
        ${rgba(theme.palette.grey[900], 0.9)} 30%,
        ${rgba(theme.palette.grey[900], 0)} 100%
      )`,
    },
  },
}));

export const BinaryUploadPage: React.FC<Props> = ({
  header,
  children,
  footer,
  centeredContent,
}) => {
  const classes = useClasses();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Box component="header" px={12} textAlign="center">
        {header}
      </Box>
      <Box
        pt={4}
        px={12}
        pb={10}
        overflow="overlay"
        {...(centeredContent ? {} : { flexGrow: "1" })}
      >
        {children}
      </Box>
      <Box className={classes.footer} px={12}>
        <footer>{footer}</footer>
      </Box>
    </Box>
  );
};
