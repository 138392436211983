import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import invertBy from "lodash/invertBy";

import {
  IncomingPlatformValues,
  platformsMap,
  TakenPlatforms,
} from "../../../types";

const PlatformRemovalText = ({
  platformsToRemove = [],
  binaryVersion = "",
}: {
  platformsToRemove: string[];
  binaryVersion?: string;
}) => {
  const platformsText = platformsToRemove
    .map((platform) => platformsMap[platform as IncomingPlatformValues])
    .join(", ");

  return (
    <Box mb={4}>
      <Typography align="center" color="textSecondary">
        <Trans
          i18nKey="remove_platform_notification.main_text"
          count={platformsToRemove.length}
          values={{
            platformsText,
            binaryVersion,
          }}
          components={{
            descriptionText: <Box component="strong" color="text.primary" />,
          }}
        />
      </Typography>
    </Box>
  );
};

interface PlatformsToRemoveFromBinariesProps {
  platformsToRemove: TakenPlatforms;
}
export const PlatformsToRemoveFromBinaries: React.FC<PlatformsToRemoveFromBinariesProps> = ({
  platformsToRemove,
}) => {
  const invertedPlatformsToRemove = invertBy(platformsToRemove);

  return (
    <>
      {Object.keys(invertedPlatformsToRemove).map(
        (buildVersion: string, index: number) => (
          <PlatformRemovalText
            key={index}
            platformsToRemove={invertedPlatformsToRemove[buildVersion]}
            binaryVersion={buildVersion}
          />
        )
      )}
    </>
  );
};
