import * as React from "react";
import { TextField, makeStyles, fade } from "@material-ui/core";
import { CheckMenuItem } from "@epic-ui/components";

type MultipleSelectOptions = {
  value: string;
  label: string;
};

interface MultipleSelectProps {
  value: string[];
  label: string;
  onChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  options: MultipleSelectOptions[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },
  menuPaper: {
    maxHeight: "28vh",
    border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
    borderRadius: "0 0 4px 4px",
    borderWidth: "0 1px 1px",
  },
}));

export const MultipleSelect: React.FC<MultipleSelectProps> = ({
  value,
  label,
  options,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <TextField
      classes={classes}
      select
      label={label}
      value={value}
      fullWidth
      SelectProps={{
        multiple: true,
        onChange,
        MenuProps: {
          classes: { paper: classes.menuPaper },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        },
      }}
    >
      {options.map(({ value, label }) => (
        <CheckMenuItem key={value} value={value}>
          {label}
        </CheckMenuItem>
      ))}
    </TextField>
  );
};
