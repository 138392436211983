import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > h2 {
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
  & .MuiSvgIcon-root {
    font-size: 6rem;
    margin-bottom: ${(p) => p.theme.spacing(4)}px;
  }
`;
