import * as React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Mod, ModModel } from "@epic-mod-market/core";

import { FooterButtons } from "../../../../components";

import { selectOrganizationId } from "../../../../store/selectors/app.selectors";
import {
  createNewModVersionAndSelect,
  recallModAndSelect,
} from "../../../../store/reducers/mod.reducer";
import {
  selectProductId,
  selectProductSandboxId,
} from "../../../../store/selectors/product.selectors";
import { useActionHandler } from "../../../hooks";

interface ModSelectionPageFooterProps {
  selectedMod?: Mod;
  disabled: boolean;
  isLoading: boolean;
  handleModSelection: () => void;
  onClose: () => void;
}

export const ModSelectionPageFooter: React.FC<ModSelectionPageFooterProps> = ({
  handleModSelection,
  onClose,
  selectedMod,
  disabled,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isActionInProgress, handleActionWithRedirect } = useActionHandler();

  const organizationId = useSelector(selectOrganizationId);
  const productId = useSelector(selectProductId);
  const productSandboxId = useSelector(selectProductSandboxId);

  if (selectedMod) {
    const { modSlug } = selectedMod;

    const params = {
      organizationId,
      productId,
      productSandboxId,
      modSlug,
    };

    if (ModModel.isPendingApproval(selectedMod)) {
      const handleConfirm = () => {
        handleActionWithRedirect(recallModAndSelect(params));
      };

      return (
        <FooterButtons
          onClose={onClose}
          onConfirm={handleConfirm}
          isLoading={isActionInProgress}
          closeLabel={t("common.cancel")}
          confirmLabel={t("common.recall.confirm")}
        />
      );
    }

    if (ModModel.isPublished(selectedMod)) {
      const handleConfirm = async () => {
        handleActionWithRedirect(createNewModVersionAndSelect(params));
      };

      return (
        <FooterButtons
          onClose={onClose}
          onConfirm={handleConfirm}
          isLoading={isActionInProgress}
          closeLabel={t("common.cancel")}
          confirmLabel={t("common.create_draft")}
        />
      );
    }
  }

  return (
    <FooterButtons
      onClose={onClose}
      onConfirm={handleModSelection}
      disabled={disabled}
      isLoading={isLoading}
      closeLabel={t("common.cancel")}
    />
  );
};
