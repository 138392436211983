import axios, { AxiosResponse } from "axios";
import {
  Mod,
  ModAction,
  ModRestrictions,
  ModProductSandboxSettingParams,
} from "@epic-mod-market/core";

import { APIRequest, bptServiceURL, unwrapResponse } from "../core";

export const modClient = axios.create({
  baseURL: bptServiceURL,
});

export interface ModMetaParams {
  organizationId: string;
  productId: string;
  productSandboxId: string;
  modSlug: string;
  locale?: string;
}

export type GetModsParams = Omit<ModMetaParams, "modSlug">;

export type GetModForModeratorParams = Omit<
  ModMetaParams,
  "organizationId" | "productSandboxId"
>;
export type GetSignedTokenParams = Pick<ModMetaParams, "productId" | "modSlug">;

const getMod: APIRequest<Mod, ModMetaParams> = ({
  organizationId,
  productId,
  productSandboxId,
  modSlug,
}) => {
  return modClient
    .get(
      `/mod/${organizationId}/${productId}/${productSandboxId}/mods/${modSlug}`
    )
    .then(unwrapResponse);
};

const getRestrictions: APIRequest<
  ModRestrictions,
  ModProductSandboxSettingParams
> = ({ productId, productSandboxId }) => {
  return modClient
    .get(`/mod/${productId}/${productSandboxId}/restrictions`)
    .then(unwrapResponse);
};

const getBackURL: APIRequest<{ backUrl: string }, ModMetaParams> = ({
  organizationId,
  productId,
  productSandboxId,
  modSlug,
  locale,
}) => {
  return modClient
    .get(
      `/mod/${organizationId}/${productId}/${productSandboxId}/${modSlug}/back-url`,
      {
        params: { locale },
      }
    )
    .then(unwrapResponse);
};

const getIsPlatformsSelectionEnabled: APIRequest<
  {
    isPlatformSelectionEnabled: boolean;
  },
  ModProductSandboxSettingParams
> = ({ productId, productSandboxId }) => {
  return modClient
    .get(`/mod/${productId}/${productSandboxId}/enable-platforms-selection`)
    .then(unwrapResponse);
};

const getBuildVersion: APIRequest<{
  buildVersion: string;
}> = () => {
  return modClient.get("/mod/build-version").then(unwrapResponse);
};

const recallMod: APIRequest<AxiosResponse, ModMetaParams> = ({
  organizationId,
  productId,
  productSandboxId,
  modSlug,
}) =>
  modClient.put(
    `/mod/${organizationId}/${productId}/${productSandboxId}/mods/${modSlug}/action/recall`
  );

const getCreatedMods: APIRequest<Mod[], GetModsParams> = ({
  organizationId,
  productId,
  productSandboxId,
}) =>
  modClient
    .get(`/mod/${organizationId}/${productId}/${productSandboxId}/mods`)
    .then(unwrapResponse);

const updateMod: APIRequest<Mod, ModMetaParams> = ({
  organizationId,
  productId,
  productSandboxId,
  modSlug,
}) =>
  modClient.patch(
    `/mod/${organizationId}/${productId}/${productSandboxId}/mods/${modSlug}/update`
  );

const createMod: APIRequest<
  { urlSlug: string },
  Omit<ModMetaParams, "modSlug">
> = ({ organizationId, productId, productSandboxId }) =>
  modClient
    .post(`/mod/${organizationId}/${productId}/${productSandboxId}/create`)
    .then(unwrapResponse);

// TODO: separate moderator and modder api
const getModForModerator: APIRequest<Mod, GetModForModeratorParams> = ({
  productId,
  modSlug,
}) => {
  return modClient
    .get(`moderator/mod/${productId}/mods/${modSlug}`)
    .then(unwrapResponse);
};

// TODO: separate moderator and modder api
const getSignedToken: APIRequest<string, GetSignedTokenParams> = ({
  productId,
  modSlug,
}) => {
  return modClient
    .get(`/moderator/mod/${productId}/${modSlug}/artifact/signed-token`)
    .then(unwrapResponse);
};

const createNewVersion: APIRequest<AxiosResponse, ModMetaParams> = ({
  organizationId,
  productId,
  productSandboxId,
  modSlug,
}) =>
  modClient.put(
    `/mod/${organizationId}/${productId}/${productSandboxId}/mods/${modSlug}/action`,
    {
      action: ModAction.CREATE_NEW_VERSION,
    }
  );

export const modApi = {
  getMod,
  getModForModerator,
  createMod,
  recallMod,
  updateMod,
  createNewVersion,
  getRestrictions,
  getBackURL,
  getCreatedMods,
  getIsPlatformsSelectionEnabled,
  getBuildVersion,
  getSignedToken,
};
