import { createSelector } from "@reduxjs/toolkit";
import { ArtifactItem } from "@epic-mod-market/core";

import { RootState } from "../reducers";
import { DownloadState } from "../reducers/download.reducer";

const selectDownload = (state: RootState): DownloadState => state.download;

export const selectDownloadFolder = createSelector(
  selectDownload,
  (download): string => download.folder
);

export const selectAvailableBuilds = createSelector(
  selectDownload,
  (download): ArtifactItem[] => download.artifacts
);
