import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ExternalLink as ExternalLinkIcon } from "@epic-ui/icons";

import { Title, SafeContainer, Link, PageWrapper } from "../../components";

import { LoadingPage } from "./LoadingPage";
import { Page } from "./Page";
import {
  selectEntityType,
  selectOrganizations,
  selectAppMode,
  selectAppIsLoading,
} from "../../store/selectors/app.selectors";
import {
  fetchOrganizations,
  setOrganizationId,
} from "../../store/reducers/app.reducer";
import { uploaderBridge } from "../../services";

const useButtonClasses = makeStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
}));

const handleOpenReadMore = () => {
  const organizationsGlossaryURL = `${process.env.REACT_APP_EPIC_SERVER_URL}/docs/services/en-US/Glossary/index.html#o`;
  uploaderBridge.openSupportPage(organizationsGlossaryURL);
};

export const ChooseOrganizationPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appMode = useSelector(selectAppMode);
  const entityType = useSelector(selectEntityType);
  const isAppLoading = useSelector(selectAppIsLoading);
  const organizations = useSelector(selectOrganizations);

  const history = useHistory();

  const handleChooseOrganization = React.useCallback(
    (organizationId: string) => {
      dispatch(setOrganizationId(organizationId));
      history.push(`/${appMode}/${entityType}`);
    },
    [dispatch, history, appMode, entityType]
  );

  React.useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  React.useEffect(() => {
    if (organizations.length === 1) {
      handleChooseOrganization(organizations[0].organizationId);
    }
  }, [organizations, handleChooseOrganization]);

  const buttonClasses = useButtonClasses();

  if (isAppLoading) {
    return <LoadingPage description={t("common.data_loading_in_progress")} />;
  }

  return (
    <PageWrapper>
      <Page
        header={
          <>
            <Box mb={6}>
              <Title>{t("common.choose_organization")}</Title>
            </Box>
            <Link onClick={handleOpenReadMore} underline="always">
              {t("common.read_more_about_organizations")}
              <Box pl={1} display="flex" alignItems="center">
                <ExternalLinkIcon fontSize="inherit" />
              </Box>
            </Link>
          </>
        }
        footer={null}
      >
        <SafeContainer justify="space-between" noBorder paddingY="0">
          {organizations.map(({ organizationId, name }) => (
            <Button
              key={organizationId}
              onClick={() => handleChooseOrganization(organizationId)}
              size="large"
              variant="contained"
              color="secondary"
              classes={buttonClasses}
            >
              {name}
            </Button>
          ))}
        </SafeContainer>
      </Page>
    </PageWrapper>
  );
};
