import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
// @ts-ignore
import { ThemeProvider } from "epic-ui-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createEpicTheme } from "@epic-ui/theme";

import { GlobalStyles } from "./GlobalStyle";

import { SignInPage, ErrorBoundary, ChooseOrganizationPage } from "./pages";
import { UploadContainer } from "./pages/upload/UploadContainer";
import { DownloadContainer } from "./pages/download/DownloadContainer";
import { fetchAppParams } from "./store/reducers/app.reducer";
import { LoadingPage } from "./pages/common";
import {
  selectAppParamsIsLoading,
  selectLocale,
} from "./store/selectors/app.selectors";
import { i18nConfig } from "./i18n";

const epicTheme = createEpicTheme();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const isLoadingAppParams = useSelector(selectAppParamsIsLoading);

  React.useEffect(() => {
    dispatch(fetchAppParams());
  }, [dispatch]);

  React.useEffect(() => {
    i18nConfig(locale);
  }, [locale]);

  if (isLoadingAppParams) {
    return (
      <>
        <GlobalStyles />
        <LoadingPage />
      </>
    );
  }

  return (
    /*TODO: remove epic-ui-toolkit ThemeProvider in scope of removing the dependency*/
    <ThemeProvider localTheme={epicTheme}>
      <MuiThemeProvider theme={epicTheme}>
        <GlobalStyles />
        <BrowserRouter>
          <ErrorBoundary>
            <Switch>
              <Route path="/upload" component={UploadContainer} />
              <Route path="/download" component={DownloadContainer} />
              <Route path="/organizations" component={ChooseOrganizationPage} />

              <Route exact path="/login" component={SignInPage} />

              <Redirect to="/login" />
            </Switch>
          </ErrorBoundary>
        </BrowserRouter>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
