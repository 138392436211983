import { Offer, OfferType, BulkItems, OfferItem, SandboxTag } from "../types";

export const hidePrePurchaseOffers = (offers: Offer[]): Offer[] => {
  return offers.filter((offer) => {
    return !offer.prePurchase;
  });
};

const decomposeNsId = (nsId: string): { namespace: string; id: string } => {
  const [namespace, id] = nsId.split(":");
  return { namespace, id };
};

export const populateSubAndMajorOffers = (offers: Offer[]): Offer[] => {
  return offers.map((offer) => ({
    ...offer,
    subNsOffers: offers.filter((subOffer) =>
      offer.subOfferDependencies?.some(
        ({ nsOfferId }) => decomposeNsId(nsOfferId).id === subOffer.id
      )
    ),

    majorNsOffers: offers.filter((majorOffer) =>
      majorOffer.subOfferDependencies?.some(
        ({ nsOfferId }) => decomposeNsId(nsOfferId).id === offer.id
      )
    ),
  }));
};

export const getAudienceItemConnectedWithArtifact = (
  items: BulkItems,
  artifactId: string
): OfferItem | undefined => {
  return Object.values(items).find((item) => {
    if (item.subItemList && item.subItemList[0]) {
      const { releaseInfo }: OfferItem = item.subItemList[0];
      return releaseInfo?.[0]?.appId === artifactId;
    }
  });
};

export const getOfferForAudienceItemId = (
  offers: Offer[],
  itemIdConnectedWithArtifact?: string
): Offer | undefined => {
  return offers.find(
    ({ items }) => items[0].id === itemIdConnectedWithArtifact
  );
};

export const isExecutableItemAvailable = (offerType: OfferType): boolean => {
  return [
    OfferType.ADD_ON,
    OfferType.DEMO,
    OfferType.BASE_GAME,
    OfferType.DIGITAL_EXTRA,
  ].includes(offerType);
};

export const getTagsIdMap = (
  tags: SandboxTag[]
): { [index: string]: number } => {
  const tagsIdMap: { [index: string]: number } = {};

  tags.forEach(({ name, id }) => {
    tagsIdMap[name] = Number(id);
  });
  return tagsIdMap;
};
