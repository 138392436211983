import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { closeApp } from "../../../../store/reducers/app.reducer";
import {
  selectUploadFolder,
  selectIsPlatformSelectionEnabled,
} from "../../../../store/selectors/upload.selectors";
import {
  selectEntityType,
  selectInitialEntityId,
} from "../../../../store/selectors/app.selectors";
import { selectModSlug } from "../../../../store/selectors/mod.selectors";
import { selectAvailablePlatforms } from "../../../../store/selectors/product.selectors";
import { updateFolder } from "../../../../store/reducers/upload.reducer";
import { startModUpload } from "../../../../store/reducers/mod.reducer";
import { FolderSelectionPage } from "../../common/folder";
import { uploaderBridge } from "../../../../services";

export const ModFolderPage: React.FC = () => {
  const { t } = useTranslation();
  const uploadFolderPath = useSelector(selectUploadFolder);

  const entityType = useSelector(selectEntityType);
  const entityId = useSelector(selectModSlug);

  const initialEntityId = useSelector(selectInitialEntityId);
  const availablePlatforms = useSelector(selectAvailablePlatforms);
  const isPlatformSelectionEnabled = useSelector(
    selectIsPlatformSelectionEnabled
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const canGoToPlatformsPage =
    isPlatformSelectionEnabled && availablePlatforms.length > 1;

  const handleCancel = () => {
    dispatch(updateFolder(""));
    if (canGoToPlatformsPage) {
      return history.push("/upload/mod/platforms");
    }
    if (!initialEntityId) {
      return history.push("/upload/mod/select");
    }
    dispatch(closeApp());
  };

  const [folderPath, setFolderPath] = React.useState(uploadFolderPath);

  const handleChangeUploadFolder = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFolderPath(target.value);
  };

  const handleChangeUploadFolderClick = async () => {
    const folderPath = await uploaderBridge.onBrowseButton();
    setFolderPath(folderPath);
  };

  const handleSelectFolder = () => {
    if (!entityId) return;

    dispatch(updateFolder(folderPath));
    dispatch(startModUpload(entityId));
    // TODO: move to goTo<% route %> actions when introduced
    history.push(`/upload/${entityType}/file-upload`);
  };

  const closeLabel =
    !canGoToPlatformsPage && initialEntityId ? t("common.cancel") : "";

  return (
    <FolderSelectionPage
      folderPath={folderPath}
      handleCancel={handleCancel}
      handleSelectFolder={handleSelectFolder}
      handleChangeFolder={handleChangeUploadFolder}
      handleChangeFolderClick={handleChangeUploadFolderClick}
      closeLabel={closeLabel}
    />
  );
};
