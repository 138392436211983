import { Policies } from "@epic-ui/icons";
import { Typography } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FooterButtons } from "../../../../../components";
import { uploaderBridge } from "../../../../../services";
import { BinaryUploadPage } from "../../../../common";
import { Container } from "./ActivateBinarySuccessPage.styles";
import {
  useBinaryUploadData,
  initialFolderPathState,
  initialAdditionalInformationState,
} from "../../data";
import { useUploadState } from "../../../../UploadStateProvider";

export const ActivateBinarySuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setFolderPaths,
    setAdditionalInformation,
    setShouldConfirmationPageBeShown,
    setSelectedPlatformsState,
  } = useBinaryUploadData();
  const { resetProgress } = useUploadState();

  const handleUploadAnother = () => {
    resetProgress();
    setFolderPaths(initialFolderPathState);
    setAdditionalInformation(initialAdditionalInformationState);
    setShouldConfirmationPageBeShown(false);
    setSelectedPlatformsState([]);
    history.push("/upload/game/folders");
  };

  const handleFinish = () => {
    uploaderBridge.onClose();
  };

  return (
    <BinaryUploadPage
      footer={
        <FooterButtons
          onClose={handleUploadAnother}
          onConfirm={handleFinish}
          confirmLabel={t("activate_binary.success.footer_buttons.finish")}
          closeLabel={t(
            "activate_binary.success.footer_buttons.upload_another"
          )}
        />
      }
    >
      <Container>
        <Policies color="primary" />
        <Typography variant="h2">
          {t("activate_binary.success.message.binary_active")}
        </Typography>
        <Typography variant="body1">
          {t("activate_binary.success.message.upload_another_question")}
        </Typography>
      </Container>
    </BinaryUploadPage>
  );
};
