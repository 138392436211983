import * as React from "react";
import pick from "lodash/pick";
import { Formik, FormikHelpers } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Platform } from "@epic-mod-market/core";

import {
  useArtifactBinariesPlatforms,
  useUpdateBinaryPlatforms,
  useUpdateOfferPlatforms,
} from "../../hooks";
import { useBinaryUploadData } from "../../data";
import { ActivateBinaryForm } from "./ActivateBinaryForm";

interface ActivateBinaryFormValues {
  selectedPlatforms: Platform[];
  distribution: string;
}
export const ActivateBinaryPage: React.FC = () => {
  const history = useHistory();

  const location = useLocation<{ buildVersion: string }>();
  const { buildVersion } = location.state;

  const {
    selectedPlatformsState,
    setShouldConfirmationPageBeShown,
    binaries: { binariesLists },
  } = useBinaryUploadData();

  const {
    takenPlatforms,
    takenPlatformsExcludeCurrent,
  } = useArtifactBinariesPlatforms(binariesLists, buildVersion as string);

  const handleUpdateBinaryPlatforms = useUpdateBinaryPlatforms(buildVersion);
  const { updateOfferPlatforms } = useUpdateOfferPlatforms();

  const onSubmit = React.useCallback(
    async (
      values: ActivateBinaryFormValues,
      { setSubmitting }: FormikHelpers<ActivateBinaryFormValues>
    ) => {
      const { selectedPlatforms } = values;

      const hasAssignedPlatforms = selectedPlatforms.some(
        (platform: Platform) => takenPlatformsExcludeCurrent[platform]
      );

      if (hasAssignedPlatforms) {
        const platformsToRemove = pick(
          takenPlatformsExcludeCurrent,
          selectedPlatforms
        );
        history.push("/upload/game/notification-remove-platforms", {
          platformsToRemove,
          takenPlatforms,
          buildVersion,
          selectedPlatforms,
        });
        return;
      }

      setShouldConfirmationPageBeShown(false);
      setSubmitting(true);
      await updateOfferPlatforms({ platforms: selectedPlatforms });
      handleUpdateBinaryPlatforms(selectedPlatforms);
    },
    [
      takenPlatforms,
      buildVersion,
      handleUpdateBinaryPlatforms,
      updateOfferPlatforms,
      history,
      takenPlatformsExcludeCurrent,
    ]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        selectedPlatforms: selectedPlatformsState,
        distribution: "Global",
      }}
      onSubmit={onSubmit}
    >
      <ActivateBinaryForm
        takenPlatforms={takenPlatforms}
        buildVersion={buildVersion}
      />
    </Formik>
  );
};
