import * as React from "react";
import axios from "axios";
import sortBy from "lodash/sortBy";

import { GroupName, SandboxTag, UseSandboxTags, Status } from "../types";
import { getSandboxTags } from "../../../../services/api/offer";

export function useSandboxTags(sandboxId?: string): UseSandboxTags {
  const [status, setStatus] = React.useState<Status>(Status.INACTIVE);
  const [tags, setTags] = React.useState<SandboxTag[]>([]);

  React.useEffect(() => {
    if (!sandboxId) return;

    const source = axios.CancelToken.source();

    setStatus(Status.REQUEST);

    getSandboxTags(sandboxId, source.token)
      .then(({ elements }) => {
        setTags(elements);
        setStatus(Status.SUCCESS);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setStatus(Status.ERROR);
        }
      });

    return () => source.cancel();
  }, [sandboxId]);

  return React.useMemo<UseSandboxTags>(() => {
    const platforms = tags.filter(
      (tag) => tag?.groupName === GroupName.PLATFORM
    );

    const sortedPlatforms = sortBy(platforms, "name");

    return {
      platforms: sortedPlatforms,
      status,
    };
  }, [status, tags]);
}
