import * as React from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface ContainerProps extends BoxProps {
  titleKey: string;
  children: React.ReactNode;
}

export const ChartContainer: React.FC<ContainerProps> = ({
  titleKey,
  children,
  ...boxProps
}) => {
  const { t } = useTranslation();

  return (
    <Box width="100%" {...boxProps}>
      <Box mb={1}>
        <Typography variant="body1" color="textSecondary" align="left">
          {t(titleKey)}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
