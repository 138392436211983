import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/epic-ui-components";
import {
  Title,
  Description,
  SafeContainer,
  ProgressBar,
} from "../../../../../components";
import { Page } from "../../../../common";
import { cancelUpload } from "../../../../../store/reducers/upload.reducer";
import { useUploadState } from "../../../../UploadStateProvider";
import { selectEntityTitle } from "../../../../../store/selectors/entity.selectors";

export const UploadProgressPage: React.FC = () => {
  const { t } = useTranslation();
  const title = useSelector(selectEntityTitle);

  const { progress } = useUploadState();

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <Button
          variant="contained"
          color="secondary"
          onClick={cancelUpload}
          fullWidth
        >
          {t("common.cancel")}
        </Button>
      }
    >
      <SafeContainer>
        <Description mb={6}>{t("upload.in_progress")}</Description>
        <ProgressBar progress={progress} />
      </SafeContainer>
    </Page>
  );
};
