import { EntityType } from "../../../models";
import { SignInCompleteCallback } from "./common";
import {
  Downloadable,
  DownloadCompleteCallback,
  DownloadErrorCode,
  DownloadParams,
  DownloadProgressState,
  DownloadState,
} from "./downloaderBridge";

export class DownloadBridgeMockService {
  private signInCompleteCallback: SignInCompleteCallback<
    DownloadErrorCode
  > = () => undefined;
  private downloadCompleteCallback: DownloadCompleteCallback = () => undefined;

  private artifacts: Downloadable[] = [];
  private folder = "";

  private downloadParams: DownloadParams = {
    entityid: "",
    entitytype: EntityType.MOD,
    signedtoken: "",
    productid: "",
    sandboxid: "",
    catalogitemid: "",
    artifactid: "",
    title: "",
    artifacts: this.artifacts,
    downloadfolder: this.folder,
  };

  private state: DownloadState | undefined;
  private downloadProgress = 0;
  private downloadRate = 0;
  private writeRate = 0;
  private readRate = 0;

  private timerID: number | undefined;
  private timeoutID: number | undefined;

  private doDownload = async (params: DownloadParams) => {
    if (this.downloadProgress >= 100) {
      this.timerID && clearTimeout(this.timerID);
      this.state = DownloadState.Completed;

      return this.downloadCompleteCallback(
        { artifacts: this.artifacts },
        {
          errorcode: DownloadErrorCode.OK,
          errormessages: [],
          supporturl: "https://google.com/",
        }
      );
    }

    this.artifacts = params.artifacts;
    this.folder = params.downloadfolder;

    await new Promise((resolve) => {
      this.timerID && clearTimeout(this.timerID);

      this.timerID = setTimeout(() => {
        this.downloadProgress += 2.5;
        resolve();
      }, 300);
    });

    this.doDownload(params);
  };

  private resetDownloadState = () => {
    this.downloadProgress = 0;
    this.downloadRate = 0;
    this.writeRate = 0;
    this.readRate = 0;
  };

  private terminateDownload = () => {
    this.timerID && clearTimeout(this.timerID);
    this.resetDownloadState();
    this.state = DownloadState.Cancelled;

    return this.downloadCompleteCallback(
      { artifacts: this.artifacts },
      {
        errorcode: DownloadErrorCode.CanceledByUser,
        errormessages: [],
        supporturl: "https://google.com/",
      }
    );
  };

  private pauseDownload = () => {
    this.timerID && clearTimeout(this.timerID);
    this.state = DownloadState.Paused;
  };

  private resumeDownload = () => {
    this.doDownload(this.downloadParams);
    this.state = DownloadState.Started;
  };

  public registerSignInCompleteCallback = async (
    signInCompleteCallback: SignInCompleteCallback<DownloadErrorCode>
  ): Promise<void> => {
    this.signInCompleteCallback = signInCompleteCallback;

    this.timeoutID && clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      return this.signInCompleteCallback({
        errorcode: DownloadErrorCode.OK,
        errormessages: [],
        supporturl: "https://google.com/",
      });
    }, 2000);
  };

  public registerDownloadCompleteCallback = async (
    downloadCompleteCallback: DownloadCompleteCallback
  ): Promise<void> => {
    this.downloadCompleteCallback = downloadCompleteCallback;
  };

  public onCancel = async (): Promise<void> => {
    this.terminateDownload();
  };

  public onPause = async (): Promise<void> => {
    this.pauseDownload();
  };

  public onResume = async (): Promise<void> => {
    this.resumeDownload();
  };

  public onClose = async (): Promise<void> => {
    window.location.reload();
  };

  public onOpenDownloadFolder = async (): Promise<void> => {
    let input: HTMLInputElement | null = document.createElement("input");
    input.type = "file";
    input.click();
    input = null;
  };

  public onChangeDownloadFolder = async (): Promise<string> => {
    let input: HTMLInputElement | null = document.createElement("input");
    input.type = "file";
    input.click();
    this.folder = "Desktop/User/Game/Mod Files";

    input = null;

    return this.folder;
  };

  public openSupportPage = async (supportUrl: string) => {
    window.open(supportUrl, "_blank");
  };

  public getDownloadFolder = async (): Promise<string> => {
    return "Desktop/Game/Mod Files";
  };

  public getDownloadProgressState = async (): Promise<
    DownloadProgressState
  > => {
    return {
      state: this.state,
      downloadprogress: this.downloadProgress,
      downloadrate: this.downloadRate,
      writerate: this.writeRate,
      readrate: this.readRate,
    };
  };

  public download = async (params: DownloadParams): Promise<void> => {
    this.resetDownloadState();
    this.state = DownloadState.Started;

    this.doDownload(params);
  };
}
