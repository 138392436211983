import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ArtifactItem } from "@epic-mod-market/core";

import {
  ArtifactMetaParams,
  commonApi,
  downloaderBridge,
} from "../../services";
import { closeApp, serverError } from "./app.reducer";
import { GlobalTypes } from "./common";

export interface DownloadState {
  folder: string;
  artifacts: ArtifactItem[];
}

export const downloadInitialState: DownloadState = {
  folder: "",
  artifacts: [],
};

export const fetchArtifacts = createAsyncThunk<
  ArtifactItem[],
  ArtifactMetaParams
>(
  `${GlobalTypes.GetData}/download/fetchArtifacts`,
  async (params, { dispatch }) => {
    try {
      const { builds } = await commonApi.getLiveArtifactsForSandbox(params);
      return builds;
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

const downloadSlice = createSlice({
  name: "download",
  initialState: downloadInitialState,
  reducers: {
    updateDownloadFolder(state, action) {
      state.folder = action.payload;
    },

    updateArtifacts(state, action) {
      state.artifacts = action.payload;
    },

    fetchDownloadDataSuccess(state, action) {
      const { downloadFolder } = action.payload;

      state.folder = downloadFolder;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArtifacts.fulfilled, (state, action) => {
      state.artifacts = action.payload;
    });
  },
});

export const {
  fetchDownloadDataSuccess,
  updateDownloadFolder,
  updateArtifacts,
} = downloadSlice.actions;

export const openDownloadFolder = () => async (dispatch) => {
  await downloaderBridge.onOpenDownloadFolder();
  dispatch(closeApp());
};

export const fetchDownloadData = () => async (dispatch) => {
  const downloadFolder = await downloaderBridge.getDownloadFolder();

  dispatch(fetchDownloadDataSuccess({ downloadFolder }));
};

export const openDownloadFolderDialog = () => async (dispatch) => {
  const folder = await downloaderBridge.onChangeDownloadFolder();

  dispatch(updateDownloadFolder(folder));
};

export default downloadSlice.reducer;
