import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_US_messages from "./messages_en-US.json";
import zh_CN_messages from "./messages_zh-CN.json";
import zh_Hant_messages from "./messages_zh-Hant.json";
import ja_messages from "./messages_ja.json";
import ko_messages from "./messages_ko.json";
import ar_messages from "./messages_ar.json";
import de_messages from "./messages_de.json";
import es_ES_messages from "./messages_es-ES.json";
import es_MX_messages from "./messages_es-MX.json";
import fr_messages from "./messages_fr.json";
import it_messages from "./messages_it.json";
import pl_messages from "./messages_pl.json";
import pt_BR_messages from "./messages_pt-BR.json";
import ru_messages from "./messages_ru.json";
import th_messages from "./messages_th.json";
import tr_messages from "./messages_tr.json";

const resources = {
  "en-US": {
    translation: en_US_messages,
  },
  "zh-Hans": {
    translation: zh_CN_messages,
  },
  "zh-CN": {
    translation: zh_CN_messages,
  },
  "zh-Hant": {
    translation: zh_Hant_messages,
  },
  ja: {
    translation: ja_messages,
  },
  ko: {
    translation: ko_messages,
  },
  ar: {
    translation: ar_messages,
  },
  de: {
    translation: de_messages,
  },
  "es-ES": {
    translation: es_ES_messages,
  },
  "es-MX": {
    translation: es_MX_messages,
  },
  fr: {
    translation: fr_messages,
  },
  it: {
    translation: it_messages,
  },
  pl: {
    translation: pl_messages,
  },
  "pt-BR": {
    translation: pt_BR_messages,
  },
  ru: {
    translation: ru_messages,
  },
  th: {
    translation: th_messages,
  },
  tr: {
    translation: tr_messages,
  },
};

export const i18nConfig = (locale) => {
  i18n.use(initReactI18next).init({
    fallbackLng: "en-US",
    lng: locale || "en-US",
    resources,
    ns: ["translation"],
    defaultNS: "translation",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: false,
    },
  });
};
