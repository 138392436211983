import styled from "styled-components";
import { Divider, List, Typography } from "@material-ui/core";
import { NotificationContainer } from "../../components/common.styles";

export const Description = styled(Typography).attrs({
  variant: "body1",
})`
  white-space: pre-wrap;
  text-align: center;
  color: ${(p) => p.theme.palette.text.secondary};

  & > span {
    color: ${(p) => p.theme.palette.dividerStrong};
  }
  margin-bottom: ${(p) => p.theme.spacing(10)}px;
`;

export const StyledDivider = styled(Divider).attrs({
  component: "li",
})`
  background-color: ${(p) => p.theme.palette.divider};
`;

export const PlatformList = styled(List)`
  border: 1px solid ${(p) => p.theme.palette.divider};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;

  .MuiListItem-root {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const SuccessfulUploadNotification = styled(
  NotificationContainer as any
)`
  background-color: ${(p) => p.theme.palette.success.main};
`;
