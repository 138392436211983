import * as React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

import { useOverflow } from "../pages/hooks";

const Wrapper = styled.div<{
  height?: number;
  heightLimit?: number;
  paddingY?: number | string;
  noBorder?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: ${(p) => (p.height ? `${p.height}px` : "auto")};
  height: 100%;
  padding: ${(p) => (p.paddingY ? `${p.paddingY}px 0` : "30px")};
  border: ${(p) => (p.noBorder ? "none" : "1px solid #484848")};
  overflow: auto;
`;

const ContentContainer = styled.div<{
  justify?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => `${p.justify || "center"}`};
  width: 100%;
  overflow: auto;
`;

const BoxShadow = styled(Box)`
  background: linear-gradient(
    180deg,
    rgba(33, 33, 33, 0) 0%,
    rgba(33, 33, 33, 0.96) 90.83%
  );
`;

interface SafeContainerProps {
  height?: number;
  heightLimit?: number;
  justify?: string;
  paddingY?: number | string;
  noBorder?: boolean;
}

export const SafeContainer: React.FC<SafeContainerProps> = ({
  children,
  justify,
  ...props
}) => {
  const contentRef = React.useRef(null);
  const { isYOverflow } = useOverflow(contentRef);

  return (
    <Wrapper {...props}>
      {isYOverflow && (
        <BoxShadow
          position="absolute"
          left={0}
          bottom={0}
          width="100%"
          height={60}
        />
      )}
      <ContentContainer justify={justify} ref={contentRef}>
        {children}
      </ContentContainer>
    </Wrapper>
  );
};
