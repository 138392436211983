import { UploaderBridge } from "../uploaderBridge";
import { UploadBridgeMockService } from "../UploadBridgeMockService";

const MockService = new UploadBridgeMockService();

export const uploaderWeb: UploaderBridge = {
  registerSignInCompleteCallback: MockService.registerSignInCompleteCallback,
  registerUploadCompleteCallback: MockService.registerUploadCompleteCallback,
  registerUploadStateChangeCallback:
    MockService.registerUploadStateChangeCallback,
  onBrowseButton: MockService.onBrowseButton,
  upload: MockService.upload,
  onCancel: MockService.onCancel,
  onClose: MockService.onClose,
  getModTitle: MockService.getModTitle,
  getSignInState: MockService.getSignInState,
  getIsPlatformSelectionEnabled: MockService.getIsPlatformSelectionEnabled,
  openSupportPage: MockService.openSupportPage,
  getSupportedPlatforms: MockService.getSupportedPlatforms,
  setSupportedPlatforms: MockService.setSupportedPlatforms,
  openDirectoryDialog: MockService.openDirectoryDialog,
  openFileDialog: MockService.openFileDialog,
  registerPreExitCallback: MockService.registerPreExitCallback,
  preventFromExit: MockService.preventFromExit,
  generateCloudDirectory: MockService.generateCloudDirectory,
};
