import { AxiosError } from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Mod,
  ModModel,
  ModRestrictions,
  SandboxModel,
} from "@epic-mod-market/core";

import { EntityType } from "../../models";
import {
  Downloadable,
  downloaderBridge,
  GetModForModeratorParams,
  GetModsParams,
  modApi,
  ModMetaParams,
  uploaderBridge,
} from "../../services";
import {
  selectAppParams,
  selectOrganizationId,
} from "../selectors/app.selectors";
import { selectModItem, selectRestrictions } from "../selectors/mod.selectors";
import {
  selectUploadFolder,
  selectUploadParams,
} from "../selectors/upload.selectors";
import { selectDownloadFolder } from "../selectors/download.selectors";
import { selectProductId } from "../selectors/product.selectors";
import {
  clientError,
  bridgeError,
  serverError,
  updateParams,
} from "./app.reducer";
import { fetchModUploadParams } from "./upload.reducer";
import { GlobalTypes, isOneOf } from "./common";
import { RootState } from "./index";

export interface ModState {
  mods: {
    items: Record<string, Mod>;
  };
  restrictions: {
    data: ModRestrictions;
  };
}

export const fetchMod = createAsyncThunk(
  "mod/fetchModData",
  async (params: ModMetaParams, { dispatch }): Promise<Mod | never> => {
    try {
      const mod = await modApi.getMod(params);
      if (
        ModModel.isPendingApproval(mod) ||
        ModModel.isPublished(mod) ||
        ModModel.isApproved(mod)
      ) {
        dispatch(
          clientError({
            errorCode: "03-003",
          })
        );
      }

      if (ModModel.isRemoved(mod)) {
        dispatch(serverError({ response: { status: 403 } } as AxiosError));
      }

      return mod;
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

export const fetchModForModerator = createAsyncThunk(
  `${GlobalTypes.GetData}/mod/fetchModModeratorData`,
  async (
    params: GetModForModeratorParams,
    { dispatch }
  ): Promise<Mod | never> => {
    try {
      return await modApi.getModForModerator(params);
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

export const fetchModRestrictions = createAsyncThunk(
  "mod/fetchModRestrictions",
  async (
    params: Pick<ModMetaParams, "productId" | "productSandboxId">,
    { dispatch }
  ): Promise<ModRestrictions | never> => {
    try {
      return await modApi.getRestrictions(params);
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

export const fetchModUploadData = createAsyncThunk<
  void,
  ModMetaParams,
  { state: RootState }
>(
  `${GlobalTypes.GetData}mod/fetchModUploadData`,
  async (params: ModMetaParams, { dispatch }): Promise<void | never> => {
    try {
      const { modSlug, productId, organizationId, productSandboxId } = params;

      const isPrivateProductSandbox = SandboxModel.isPrivateSandbox(
        productSandboxId
      );

      if (!isPrivateProductSandbox) {
        await dispatch(
          fetchMod({ organizationId, modSlug, productId, productSandboxId })
        );
      }

      await Promise.all([
        dispatch(fetchModRestrictions({ productId, productSandboxId })),
        dispatch(fetchModUploadParams(params)),
      ]);
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

export const fetchCreatedMods = createAsyncThunk(
  "mod/fetchCreatedMods",
  async (
    params: GetModsParams,
    { dispatch }
  ): Promise<Record<string, Mod> | never> => {
    try {
      const mods = await modApi.getCreatedMods(params);

      const normalizedMods = {};

      mods.forEach((mod) => {
        normalizedMods[mod.modSlug] = mod;
      });

      return normalizedMods;
    } catch (err) {
      dispatch(serverError);
      throw err;
    }
  }
);

export const createModAndSelect = createAsyncThunk(
  "mod/createMod",
  async (
    params: Omit<ModMetaParams, "modSlug">,
    { dispatch }
  ): Promise<void> => {
    const { urlSlug } = await modApi.createMod(params);
    dispatch(selectMod({ modSlug: urlSlug, productId: params.productId }));
  }
);

export const selectMod = ({ modSlug, productId }) => (dispatch) => {
  return dispatch(updateParams({ entityid: modSlug, productid: productId }));
};

export const recallModAndSelect = createAsyncThunk(
  "mod/recallModAndSelect",
  async (params: ModMetaParams, { dispatch }): Promise<void | never> => {
    try {
      await modApi.recallMod(params);

      dispatch(selectMod(params));
    } catch (err) {
      dispatch(serverError(err));
    }
  }
);

export const updateModAndSelect = createAsyncThunk(
  "mod/updateModAndSelect",
  async (params: ModMetaParams, { dispatch }): Promise<void | never> => {
    try {
      await modApi.updateMod(params);

      dispatch(selectMod(params));
    } catch (err) {
      dispatch(serverError(err));
    }
  }
);

export const createNewModVersionAndSelect = createAsyncThunk(
  "mod/createNewModVersionAndSelect",
  async (params: ModMetaParams, { dispatch }): Promise<void | never> => {
    try {
      await modApi.createNewVersion(params);

      dispatch(selectMod(params));
    } catch (err) {
      dispatch(serverError(err));
    }
  }
);

export const startModUpload = (entityId: string) => (dispatch, getState) => {
  const state = getState();

  const {
    productid,
    entitytype,
    artifactid: artifactIdAppParam,
    sandboxid: sandboxIdAppParam,
    title: titleAppParam,
  } = selectAppParams(state);

  const organizationid = selectOrganizationId(state);
  const { prohibitedFileExtensions, maxBuildSize } = selectRestrictions(state);
  const { artifactId, privateNamespace, title } = selectModItem(state) || {
    artifactId: artifactIdAppParam,
    privateNamespace: sandboxIdAppParam,
    title: titleAppParam,
  };
  const { buildVersion, isPlatformSelectionEnabled, backURL, platforms } =
    selectUploadParams(state) || {};
  const folderPath = selectUploadFolder(state);

  uploaderBridge.upload({
    entitytype,
    organizationid,
    productid,
    entityid: entityId,
    artifactid: artifactId,
    buildversion: buildVersion,
    buildroot: folderPath,
    sandboxid: privateNamespace,
    prohibitedfileextensions: prohibitedFileExtensions,
    maxbuildsize: maxBuildSize,
    bisplatformselectionenabled: isPlatformSelectionEnabled,
    backurl: backURL,
    supportedplatforms: platforms,
    title,
  });
};

export const startModDownload = createAsyncThunk<
  void,
  Downloadable[],
  { state: RootState }
>("mod/startDownload", async (artifacts, { dispatch, getState }) => {
  const state = getState();
  const mod = selectModItem(state);

  if (!mod) {
    return;
  }

  const productId = selectProductId(state);
  const downloadFolder = selectDownloadFolder(state);

  const { modSlug, artifactId, itemId, privateNamespace, title } = mod;

  let signedToken: string;

  try {
    signedToken = await modApi.getSignedToken({ productId, modSlug });
  } catch (err) {
    dispatch(serverError(err));

    return;
  }

  try {
    await downloaderBridge.download({
      entityid: modSlug,
      entitytype: EntityType.MOD,
      signedtoken: signedToken,
      productid: productId,
      sandboxid: privateNamespace,
      catalogitemid: itemId,
      artifactid: artifactId,
      title,
      artifacts,
      downloadfolder: downloadFolder,
    });
  } catch (err) {
    dispatch(bridgeError(err));
  }
});

const initialState: ModState = {
  mods: {
    items: {},
  },
  restrictions: {
    data: { maxBuildSize: -1, prohibitedFileExtensions: [] },
  },
};

const modSlice = createSlice({
  name: "mod",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModRestrictions.fulfilled, (state, action) => {
        state.restrictions.data = action.payload;
      })
      .addCase(fetchCreatedMods.fulfilled, (state, action) => {
        state.mods.items = action.payload;
      })
      .addMatcher(
        isOneOf([fetchMod.fulfilled, fetchModForModerator.fulfilled]),
        (state, action) => {
          state.mods.items[action.payload.modSlug] = action.payload;
        }
      );
  },
});

export default modSlice.reducer;
