import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router";

import { PageWrapper } from "../../components";
import { useEntityTypeMismatch } from "../hooks";
import { ModUploadPage } from "./mod";
import { BinaryUpload } from "./product-binaries";
import {
  selectEntityType,
  selectOrganizationId,
} from "../../store/selectors/app.selectors";
import { UploadStateProvider } from "../UploadStateProvider";
import { clientError } from "../../store/reducers/app.reducer";
import {
  selectIsModMarketSuspended,
  selectProductId,
  selectProductSandboxId,
} from "../../store/selectors/product.selectors";
import { LoadingPage } from "../common";
import { EntityType } from "../../models";
import { fetchProduct } from "../../store/reducers/product.reducer";
import {
  BinaryUploadProvider,
  OffersDataProvider,
} from "./product-binaries/data";

export const UploadContainer: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const entityType = useSelector(selectEntityType);
  const productId = useSelector(selectProductId);
  const productSandboxId = useSelector(selectProductSandboxId);
  const organizationId = useSelector(selectOrganizationId);
  const isModMarketSuspended = useSelector(selectIsModMarketSuspended);
  const {
    isLoading: isEntityTypeMismatchProcessing,
    isEntityTypeMismatch,
  } = useEntityTypeMismatch();

  const isModEntity = entityType === EntityType.MOD;
  const isPageLoading =
    (isModEntity && isModMarketSuspended !== false && organizationId) ||
    isEntityTypeMismatchProcessing;

  React.useEffect(() => {
    if (isModEntity && organizationId) {
      dispatch(fetchProduct({ organizationId, productId, productSandboxId }));
    }
  }, [dispatch, isModEntity, productId, organizationId]);

  if (!isEntityTypeMismatchProcessing && isEntityTypeMismatch) {
    dispatch(
      clientError({
        errorCode: 403,
      })
    );
  }

  if (isPageLoading) {
    return <LoadingPage description={t("common.data_loading_in_progress")} />;
  }

  return (
    <PageWrapper>
      <UploadStateProvider>
        <Switch>
          <Route path="/upload/mod">
            <ModUploadPage />
          </Route>
          <Route path="/upload/game">
            <BinaryUploadProvider>
              <OffersDataProvider>
                <BinaryUpload />
              </OffersDataProvider>
            </BinaryUploadProvider>
          </Route>

          {!organizationId && <Redirect to="/organizations" />}
          <Redirect to={`/upload/${entityType}`} />
        </Switch>
      </UploadStateProvider>
    </PageWrapper>
  );
};
