import { Typography } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const BinaryUploadHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Typography color="textPrimary" variant="h2" align="center">
      {t("upload_binaries.title")}
    </Typography>
  );
};
