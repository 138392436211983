import * as React from "react";
import { useFormikContext } from "formik";
import { Trans, useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import { Box, Typography } from "@material-ui/core";

import { initialFolderPathState, useBinaryUploadData } from "../../../../data";
import { BinaryUploadFoldersFormFields } from "../../types";
import { uploaderBridge } from "../../../../../../../services";
import {
  BinaryVersionField,
  AppLaunchField,
  BinaryFolderField,
  LocalCacheField,
} from "./components";
import {
  StyledExternalLink,
  StyledLearnMoreLink,
} from "./BinaryUploadFoldersForm.styles";

export const BinaryUploadFoldersForm: React.FC = () => {
  const { values, setFieldValue, dirty } = useFormikContext<
    BinaryUploadFoldersFormFields
  >();
  const { t } = useTranslation();
  const {
    setFolderPaths,
    setShouldConfirmationPageBeShown,
  } = useBinaryUploadData();

  const onSelectFolder = (fieldName: string) => async () => {
    const uploadFolderPath = await uploaderBridge.openDirectoryDialog();

    setFieldValue(fieldName, uploadFolderPath);
  };

  const handleOpenGuidelines = () => {
    uploaderBridge.openSupportPage(
      `${process.env.REACT_APP_EPIC_SERVER_URL}/docs/services/en-US/EpicGamesStore/index.html`
    );
  };

  React.useEffect(() => {
    const areValuesChanged = !isEqual(values, initialFolderPathState);

    setShouldConfirmationPageBeShown(areValuesChanged);
    setFolderPaths(values);
  }, [values, dirty]);

  return (
    <form noValidate>
      <Typography
        color="textSecondary"
        variant="body1"
        align="center"
        gutterBottom
      >
        {t("upload_binaries.folders.description")}
      </Typography>
      <Box mb={8}>
        <Typography color="textSecondary" variant="body1" align="center">
          <Trans
            i18nKey="upload_binaries.folders.guidelines"
            components={{
              a: (
                <StyledLearnMoreLink
                  onClick={handleOpenGuidelines}
                  underline="hover"
                  color="primary"
                />
              ),
              icon: <StyledExternalLink fontSize="inherit" />,
            }}
          />
        </Typography>
      </Box>
      <Box mb={6}>
        <BinaryVersionField />
      </Box>
      <Box mb={6}>
        <BinaryFolderField onSelect={onSelectFolder} />
      </Box>
      <Box mb={6}>
        <AppLaunchField />
      </Box>
      <LocalCacheField onSelect={onSelectFolder} />
    </form>
  );
};
