import { Platform } from "@epic-mod-market/core";

import { AppMode, EntityType } from "../../../models";
import { BridgeType, resolveBridgeType } from "./BridgeApi";
import { commonLauncher } from "./launcher/common.launcher";
import { commonWeb } from "./web/common.web";

export interface AppParams {
  action: AppMode | null;
  entitytype: EntityType | null;
  productid: string;
  productnamespaceid?: string;
  entityid?: string;
  organizationid?: string;
  artifactid?: string;
  title?: string;
  backurl?: string;
  buildroot?: string;
  sandboxid?: string;
  buildversion?: string;
  supportedplatforms?: Platform[];
  bisplatformselectionenabled?: boolean;
  prohibitedfileextensions?: string[];
  maxbuildsize?: number;
  locale?: string;
  clouddir?: string;
  applaunch?: string;
  fileignorelist?: string;
  fileattributelist?: string;
  appargs?: string;
  bliveupdate?: boolean;
}

export interface CommonBridge {
  getAccessToken: () => Promise<string>;
  getAppParams: () => Promise<AppParams>;
}

const bridges = {
  [BridgeType.LAUNCHER]: commonLauncher,
  [BridgeType.WEB]: commonWeb,
};

export const commonBridge = bridges[resolveBridgeType()];
