import { OS } from "../../utils/operatingSystem";
import { useTranslation } from "react-i18next";

const FOLDER_PATH_MAX_LENGTH: Record<OS, number> = {
  [OS.Mac]: 255,
  [OS.Windows]: 260,
};

const FOLDER_FORBIDDEN_NAMES: Record<OS, string[]> = {
  [OS.Mac]: [],
  [OS.Windows]: [
    "com1",
    "com2",
    "com3",
    "com4",
    "com5",
    "com6",
    "com7",
    "com8",
    "com9",
    "lpt1",
    "lpt2",
    "lpt3",
    "lpt4",
    "lpt5",
    "lpt6",
    "lpt7",
    "lpt8",
    "lpt9",
    "con",
    "nul",
    "prn",
  ],
};

const FOLDER_NAME_REGEX: Record<OS, RegExp> = {
  [OS.Mac]: /^[^.].*/,
  [OS.Windows]: /^[^?<>*|"^]*[^. ?<>*|"^]$/,
};

export const useValidateFolderPath = (
  path: string,
  os: OS | undefined
): string | undefined => {
  const { t } = useTranslation();

  if (!os || !path) {
    return undefined;
  }

  // 1. split by any sequence of separator ("/") as technically it's a valid structure
  // 2. filter out empty string that may occur after splitting path by "/*n", when path starts or ends with "/*n"
  // 3. check for forbidden names or characters
  const pathContainsInvalidFolderName = path
    .replace(/\\/g, "/")
    .split(/\/+/)
    .filter(Boolean)
    .some(
      (folder) =>
        FOLDER_FORBIDDEN_NAMES[os].includes(folder) ||
        !FOLDER_NAME_REGEX[os].test(folder)
    );

  if (pathContainsInvalidFolderName) {
    return t("common.validation.binaries_path.invalid_path");
  }

  if (path.length > FOLDER_PATH_MAX_LENGTH[os]) {
    return t("common.validation.binaries_path.too_long_path");
  }
};
