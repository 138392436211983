import * as React from "react";
import { Redirect } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  downloaderBridge,
  uploaderBridge,
  configureAxios,
} from "../../services";
import { bridgeError, signInSuccess } from "../../store/reducers/app.reducer";
import { AppSignInStateManager } from "../../managers";
import { LoadingPage } from ".";
import { AppMode } from "../../models";
import {
  selectSignInStatus,
  selectAppMode,
} from "../../store/selectors/app.selectors";

export const SignInPage: React.FC = () => {
  const { t } = useTranslation();
  const [isAxiosConfigured, setIsAxiosConfigured] = React.useState(false);
  const signInStatus = useSelector(selectSignInStatus);
  const appMode = useSelector(selectAppMode);

  const isLoading =
    AppSignInStateManager.isLoading(signInStatus) || !isAxiosConfigured;

  const dispatch = useDispatch();

  const signInCallback = React.useCallback(
    ({ errormessages = [], errorcode, supporturl }) => {
      if (errormessages.length) {
        dispatch(bridgeError({ errormessages, errorcode, supporturl }));
      } else {
        dispatch(signInSuccess());
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (!AppSignInStateManager.isLoaded(signInStatus)) {
      return;
    }

    (async () => {
      await configureAxios();
      setIsAxiosConfigured(true);
    })();
  }, [signInStatus]);

  React.useEffect(() => {
    if (appMode === AppMode.UPLOAD) {
      uploaderBridge.registerSignInCompleteCallback(signInCallback);
    }

    if (appMode === AppMode.DOWNLOAD) {
      downloaderBridge.registerSignInCompleteCallback(signInCallback);
    }
  }, [appMode, dispatch, signInCallback]);

  if (isLoading) {
    return <LoadingPage description={t("common.login_process")} />;
  }

  if (AppSignInStateManager.isLoaded(signInStatus)) {
    return <Redirect to={`/${appMode}`} />;
  }

  return null;
};
