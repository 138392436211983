import { Status } from "./api";

export const MAX_TAGS_COUNT = 1000;

export enum GroupName {
  GENRE = "genre",
  FEATURE = "feature",
  PLATFORM = "platform",
  CONTENT = "content",
  ACCESSIBILITY = "accessibility",
}

export interface SandboxTag {
  id: string;
  name: string;
  groupName?: GroupName;
}

export enum TagName {
  EARLY_ACCESS = "EARLY ACCESS",
  WINDOWS = "Windows",
  MAC_OS = "Mac OS",
}

export interface UseSandboxTags {
  platforms: SandboxTag[];
  status: Status;
}
