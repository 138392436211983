import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import { Page } from "../../../common";
import { selectEntityTitle } from "../../../../store/selectors/entity.selectors";
import {
  Description,
  FolderSelector,
  FooterButtons,
  SafeContainer,
  Title,
} from "../../../../components";
import { useValidateFolderPath } from "../../../hooks/useValidateFolderPath";
import { getOS } from "../../../../utils/operatingSystem";

interface Props {
  folderPath: string;
  handleCancel: () => void;
  handleSelectFolder: () => void;
  handleChangeFolderClick: () => void;
  handleChangeFolder: (e: React.ChangeEvent<HTMLInputElement>) => void;
  closeLabel?: string;
  confirmLabel?: string;
}

export const FolderSelectionPage: React.FC<Props> = ({
  folderPath,
  handleCancel,
  handleChangeFolderClick,
  handleChangeFolder,
  handleSelectFolder,
  closeLabel,
  confirmLabel,
}) => {
  const title = useSelector(selectEntityTitle);
  const { t } = useTranslation();

  const os = getOS();
  const error = useValidateFolderPath(folderPath, os);

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <FooterButtons
          onClose={handleCancel}
          onConfirm={handleSelectFolder}
          disabled={!folderPath || Boolean(error)}
          closeLabel={closeLabel}
          confirmLabel={confirmLabel}
        />
      }
    >
      <SafeContainer>
        <Description mb={3}>{t("upload.choose_folder")}</Description>
        <Description mb={6} color="text.primary">
          {t("upload.data_validation_warning")}
        </Description>
        <Box minHeight="85px">
          <FolderSelector
            value={folderPath}
            onSelect={handleChangeFolderClick}
            onChange={handleChangeFolder}
            inputProps={{ disabled: !os }}
            error={error}
          />
        </Box>
      </SafeContainer>
    </Page>
  );
};
