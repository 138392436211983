import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./app.reducer";
import uploadReducer from "./upload.reducer";
import downloadReducer from "./download.reducer";
import modReducer from "./mod.reducer";
import gameReducer from "./game.reducer";
import productReducer from "./product.reducer";

export const rootReducer = combineReducers({
  app: appReducer,
  upload: uploadReducer,
  download: downloadReducer,
  mod: modReducer,
  game: gameReducer,
  product: productReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
