import React from "react";
import { Typography, Box, BoxProps } from "@material-ui/core";

export const Description: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box maxWidth="100%" whiteSpace="pre-line" color="grey.600" {...props}>
    <Typography variant="body1" color="inherit">
      {children}
    </Typography>
  </Box>
);
