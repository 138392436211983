import React, { PureComponent } from "react";
import PropTypes, { shape } from "prop-types";
import { utcParse } from "d3-time-format";
import { format } from "d3-format";

import { installerBridge } from "../../../../../services/api/bridges";
import { ChartContainer } from "./ChartContainer";
import { SpeedChart, FileOperationChart } from "./charts";

// TODO: copied from diesel-src/services/diesel-site/node/app/scripts/client/updater-drawer/components/install-stats/InstallStats.js
export class InstallStats extends PureComponent {
  state = {
    inrangedrawfileoperations: [],
    data: [],
  };

  static propTypes = {
    timePeriod: PropTypes.number,
    width: PropTypes.number,
    view: shape({
      linesprefocus: PropTypes.number,
      linespostfocus: PropTypes.number,
      minlinethreshold: PropTypes.number,
      maxlinethreshold: PropTypes.number,
      width: PropTypes.number,
      bytesperunit: PropTypes.number,
      minbarsize: PropTypes.number,
      maxbarsize: PropTypes.number,
    }),
  };

  static defaultProps = {
    timePeriod: 30000,
    width: 370,
    view: {
      linesprefocus: 1,
      linespostfocus: 50,
      minlinethreshold: 1,
      maxlinethreshold: 1,
      width: 60,
      bytesperunit: 1024 * 128,
      minbarsize: 6,
      maxbarsize: 60,
    },
  };

  constructor(args) {
    super(args);

    this.d3formatMemory = format(".3s");
  }

  /**
   * Subscribe to launcher events for updates on installing task and installer changed update to clear state
   * @returns {Promise.<void>}
   */
  async componentDidMount() {
    const { view } = this.props;
    this.installerBridge = installerBridge;

    // Set view metadata over the bridge for how we want data back, then subscribe to updates on install progress
    this.installerBridge.setFileOperationView(view);

    this.updateSubscription = await this.installerBridge.subscribe(
      "OnUpdate",
      this.updateHandle
    );
    this.changedSubscription = await this.installerBridge.subscribe(
      "OnInstallerChanged",
      this.resetCharts
    );
  }

  /**
   * Clear out subscriptions made to the installer bridge so we don't have bad references to an unmounted component
   */
  componentWillUnmount() {
    if (!this.installerBridge) {
      return;
    }

    // Unsubscribe from install updates when we unmount to stop having the large JSON blobs sent to us
    if (this.updateSubscription) {
      this.installerBridge.unsubscribe(this.updateSubscription);
    }
    if (this.changedSubscription) {
      this.installerBridge.unsubscribe(this.changedSubscription);
    }
  }

  formatSpeed = (d, suffix) => {
    // TODO: Don't duplicate this function. Not sure if it's appropriate to make a util that creates the d3 formatter
    const preformattedMemory = `${this.d3formatMemory(d)}B`;
    return suffix ? preformattedMemory + suffix : preformattedMemory;
  };

  resetCharts = () => {
    this.setState({
      inrangedrawfileoperations: [],
      data: [],
    });
  };

  /**
   * Gets the bridge data chunks with install data points and transform into state the components accept.
   * Transforms the date to a format we can map to the x-axis for time, and sets the range of valid data
   * in the blob
   *
   * @param args
   */
  updateHandle = (args) => {
    const { timePeriod } = this.props;
    const { data } = this.state;

    const { inrangedrawfileoperations, samplingdatetime } = args;
    args.inrangedrawfileoperations = null;

    const parseTime = utcParse("%Y-%m-%dT%H:%M:%S.%L%Z");
    args.date = parseTime(samplingdatetime);
    data.unshift(args);

    const lastDate = new Date(data[0].date.getTime());
    lastDate.setTime(lastDate.getTime() - timePeriod);
    for (let i = data.length - 1; i > 0; --i) {
      if (data[i].date < lastDate) {
        data.splice(i, 1);
      } else {
        break;
      }
    }

    this.setState({
      inrangedrawfileoperations,
      data,
    });
  };

  render() {
    const { view, width } = this.props;
    const { data, inrangedrawfileoperations } = this.state;

    return (
      <>
        <ChartContainer titleKey="download.progress.details_download" mt={3}>
          <SpeedChart
            width={width}
            id="downloadbytespeed"
            data={data}
            colors={["#a6c6d6", "#90b1cb"]}
          />
        </ChartContainer>
        <ChartContainer titleKey="download.progress.details_read" mt={4}>
          <SpeedChart
            width={width}
            id="diskreadbytespeed"
            data={data}
            colors={["#b1a6d6", "#a390cb"]}
          />
        </ChartContainer>
        <ChartContainer titleKey="download.progress.details_write" mt={4}>
          <SpeedChart
            width={width}
            id="diskwritebytespeed"
            data={data}
            colors={["#afd6a6", "#95cb90"]}
          />
        </ChartContainer>
        <ChartContainer titleKey="download.progress.details_operations" mt={4}>
          <FileOperationChart
            width={width}
            id="fileoperation-chart"
            data={data}
            view={view}
            inrangedrawfileoperations={inrangedrawfileoperations}
          />
        </ChartContainer>
      </>
    );
  }
}
