import * as React from "react";
import { useParams } from "react-router-dom";

export type TimeFormatKey = "datetime" | "date" | "time" | "utc";

// TODO: should we export following component from @epic-mod-market ?
export const TimeFormat: Record<TimeFormatKey, Intl.DateTimeFormatOptions> = {
  datetime: {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  },
  date: {
    month: "short",
    day: "numeric",
    year: "numeric",
  },
  time: {
    hour: "2-digit",
    minute: "2-digit",
  },
  utc: {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    timeZone: "UTC",
  },
};

export interface TimeProps
  extends Omit<React.PropsWithRef<JSX.IntrinsicElements["time"]>, "dateTime"> {
  timestamp: string | number | Date;
  format?: TimeFormatKey | Intl.DateTimeFormatOptions;
}

export const Time = React.forwardRef<HTMLTimeElement, TimeProps>(function Time(
  { timestamp, format: formatProp = TimeFormat.datetime, ...props },
  ref
) {
  const { locale } = useParams<{ locale: string }>();
  const date =
    typeof timestamp === "string" || typeof timestamp === "number"
      ? new Date(timestamp)
      : timestamp;
  const format =
    typeof formatProp === "string" ? TimeFormat[formatProp] : formatProp;

  return (
    <time {...props} dateTime={date.toISOString()} ref={ref}>
      {date.toLocaleString(locale, format)}
    </time>
  );
});
