import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { EntityType, SandboxPurposeType } from "../../models";
import { commonApi } from "../../services";
import { serverError } from "../../store/reducers/app.reducer";
import {
  selectEntityType,
  selectSandboxId,
} from "../../store/selectors/app.selectors";
import { isModEntity as checkIsModEntity } from "../../utils/app.utils";

export const useEntityTypeMismatch = () => {
  const [sandboxPurposeTypes, setSandboxPurposeTypes] = React.useState<
    SandboxPurposeType[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const entityType = useSelector(selectEntityType);
  const isModEntity = checkIsModEntity(entityType);
  const sandboxId = useSelector(selectSandboxId);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isModEntity) {
      setIsLoading(false);
      return;
    }

    const getSandboxPurposeTypes = async () => {
      try {
        const sandboxPurposeTypes = await commonApi.getSandboxPurposeTypes(
          sandboxId
        );
        setSandboxPurposeTypes(sandboxPurposeTypes);
      } catch (err) {
        dispatch(serverError(err));
      } finally {
        setIsLoading(false);
      }
    };

    getSandboxPurposeTypes();
  }, [dispatch, isModEntity, sandboxId]);

  const isEntityTypeMismatch =
    !isModEntity &&
    sandboxPurposeTypes.includes(SandboxPurposeType.FOR_MOD_MARKETPLACE);

  return {
    isLoading,
    isEntityTypeMismatch,
  };
};
