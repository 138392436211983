import styled from "styled-components";
import { Button as MuiButton } from "@material-ui/core";

export const Button = styled(MuiButton)`
  && {
    min-width: 170px;
    min-height: ${({ size }) => (size === "large" ? "60px" : "50px")};
    line-height: 1;
  }
`;
