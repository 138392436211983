import * as React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
  BinaryUploadFoldersFooter,
  BinaryUploadFoldersForm,
} from "./components";
import { BinaryUploadFoldersFormFields } from "./types";
import { BinaryUploadPage, LoadingPage } from "../../../../common";
import { BinaryUploadHeader } from "../../components";
import { useBinaryUploadData } from "../../data";
import { Status } from "../../types";

export const BinarySelectFoldersPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    folderPaths,
    setFolderPaths,
    generateCloudDirStatus,
  } = useBinaryUploadData();

  const isPageLoading = generateCloudDirStatus === Status.REQUEST;

  const onSubmit = React.useCallback(
    (values) => {
      setFolderPaths(values);

      history.push("/upload/game/additional-information");
    },
    [setFolderPaths, history]
  );

  if (isPageLoading) {
    return <LoadingPage description={t("common.data_loading_in_progress")} />;
  }

  return (
    <Formik<BinaryUploadFoldersFormFields>
      initialValues={folderPaths}
      onSubmit={onSubmit}
    >
      <BinaryUploadPage
        header={<BinaryUploadHeader />}
        footer={<BinaryUploadFoldersFooter />}
      >
        <BinaryUploadFoldersForm />
      </BinaryUploadPage>
    </Formik>
  );
};
