import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { Button } from "../../../../../components/epic-ui-components";
import { BinaryUploadPage } from "../../../../common";
import { BinaryUploadHeader } from "../../components";
import { ProgressBar } from "../../../../../components";
import { useUploadState } from "../../../../UploadStateProvider";
import { uploaderBridge } from "../../../../../services";

export const ProgressUploadPage: React.FC = () => {
  const { t } = useTranslation();
  const { progress } = useUploadState();

  const onCancel = () => {
    uploaderBridge.onCancel();
  };

  return (
    <BinaryUploadPage
      header={<BinaryUploadHeader />}
      footer={
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </Button>
      }
      centeredContent
    >
      <Box textAlign="center">
        <Box mb={6}>
          <Typography color="textSecondary">
            {t("upload.in_progress")}
          </Typography>
        </Box>
        <ProgressBar progress={progress} />
      </Box>
    </BinaryUploadPage>
  );
};
