import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// TODO: think of where Game restrictions type should come from
import { ModRestrictions as GameRestrictions } from "@epic-mod-market/core";

import { gameApi, uploaderBridge } from "../../services";
import { selectAppParams } from "../selectors/app.selectors";
import {
  selectUploadFolder,
  selectUploadParams,
} from "../selectors/upload.selectors";

interface GameState {
  restrictions: GameRestrictions;
  isLoading: boolean;
}

// TODO: remove redundant params from uploadParams
export const startGameUpload = () => (dispatch, getState) => {
  const state = getState();

  const appParams = selectAppParams(state);
  const { action, ...uploadParams } = appParams;

  const { platforms } = selectUploadParams(state) || {};
  const folderPath = selectUploadFolder(state);

  uploaderBridge.upload({
    ...uploadParams,
    buildroot: folderPath,
    supportedplatforms: platforms,
  });
};

const initialState: GameState = {
  restrictions: { maxBuildSize: -1, prohibitedFileExtensions: [] },
  isLoading: false,
};

export const fetchGameRestrictions = createAsyncThunk(
  "game/fetchGameRestrictions",
  async (productId: string): Promise<GameRestrictions | never> => {
    const restrictions = await gameApi.getRestrictions({ productId });
    return restrictions;
  }
);

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameRestrictions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGameRestrictions.fulfilled, (state, action) => {
        state.restrictions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchGameRestrictions.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default gameSlice.reducer;
