import * as React from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { BinaryUploadField } from "../../../../../components";
import { FolderSelector } from "../../../../../../../../components";
import { BinaryUploadFoldersFormFields } from "../../../types";

interface LocalCacheFieldProps {
  onSelect: (name: string) => () => void;
}

export const LocalCacheField: React.FC<LocalCacheFieldProps> = ({
  onSelect,
}) => {
  const {
    values: { binaryFolder },
  } = useFormikContext<BinaryUploadFoldersFormFields>();
  const { t } = useTranslation();

  const validate = React.useCallback(
    (localCacheFolder: string) => {
      if (!binaryFolder) {
        return;
      }

      if (localCacheFolder === binaryFolder) {
        return t(
          "upload_binaries.folders.errors.local_cache.same_as_binary_folder"
        );
      }

      if (localCacheFolder.includes(binaryFolder)) {
        return t(
          "upload_binaries.folders.errors.local_cache.inside_binary_folder"
        );
      }
    },
    [t, binaryFolder]
  );

  return (
    <BinaryUploadField
      label={t("upload_binaries.folders.local_cache.label")}
      description={t("upload_binaries.folders.local_cache.description")}
    >
      <Field name="localCacheFolder" validate={validate}>
        {({ field: { value, name }, form: { errors } }: FieldProps) => {
          const error = String(errors[name] ?? "");

          return (
            <FolderSelector
              value={value}
              onSelect={onSelect(name)}
              error={error}
              inputProps={{
                disabled: true,
                variant: "outlined",
                ...(!Boolean(value) && {
                  label: t("upload_binaries.folders.local_cache.placeholder"),
                }),
              }}
            />
          );
        }}
      </Field>
    </BinaryUploadField>
  );
};
