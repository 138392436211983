import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Platform } from "@epic-mod-market/core";

import { modApi, ModMetaParams, uploaderBridge } from "../../services";
import { selectUploadPlatforms } from "../selectors/upload.selectors";
import { serverError } from "./app.reducer";
import { RootState } from "./";
import { selectAvailablePlatforms } from "../selectors/product.selectors";

export interface UploadParams {
  buildVersion?: string;
  isPlatformsConfigured: boolean;
  isPlatformSelectionEnabled: boolean;
  backURL: string;
  platforms?: Platform[];
  availablePlatforms: Platform[];
}

interface UploadState {
  folder: string;
  params: UploadParams | undefined;
}

export const uploadInitialState: UploadState = {
  folder: "",
  params: undefined,
};

export const fetchModUploadParams = createAsyncThunk<
  UploadParams,
  ModMetaParams,
  { state: RootState }
>(
  `upload/fetchModParams`,
  async (
    params: ModMetaParams,
    { dispatch, getState }
  ): Promise<UploadParams | never> => {
    const { productId, productSandboxId } = params;

    const state = getState();
    const selectedPlatforms = selectUploadPlatforms(state);
    const availablePlatforms = selectAvailablePlatforms(state);
    const isPlatformsConfigured = availablePlatforms.length > 0;

    try {
      const [
        buildVersionResponse,
        multiplePlatformsResponse,
        backURLResponse,
      ] = await Promise.all([
        modApi.getBuildVersion(),
        modApi.getIsPlatformsSelectionEnabled({ productId, productSandboxId }),
        modApi.getBackURL(params),
      ]);

      const buildVersion = String(buildVersionResponse.buildVersion);
      const {
        isPlatformSelectionEnabled: isPlatformSelectionEnabledSettingOn,
      } = multiplePlatformsResponse;
      const { backUrl: backURL } = backURLResponse;

      const isPlatformSelectionEnabled =
        !isPlatformsConfigured || isPlatformSelectionEnabledSettingOn;

      const platforms =
        selectedPlatforms ||
        (isPlatformSelectionEnabled && availablePlatforms.length > 1
          ? undefined
          : availablePlatforms);

      return {
        buildVersion,
        isPlatformsConfigured,
        isPlatformSelectionEnabled,
        backURL,
        platforms,
        availablePlatforms,
      };
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

const uploadSlice = createSlice({
  name: "upload",
  initialState: uploadInitialState,
  reducers: {
    updateFolder(state, action) {
      state.folder = action.payload;
    },
    updateUploadParams(state, action) {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModUploadParams.fulfilled, (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload,
      };
    });
  },
});

export const { updateFolder, updateUploadParams } = uploadSlice.actions;

export const cancelUpload = () => {
  uploaderBridge.onCancel();
};

export default uploadSlice.reducer;
