import axios from "axios";
import { ArtifactItem, ModProduct, Organization } from "@epic-mod-market/core";

import { SandboxPurposeType } from "../../../../models";
import { APIRequest, bptServiceURL, unwrapResponse } from "../core";

export const commonClient = axios.create({
  baseURL: bptServiceURL,
});

interface MetaParams {
  productId: string;
}

export interface ArtifactMetaParams extends MetaParams {
  organizationId: string;
  sandboxId: string;
  artifactId: string;
}

const getOrganizations: APIRequest<Organization[]> = () => {
  return commonClient.get("/organizations").then(unwrapResponse);
};

const getProduct: APIRequest<
  ModProduct,
  { organizationId: string; productId: string; productSandboxId?: string }
> = ({ organizationId, productId, productSandboxId }) => {
  // if pass empty string to params, it'll be in query with empty value,
  // so need to replace it with undefined
  const config = {
    params: {
      productSandboxId:
        productSandboxId?.length === 0 ? undefined : productSandboxId,
    },
  };

  return commonClient
    .get(`/${organizationId}/product/${productId}`, config)
    .then(unwrapResponse);
};

const getLiveArtifactsForSandbox: APIRequest<
  { builds: ArtifactItem[] },
  ArtifactMetaParams
> = ({ organizationId, productId, sandboxId, artifactId }) => {
  return commonClient
    .get(
      `/${organizationId}/product/${productId}/sandbox/${sandboxId}/artifact/${artifactId}`
    )
    .then(unwrapResponse);
};

const getSandboxPurposeTypes: APIRequest<SandboxPurposeType[], string> = (
  sandboxId
) => {
  return commonClient
    .get(`/sandbox/${sandboxId}/purpose-types`)
    .then(unwrapResponse);
};

export const commonApi = {
  getOrganizations,
  getProduct,
  getLiveArtifactsForSandbox,
  getSandboxPurposeTypes,
};
