import React from "react";
import { uploaderBridge } from "../services";

export interface UploadStateManager {
  progress: number;
  resetProgress: () => void;
}

const UploadStateContext = React.createContext<UploadStateManager>({
  progress: 0,
  resetProgress: () => {},
});

export const UploadStateProvider: React.FC = ({ children }) => {
  const [progress, setProgress] = React.useState(0);

  uploaderBridge.registerUploadStateChangeCallback(({ progress }) => {
    setProgress(progress);
  });

  const resetProgress = React.useCallback(() => {
    setProgress(0);
  }, []);

  const context = {
    progress,
    resetProgress,
  };

  return (
    <UploadStateContext.Provider value={context}>
      {children}
    </UploadStateContext.Provider>
  );
};

export const useUploadState = (): UploadStateManager => {
  return React.useContext(UploadStateContext);
};
