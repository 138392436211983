import * as React from "react";

import { IconWrapper, IconProps } from "../IconWrapper";

export const DraftIcon: React.FC<IconProps> = ({ title, ...props }) => (
  <IconWrapper {...props}>
    <title>{title}</title>
    <path
      d="M99.992 0c5.344 0 9.676 4.333 9.676 9.677 0 5.345-4.332 9.678-9.676 9.678H35.48c-8.907 0-16.128 7.221-16.128 16.129v129.032c0 8.907 7.22 16.129 16.128 16.129h129.021c8.908 0 16.128-7.222 16.128-16.129V100c0-5.345 4.333-9.678 9.676-9.678 5.345 0 9.677 4.333 9.677 9.678v64.516c0 19.597-15.885 35.484-35.48 35.484H35.48C15.885 200 0 184.113 0 164.516V35.484C0 15.887 15.885 0 35.48 0h64.512zm80.603 3.034l1.483 1.296 14.632 14.633a11.185 11.185 0 011.185 14.456l-1.185 1.39L180.36 51.16l-.021-.022-91.567 91.583.051.04-45.51 17.739c-2.35.572-4.503-1.2-4.604-3.453l.088-.989 17.175-46.153 95.933-95.934 9.66-9.641c5.202-5.184 13.35-5.616 19.029-1.296z"
      fillRule="nonzero"
      opacity=".9"
    />
  </IconWrapper>
);
