import { css } from "emotion";
import React, { Component } from "react";
import PropTypes, { shape } from "prop-types";
import { select } from "d3-selection";
import { scaleLinear } from "d3-scale";

const styles = {
  bar: css({
    stroke: "none",
  }),
};

// TODO: copied from diesel-src/services/diesel-site/node/app/scripts/client/updater-drawer/components/install-stats/charts/FileOperationChart.js
export class FileOperationChart extends Component {
  useableWidth = undefined;
  useableHeight = undefined;
  state = {};

  static propTypes = {
    id: PropTypes.string,
    colors: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    margin: shape({
      top: PropTypes.number,
      left: PropTypes.number,
      bottom: PropTypes.number,
      right: PropTypes.number,
    }),
    view: shape({
      linesprefocus: PropTypes.number,
      linespostfocus: PropTypes.number,
      minlinethreshold: PropTypes.number,
      maxlinethreshold: PropTypes.number,
      width: PropTypes.number,
      bytesperunit: PropTypes.number,
      minbarsize: PropTypes.number,
      maxbarsize: PropTypes.number,
    }).isRequired,
    inrangedrawfileoperations: PropTypes.array,
  };

  static defaultProps = {
    height: 70,
    margin: {
      top: 10,
      right: 0,
      bottom: 0,
      left: 0,
    },
    colors: [
      "c3c2c2", // Unknown - Grey

      "a390cb", // PendingLocalChunkDbData - Aqua
      "b1a6d6", // RetrievingLocalChunkDbData - Light Aqua

      "a390cb", // PendingLocalInstallData
      "b1a6d6", // RetrievingLocalInstallData - Light

      "90b1cb", // PendingRemoteCloudData - Purple
      "a6c6d6", // RetrievingRemoteCloudData - Light Purple

      "a390cb", // PendingLocalDataStore
      "b1a6d6", // RetrievingLocalDataStore

      "c3c2c2", // DataInMemoryStore

      "c8e2bf", // Staged - Light Green 3
      "c8e2bf", // Installed - Green 1

      "afd6a6", // Verifying - Orange
      "rgb(214, 39, 40)", // VerifiedFail - Red
      "95cb90", // VerifiedSuccess - Green
    ],
  };

  setChartProps({ view, height, width, margin }) {
    this.useableHeight = height - margin.top - margin.bottom;
    this.useableWidth = width - margin.left - margin.right;

    this.x = scaleLinear()
      .domain([0, view.linesprefocus + view.linespostfocus])
      .range([0, this.useableWidth]);
    this.y = scaleLinear()
      .domain([0, view.width])
      .range([0, this.useableHeight]);
  }

  constructor(props) {
    super(props);

    const { view, height, width, margin } = props;

    this.setChartProps({
      view,
      height,
      width,
      margin,
    });
  }

  componentDidMount() {
    const { id, width, height, margin } = this.props;

    // Create the root svg item that d3 will draw this chart to
    const svg = select(this._rootNode)
      .append("svg")
      .style("id", id)
      .style("width", width)
      .style("height", height);

    svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
  }

  /**
   * Draw the newest state of the chart. Gets the in-range file operations and draws
   * the blocks to represent what is happening with each chunk in view
   *
   * @param margin
   * @param colors
   * @param inrangedrawfileoperations
   */
  updateChart = ({ margin, colors, inrangedrawfileoperations }) => {
    const root = select(this._rootNode);
    const g = root.select("g");

    let rangefileoperationsoffset = 0;
    const first =
      inrangedrawfileoperations[inrangedrawfileoperations.length - 1];

    if (first) {
      rangefileoperationsoffset = first.left;
    }

    g.attr(
      "transform",
      `translate(${
        margin.left - this.x(rangefileoperationsoffset + 1) + this.useableWidth
      },${margin.top})`
    );

    const rects = g.selectAll("rect").data(inrangedrawfileoperations, (d) => {
      return d.id;
    });

    rects
      .attr("height", (d) => {
        return this.y(d.width) + 1;
      })
      .style("fill", (d) => {
        return colors[d.state];
      });

    rects
      .enter()
      .append("rect")
      .classed(styles.bar, true)
      .classed("visible", true)
      .style("fill", (d) => {
        return colors[d.state];
      })
      .style("opacity", 100)
      .attr("_top", (d) => {
        return d.top;
      })
      .attr("_left", (d) => {
        return d.left;
      })
      .attr("_width", (d) => {
        return d.width;
      })
      .attr("y", (d) => {
        return this.y(d.top) - 1;
      })
      .attr("width", (d) => {
        return this.x(1) + 1;
      })
      .attr("x", (d) => {
        return this.x(d.left) - 1;
      })
      .attr("height", (d) => {
        return this.y(d.width) + 1;
      });

    rects.exit().filter(".visible").classed("visible", false).remove();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      margin,
      colors,
      // width,
      // view,
      inrangedrawfileoperations,
    } = nextProps;

    // TODO handle width changes
    // if (currentWidth && width && currentWidth !== width) {
    //     this.setChartProps({
    //         view,
    //         height,
    //         width,
    //         margin
    //     });
    // }

    this.updateChart({ margin, colors, inrangedrawfileoperations });
  }

  shouldComponentUpdate() {
    // Let D3 entirely control dom of things in this component. Prevents component re-rendering via React.
    return false;
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    return (
      <div className="file-operation-container" ref={this._setRef.bind(this)} />
    );
  }
}
