import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory, useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";

import { downloaderBridge, DownloadErrorCode } from "../../../services";
import { bridgeError } from "../../../store/reducers/app.reducer";
import {
  DownloadStateProvider,
  useDownloadState,
} from "../DownloadStateProvider";
import { DownloadBuildsPage } from "./DownloadBuilds";
import { DownloadProgressPage } from "./DownloadProgress";
import { DownloadCompletePage } from "./DownloadComplete";
import { LoadingPage } from "../../common";
import { usePrepareDownload } from "./hooks";
import { selectAppIsLoading } from "../../../store/selectors/app.selectors";

export const ModDownloadPage: React.FC = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { resetDownloadState } = useDownloadState();
  const isAppLoading = useSelector(selectAppIsLoading);

  usePrepareDownload();

  React.useEffect(() => {
    downloaderBridge.registerDownloadCompleteCallback(
      (artifacts, errorParams) => {
        const { errormessages = [], errorcode, supporturl } = errorParams;

        if (errormessages.length) {
          resetDownloadState();
          dispatch(bridgeError({ errormessages, errorcode, supporturl }));
        }

        if (errorcode === DownloadErrorCode.OK) {
          return history.push("/download/mod/complete");
        }

        if (errorcode === DownloadErrorCode.CanceledByUser) {
          resetDownloadState();
          return history.push("/download");
        }
      }
    );
  }, [dispatch, history, resetDownloadState]);

  if (isAppLoading) {
    return <LoadingPage description={t("common.data_loading_in_progress")} />;
  }

  return (
    <DownloadStateProvider>
      <Switch>
        <Route exact path={path}>
          <DownloadBuildsPage />
        </Route>
        <Route path={`${path}/progress`}>
          <DownloadProgressPage />
        </Route>
        <Route path={`${path}/complete`}>
          <DownloadCompletePage />
        </Route>
      </Switch>
    </DownloadStateProvider>
  );
};
