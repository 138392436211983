import React from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";

import { Button } from "../../../components/epic-ui-components";
import { Title, SafeContainer, PageWrapper } from "../../../components";
import { Page } from "../Page";
import {
  selectAppError,
  selectAppMode,
  selectEntityType,
} from "../../../store/selectors/app.selectors";
import {
  AppError,
  clearError,
  closeApp,
  openSupportPage,
} from "../../../store/reducers/app.reducer";
import { selectEntityTitle } from "../../../store/selectors/entity.selectors";
import { ErrorType, ErrorCode } from "../../../models/error";
import { AppMode } from "../../../models";
import { isModEntity as checkIsModEntity } from "../../../utils/app.utils";
import {
  BridgeErrorMap,
  ServerErrorMap,
  ErrorField,
  AppModeErrorPrefix,
  ClientErrorMap,
} from "./types";
import { ErrorReasonMap, ReasonErrorView } from "./ReasonErrorView";

const useStyles = makeStyles((theme) => ({
  errorHeader: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(18),
    whiteSpace: "pre-line",
    color: theme.palette.error.main,
  },
  link: {
    textAlign: "center",
    cursor: "pointer",
  },
  message: {
    color: theme.palette.grey[600],
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

const ERRORS_TO_SHOW_RETRY: ErrorCode[] = [
  "UP-03-001",
  "UP-03-002",
  "UP-04-003",
  "UP-05-001",
  "UP-05-002",
  500,
  "DL-03-001",
  "DL-03-002",
];

const isBridgeError = ({ type }: AppError) => type === ErrorType.BRIDGE;

const getServerErrorData = (code: ErrorCode, field: string) => {
  return ServerErrorMap[code] && ServerErrorMap[code][field];
};

const getClientErrorData = (code: ErrorCode, field: string) => {
  return ClientErrorMap[code] && ClientErrorMap[code][field];
};

const getServerErrorMessage = (code: ErrorCode) =>
  getServerErrorData(code, ErrorField.MESSAGE);

const getClientErrorMessage = (code: ErrorCode) =>
  getClientErrorData(code, ErrorField.MESSAGE);

const getErrorMessage = (type: ErrorType | "", code: ErrorCode) => {
  if (type === ErrorType.SERVER) {
    return getServerErrorMessage(code);
  }

  if (type === ErrorType.CLIENT) {
    return getClientErrorMessage(code);
  }
};

const getErrorCode = (
  type: ErrorType | "",
  code: ErrorCode,
  appMode: AppMode
) => {
  const appModeErrorPrefix = AppModeErrorPrefix[appMode];

  if (type === ErrorType.SERVER) {
    const serverErrorData = getServerErrorData(code, ErrorField.CODE) || code;
    return `${appModeErrorPrefix}-${serverErrorData}`;
  }

  if (type === ErrorType.CLIENT) {
    const clientErrorData = getClientErrorData(code, ErrorField.CODE) || code;
    return `${appModeErrorPrefix}-${clientErrorData}`;
  }
};

const isRetryAvailable = (code: ErrorCode) => {
  return ERRORS_TO_SHOW_RETRY.includes(code);
};

const getErrorHeader = ({ type, errorCode }: AppError) => {
  const defaultErrorHeader = "common.error.something_went_wrong";

  if (type === ErrorType.SERVER) {
    return (
      (ServerErrorMap[errorCode] &&
        ServerErrorMap[errorCode][ErrorField.HEADER]) ||
      defaultErrorHeader
    );
  }

  if (type === ErrorType.CLIENT) {
    return (
      (ClientErrorMap[errorCode] &&
        ClientErrorMap[errorCode][ErrorField.HEADER]) ||
      defaultErrorHeader
    );
  }

  if (type === ErrorType.BRIDGE) {
    return BridgeErrorMap[errorCode] || defaultErrorHeader;
  }

  return defaultErrorHeader;
};

export const ErrorBoundary: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const error = useSelector(selectAppError);
  const { supportUrl, errorCode, errorMessages } = error;
  const title = useSelector(selectEntityTitle);
  const appMode = useSelector(selectAppMode);
  const entityType = useSelector(selectEntityType);

  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  if (!errorCode) {
    return <>{children}</>;
  }

  const hasReason = !!ErrorReasonMap[error.reason || ""];

  if (hasReason) {
    return <ReasonErrorView />;
  }

  if (!appMode) return null;

  const onRetry = () => {
    dispatch(clearError());
    history.push("/");
  };

  const handleCloseApp = () => {
    dispatch(closeApp());
  };

  const isModEntity = checkIsModEntity(entityType);
  const serverErrorSupportUrl = isModEntity
    ? (process.env.REACT_APP_EPIC_MOD_UPLOAD_ERROR_URL as string)
    : "";

  const handleOpenSupportPage = () => {
    const supportPageUrl = isBridgeError(error)
      ? supportUrl
      : serverErrorSupportUrl;
    dispatch(openSupportPage(supportPageUrl));
  };

  const showRetry = isRetryAvailable(errorCode);

  const errorHeader = getErrorHeader(error);

  return (
    <PageWrapper>
      <Page
        header={
          <>
            {title && (
              <Box mb={6}>
                <Title>{title}</Title>
              </Box>
            )}
            {errorHeader && (
              <Typography variant="h2" className={classes.errorHeader}>
                {t(errorHeader)}
              </Typography>
            )}
          </>
        }
        footer={
          <Box display="flex" justifyContent="center">
            <Box width="100%">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleCloseApp}
              >
                {t("common.cancel")}
              </Button>
            </Box>
            {showRetry && (
              <Box ml={4} width="100%">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={onRetry}
                >
                  {t("common.retry")}
                </Button>
              </Box>
            )}
          </Box>
        }
      >
        <SafeContainer
          height={title ? 290 : 360}
          justify="space-between"
          paddingY="0"
        >
          <Box m={6}>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.message}
            >
              <Trans
                i18nKey={"common.error.error_code"}
                values={{
                  errorCode: isBridgeError(error)
                    ? errorCode
                    : getErrorCode(error.type, errorCode, appMode),
                }}
                components={{
                  error:
                    isBridgeError(error) || isModEntity ? (
                      <Typography
                        variant="body1"
                        color="primary"
                        component="span"
                        className={classes.link}
                        onClick={handleOpenSupportPage}
                      />
                    ) : (
                      <span />
                    ),
                }}
              />
            </Typography>
            {isBridgeError(error) ? (
              errorMessages.map((error: string) => (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.message}
                  key={error}
                >
                  {error}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.message}
              >
                {t(getErrorMessage(error.type, errorCode))}
              </Typography>
            )}
          </Box>
        </SafeContainer>
      </Page>
    </PageWrapper>
  );
};
