import * as React from "react";
import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router";
import { Box } from "@material-ui/core";

import {
  ActivateBinaryPage,
  BinarySelectFoldersPage,
  CloseConfirmationPage,
  FilesAdditionalInformationPage,
  ProgressUploadPage,
  ErrorPage,
  ActivateBinarySuccessPage,
} from "./pages";
import {
  uploaderBridge,
  UploadErrorCode,
  UploadParams,
} from "../../../services";
import { useUploadState } from "../../UploadStateProvider";
import { RemovePlatformsNotificationPage } from "./pages/RemovePlatformsNotificationPage";
import { ErrorReferrerPage } from "./types";
import { useBinaryUploadData } from "./data";

export const BinaryUpload: React.FC = () => {
  const { path } = useRouteMatch();
  const { resetProgress } = useUploadState();
  const history = useHistory();
  const {
    shouldConfirmationPageBeShown,
    setShouldConfirmationPageBeShown,
  } = useBinaryUploadData();

  const foldersUrl = `${path}/folders`;
  const fileUploadUrl = `${path}/file-upload`;
  const errorUrl = `${path}/error`;

  const startBinaryUpload = (uploadParams: UploadParams) => {
    uploaderBridge.upload(uploadParams);
    setShouldConfirmationPageBeShown(false);
    history.push(fileUploadUrl);
  };

  React.useEffect(() => {
    uploaderBridge.registerUploadCompleteCallback((params) => {
      const {
        errorcode,
        errormessages = [],
        supporturl,
        buildversion: buildVersion,
      } = params;

      if (errorcode === UploadErrorCode.OK) {
        return history.push(`${path}/activate-binary`, { buildVersion });
      }

      if (errorcode === UploadErrorCode.CanceledByUser) {
        resetProgress();

        return history.push(foldersUrl);
      }

      if (errormessages.length) {
        resetProgress();

        return history.push(errorUrl, {
          errorcode,
          errormessages,
          supporturl,
          referrerPage: ErrorReferrerPage.UPLOAD_BINARIES,
        });
      }
    });

    return () => {
      setShouldConfirmationPageBeShown(false);
    };
  }, [
    history,
    foldersUrl,
    errorUrl,
    resetProgress,
    path,
    setShouldConfirmationPageBeShown,
  ]);

  React.useEffect(() => {
    uploaderBridge.registerPreExitCallback(() => {
      if (shouldConfirmationPageBeShown) {
        uploaderBridge.preventFromExit(true);
        setShouldConfirmationPageBeShown(false);
        history.push("/upload/game/close");
      } else {
        uploaderBridge.preventFromExit(false);
      }
    });
  }, [shouldConfirmationPageBeShown]);

  return (
    <Box width="100%" height="100vh" py={12}>
      <Switch>
        <Route exact path={foldersUrl}>
          <BinarySelectFoldersPage />
        </Route>
        <Route path={`${path}/additional-information`}>
          <FilesAdditionalInformationPage
            startBinaryUpload={startBinaryUpload}
          />
        </Route>
        <Route path={fileUploadUrl}>
          <ProgressUploadPage />
        </Route>
        <Route path={errorUrl}>
          <ErrorPage startBinaryUpload={startBinaryUpload} />
        </Route>
        <Route path={`${path}/activate-binary`}>
          <ActivateBinaryPage />
        </Route>
        <Route path={`${path}/notification-remove-platforms`}>
          <RemovePlatformsNotificationPage />
        </Route>
        <Route path={`${path}/activate-binary-success`}>
          <ActivateBinarySuccessPage />
        </Route>
        <Route path={`${path}/close`}>
          <CloseConfirmationPage />
        </Route>
        <Redirect to={foldersUrl} />
      </Switch>
    </Box>
  );
};
