import { Mod, ModModel, ModStatus } from "@epic-mod-market/core";

const iconParams = [
  {
    name: "published",
    color: "#037AEE",
    checker: ModModel.isPublished,
  },
  {
    name: "pendingapproval",
    color: "#F6B735",
    checker: ModModel.isPendingApproval,
  },
  {
    name: "changesneeded",
    color: "#FFA640",
    checker: ModModel.isChangesNeeded,
  },
  {
    name: "draft",
    color: "#A1A1A1",
    checker: ModModel.isDraft,
  },
];

interface IconParams {
  name: string;
  color: string;
}

export const getModStatusIconParams = (mod: Mod): IconParams | undefined => {
  return iconParams.find(({ checker }) => checker(mod));
};

interface SortedModsAccumulator {
  [ModStatus.DRAFT]: Mod[];
  [ModStatus.CHANGES_NEEDED]: Mod[];
  [ModStatus.SUBMITTED]: Mod[];
  [ModStatus.PUBLISHED]: Mod[];
}

export const sortModsByStatus = (mods: Mod[]): Mod[] => {
  const sortedModsByStatus = mods.reduce(
    (acc: SortedModsAccumulator, mod: Mod) => {
      if (ModModel.isDraft(mod)) {
        acc[ModStatus.DRAFT].push(mod);
        return acc;
      }
      if (ModModel.isChangesNeeded(mod)) {
        acc[ModStatus.CHANGES_NEEDED].push(mod);
        return acc;
      }
      if (ModModel.isPendingApproval(mod)) {
        acc[ModStatus.SUBMITTED].push(mod);
        return acc;
      }
      if (ModModel.isPublished(mod)) {
        acc[ModStatus.PUBLISHED].push(mod);
        return acc;
      }
      return acc;
    },
    {
      [ModStatus.DRAFT]: [],
      [ModStatus.CHANGES_NEEDED]: [],
      [ModStatus.SUBMITTED]: [],
      [ModStatus.PUBLISHED]: [],
    }
  );

  return [
    ...sortedModsByStatus[ModStatus.DRAFT],
    ...sortedModsByStatus[ModStatus.CHANGES_NEEDED],
    ...sortedModsByStatus[ModStatus.SUBMITTED],
    ...sortedModsByStatus[ModStatus.PUBLISHED],
  ];
};
