import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Mod } from "@epic-mod-market/core";

import { fetchModForModerator } from "../../../../store/reducers/mod.reducer";
import {
  fetchArtifacts,
  fetchDownloadData,
} from "../../../../store/reducers/download.reducer";
import { selectOrganizationId } from "../../../../store/selectors/app.selectors";
import {
  selectModItem,
  selectModSlug,
} from "../../../../store/selectors/mod.selectors";
import { selectProductId } from "../../../../store/selectors/product.selectors";

export const usePrepareDownload = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(selectOrganizationId);
  const productId = useSelector(selectProductId);
  const modSlug = useSelector(selectModSlug);
  const mod = useSelector(selectModItem);

  const { privateNamespace, artifactId } = mod || ({} as Mod);

  React.useEffect(() => {
    if (modSlug) {
      dispatch(fetchModForModerator({ productId, modSlug }));
      dispatch(fetchDownloadData());
    }
  }, [dispatch, modSlug, productId, organizationId]);

  React.useEffect(() => {
    if (!mod) {
      return;
    }

    dispatch(
      fetchArtifacts({
        organizationId,
        productId,
        sandboxId: privateNamespace,
        artifactId,
      })
    );
  }, [organizationId, productId, privateNamespace, artifactId]);
};
