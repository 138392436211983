import * as React from "react";
import { Formik } from "formik";

import { FilesAdditionalInformationForm } from "./FilesAdditionalInformationForm";
import { useBinaryUploadData } from "../../data";
import { UploadParams } from "../../../../../services";

export interface FilesAdditionalInformationValue {
  launchArguments: string;
  attributesList: string;
  ignoreList: string;
}

interface FilesAdditionalInformationPageProps {
  startBinaryUpload: (uploadParams: UploadParams) => void;
}

export const FilesAdditionalInformationPage: React.FC<FilesAdditionalInformationPageProps> = ({
  startBinaryUpload,
}) => {
  const { additionalInformation, uploadParams } = useBinaryUploadData();

  const initialValues = React.useMemo(() => {
    return additionalInformation;
  }, [additionalInformation]);

  const handleSubmit = () => {
    startBinaryUpload(uploadParams);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <FilesAdditionalInformationForm />
    </Formik>
  );
};
