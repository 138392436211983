import * as React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const iconSizes = {
  xs: "0.75em",
  s: "0.875em",
  m: "1em",
  l: "1.25em",
  xl: "2em",
};

interface IconWrapperProps extends SpaceProps {
  size?: keyof typeof iconSizes;
  forwardedRef?: React.Ref<any>;
  className?: string;
  style?: React.CSSProperties;
  color?: string;
}

export interface IconProps extends IconWrapperProps {
  title?: string;
}

const getSize = (p) => iconSizes[p.size] || iconSizes.m;

export const SvgWrapper = styled.svg`
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  fill-rule: evenodd;

  fill: currentColor;
  stroke: none;
  .stroke {
    fill: none;
    stroke: currentColor;
    stroke-width: 24;
  }
`;

export const IconContainer = styled.div`
  display: inline-block;
  height: ${getSize};
  width: ${getSize};
  font-size: ${getSize};

  /* Align icon a bit so it will be more centered. */
  vertical-align: -0.1em;

  /* Apply some styles so icon will look properly when 'as' prop is used. */
  cursor: ${(p) => Boolean(p.onClick) && "pointer"};
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  color: inherit;

  &&& {
    ${space};
    color: ${(p) => p.color || "inherit"};
  }
`;

export const IconWrapper: React.FC<IconWrapperProps> = ({
  children,
  forwardedRef,
  ...props
}) => (
  <IconContainer ref={forwardedRef} {...props}>
    <SvgWrapper xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      {children}
    </SvgWrapper>
  </IconContainer>
);
