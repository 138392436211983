import React from "react";
import { Box } from "@material-ui/core";

export const PageWrapper: React.FC = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    width="100vw"
    overflow="hidden"
    m={0}
    bgcolor="grey.900"
    color="text.primary"
    fontSize={18}
  >
    {children}
  </Box>
);
