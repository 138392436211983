import { UEBridgeName, createBridgeCaller } from "../BridgeApi";
import { UploaderBridge, UploadParams } from "../uploaderBridge";

const executeUploaderBridgeCall = createBridgeCaller(UEBridgeName.Upload, {
  log: () => {},
});

export const uploaderLauncher: UploaderBridge = {
  registerSignInCompleteCallback: (callback) =>
    executeUploaderBridgeCall<void>("registersignincompletecallback", [
      callback,
    ]),
  registerUploadCompleteCallback: (callback) =>
    executeUploaderBridgeCall<void>("registeruploadcompletecallback", [
      callback,
    ]),
  registerUploadStateChangeCallback: (callback) =>
    executeUploaderBridgeCall<void>("registeruploadstatechangecallback", [
      callback,
    ]),
  onBrowseButton: () =>
    executeUploaderBridgeCall<string>("askuserforuploaddirectory"),
  onCancel: () => executeUploaderBridgeCall<void>("oncancel"),
  onClose: () => executeUploaderBridgeCall<void>("onclose"),
  getModTitle: () => executeUploaderBridgeCall<string>("getmodtitle"),
  getSupportedPlatforms: () =>
    executeUploaderBridgeCall<string[]>("getsupportedplatforms"),
  setSupportedPlatforms: (platforms) =>
    executeUploaderBridgeCall<void>("setmodplatforms", [platforms]),
  getSignInState: () => executeUploaderBridgeCall<number>("getsigninstate"),
  getIsPlatformSelectionEnabled: () =>
    executeUploaderBridgeCall<boolean>("isplatformselectionenabled"),
  openSupportPage: (supportUrl) =>
    executeUploaderBridgeCall<void>("opensupportpage", [supportUrl]),
  upload: (params: UploadParams) =>
    executeUploaderBridgeCall<void>("upload", [params]),
  openFileDialog: (...params) =>
    executeUploaderBridgeCall<string[]>("openfiledialog", [...params]),
  openDirectoryDialog: (...params) =>
    executeUploaderBridgeCall<string>("opendirectorydialog", [...params]),
  registerPreExitCallback: (callback) =>
    executeUploaderBridgeCall<void>("registerpreexitcallback", [callback]),
  preventFromExit: (param) =>
    executeUploaderBridgeCall<void>("preventfromexit", [param]),
  generateCloudDirectory: () =>
    executeUploaderBridgeCall<string>("generateclouddirectory"),
};
