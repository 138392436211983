import root from "window-or-global";

export enum BridgeType {
  LAUNCHER = "LAUNCHER",
  WEB = "WEB",
}

export enum UEBridgeName {
  Upload = "moduploader",
  Download = "moddownloader",
}

interface Logger {
  log: (message?: any, ...optionalParams: any[]) => void;
}

const ueBridgeNames: UEBridgeName[] = [
  UEBridgeName.Upload,
  UEBridgeName.Download,
];

const getBridgesObject = () => root.ue;

export const resolveBridgeType = (): BridgeType => {
  const bridgesObject = getBridgesObject();

  return bridgesObject ? BridgeType.LAUNCHER : BridgeType.WEB;
};

export const resolveBridgeName = (): UEBridgeName => {
  const bridgeType = resolveBridgeType();
  const isWeb = bridgeType === BridgeType.WEB;

  if (isWeb) {
    // mock bridge name for WEB
    return UEBridgeName.Upload;
  }

  const bridgesObject = getBridgesObject();

  // C++ exposes separate bridges for upload / download apps,
  // single bridge is present in both cases
  const [bridgeName] = Object.keys(bridgesObject).filter((key) =>
    ueBridgeNames.includes(key as UEBridgeName)
  );

  return bridgeName as UEBridgeName;
};

export const executeBridgeCall = <T>(
  context: any,
  method: string,
  params: any[] = []
): Promise<T> => {
  if (!context || !context[method]) {
    console.error(`Method was not found ${method} on bridge`);
    return Promise.reject(`Method was not found ${method} on bridge`);
  }
  const promise = context[method](...params);
  return promise.then ? promise : Promise.resolve(promise);
};

const getContext = (ueBridgeName: UEBridgeName) =>
  (root.ue && root.ue[ueBridgeName]) || {};

export const createBridgeCaller = (
  ueBridgeName: UEBridgeName,
  logger: Logger
) => {
  return <T>(method: string, params: any[] = []): Promise<T> => {
    logger.log(method, { params });
    return executeBridgeCall<T>(getContext(ueBridgeName), method, params);
  };
};

export const contextHasBridgeFunction = (
  ueBridgeName: UEBridgeName,
  ueFunctionName: string
) => {
  const context = getContext(ueBridgeName);
  return !!(context && context[ueFunctionName]);
};
