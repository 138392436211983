import { Platform } from "@epic-mod-market/core";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { putOffer } from "../../../../services/api/offer";
import { selectAppParams } from "../../../../store/selectors/app.selectors";
import { useBinaryUploadData, useOffersData } from "../data";
import { ErrorReferrerPage, Offer, OfferType, TagName } from "../types";
import {
  getAudienceItemConnectedWithArtifact,
  getOfferForAudienceItemId,
  getTagsIdMap,
  isExecutableItemAvailable,
} from "../utils";

interface PlatformsList {
  platforms: Platform[];
}

export function useUpdateOfferPlatforms(): {
  updateOfferPlatforms: (selectedPlatforms: PlatformsList) => Promise<void>;
} {
  const history = useHistory();
  const appParams = useSelector(selectAppParams);
  const { sandboxid, artifactid } = appParams;

  const {
    binaries: { binariesLists },
  } = useBinaryUploadData();
  const { activeBinaries } = binariesLists;

  const { buildVersion } = useParams<{
    buildVersion: string;
  }>();

  const {
    offers,
    bulkItems,
    sandboxTags: { platforms },
  } = useOffersData();

  const tagsIdMap = getTagsIdMap(platforms);

  const getActiveBinariesPlatformsTags = () => {
    const tags: number[] = [];

    activeBinaries
      .filter((binary) => binary.buildVersion !== buildVersion)
      .forEach(({ labels }) => {
        labels.forEach(({ platform }) => {
          if ([Platform.Win32, Platform.Win64].includes(platform)) {
            tags.push(tagsIdMap[TagName.WINDOWS]);
          }
          if (platform === Platform.Mac) {
            tags.push(tagsIdMap[TagName.MAC_OS]);
          }
        });
      });

    return tags;
  };

  const getSelectedPlatformsTags = (selectedPlatforms: Platform[]) => {
    const tags: number[] = [];
    selectedPlatforms.forEach((platform: Platform) => {
      if ([Platform.Win32, Platform.Win64].includes(platform)) {
        tags.push(tagsIdMap[TagName.WINDOWS]);
      }
      if (platform === Platform.Mac) {
        tags.push(tagsIdMap[TagName.MAC_OS]);
      }
    });

    return tags;
  };

  const getIdsOfOffersWithoutExecutableItem = () => {
    return compact(
      offers
        .filter((item: Offer) => !isExecutableItemAvailable(item.offerType))
        .map((item: Offer) => item.id)
    );
  };

  const getTags = (offer: Offer, selectedPlatforms: PlatformsList) => {
    const tagsWithoutPlatforms = offer.tags
      ? offer.tags.filter(
          (tag) =>
            tag !== tagsIdMap[TagName.MAC_OS] &&
            tag !== tagsIdMap[TagName.WINDOWS]
        )
      : [];

    return uniq([
      ...tagsWithoutPlatforms,
      ...getSelectedPlatformsTags(selectedPlatforms.platforms),
      ...getActiveBinariesPlatformsTags(),
    ]);
  };

  const updateOfferPlatforms = React.useCallback(
    async (selectedPlatforms) => {
      const itemConnectedWithArtifact = getAudienceItemConnectedWithArtifact(
        bulkItems,
        artifactid!
      );
      const offer = getOfferForAudienceItemId(
        offers,
        itemConnectedWithArtifact?.id
      );

      if (!offer || !sandboxid) return;

      const tags = getTags(offer, selectedPlatforms);

      if (offer.tags && isEqual(tags.sort(), offer.tags.sort())) return;

      try {
        if (offer.offerType === OfferType.BASE_GAME) {
          const offerIds = getIdsOfOffersWithoutExecutableItem();
          await Promise.all(
            offerIds.map((id) => {
              const offerData = offers.find(
                (item: Offer) => item.id === id
              ) as Offer;
              return putOffer(sandboxid, id, {
                tags: getTags(offerData, selectedPlatforms),
                status: offerData.status,
              });
            })
          );
        }
        await putOffer(sandboxid, offer.id, {
          tags,
        });
      } catch (e) {
        history.push(`/upload/game/error`, {
          errorcode: e?.code,
          errormessages: e?.message,
          supporturl: "",
          referrerPage: ErrorReferrerPage.ACTIVATE_BINARY,
          buildVersion,
        });
      }
    },
    [bulkItems, artifactid, offers, sandboxid, history, buildVersion]
  );

  return { updateOfferPlatforms };
}
