import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Title,
  Description,
  SafeContainer,
  FooterButtons,
  FolderPath,
} from "../../../../components";
import { openDownloadFolder } from "../../../../store/reducers/download.reducer";
import { selectDownloadFolder } from "../../../../store/selectors/download.selectors";
import { closeApp } from "../../../../store/reducers/app.reducer";
import { selectModTitle } from "../../../../store/selectors/mod.selectors";
import { Page } from "../../../common";

export const DownloadCompletePage: React.FC = () => {
  const { t } = useTranslation();
  const downloadFolderPath = useSelector(selectDownloadFolder);
  const title = useSelector(selectModTitle);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeApp());
  };

  const handleConfirm = () => {
    dispatch(openDownloadFolder());
  };

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <FooterButtons
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirmLabel={t("download.open_folder")}
          closeLabel={t("download.complete_done")}
        />
      }
    >
      <SafeContainer>
        <Description mb={4}>{t("download.complete_title")}</Description>
        <FolderPath value={downloadFolderPath} />
      </SafeContainer>
    </Page>
  );
};
