import styled from "styled-components";
import { TextField as MuiTextField } from "@material-ui/core";

export const TextField = styled(MuiTextField)`
  & .MuiInputBase-root {
    font-size: ${(p) => p.theme.typography.caption.fontSize};
    border-radius: 4px;
  }
  & .MuiFilledInput-input {
    padding: 10px 12px;
  }
  & .MuiFilledInput-underline:before {
    display: none;
  }
`;
