import { BridgeType, resolveBridgeType } from "./BridgeApi";
import { uploaderLauncher } from "./launcher/uploader.launcher";
import { uploaderWeb } from "./web/uploader.web";
import { ErrorParams, SignInCompleteCallback } from "./common";
import { AppParams } from "./commonBridge";

// ok - upload success
// UP-UC-CANCEL - user canceled upload
export enum UploadErrorCode {
  OK = "ok",
  CanceledByUser = "UP-UC-CANCEL",
}

type UploadCompleteCallback = (
  params: {
    buildroot: string;
    title: string;
    buildversion: string;
  } & ErrorParams<UploadErrorCode>
) => void;

export type UploadStateChangeCallback = (params: { progress: number }) => void;

export type UploadParams = Omit<AppParams, "action">;

export interface UploaderBridge {
  registerSignInCompleteCallback: (
    signInCompleteCallback: SignInCompleteCallback<UploadErrorCode>
  ) => Promise<void>;
  registerUploadCompleteCallback: (
    uploadCompleteCallback: UploadCompleteCallback
  ) => Promise<void>;
  registerUploadStateChangeCallback: (
    stateChangeCallback: UploadStateChangeCallback
  ) => Promise<void>;
  onBrowseButton: () => Promise<string>;
  onCancel: () => Promise<void>;
  onClose: () => Promise<void>;
  getModTitle: () => Promise<string>;
  getSignInState: () => Promise<number>;
  getSupportedPlatforms: () => Promise<string[]>;
  setSupportedPlatforms: (platforms: string[]) => Promise<void>;
  getIsPlatformSelectionEnabled: () => Promise<boolean>;
  openSupportPage: (supportUrl: string) => Promise<void>;
  upload: (params: UploadParams) => Promise<void>;
  openFileDialog: (
    dialogTitle?: string,
    defaultPath?: string,
    defaultFile?: string,
    fileTypes?: string,
    allowMultipleSelect?: boolean
  ) => Promise<string[]>;
  openDirectoryDialog: (
    dialogTitle?: string,
    defaultPath?: string
  ) => Promise<string>;
  registerPreExitCallback: (preExitCallback: () => void) => Promise<void>;
  preventFromExit: (param) => Promise<void>;
  generateCloudDirectory: () => Promise<string>;
}

const bridges = {
  [BridgeType.LAUNCHER]: uploaderLauncher,
  [BridgeType.WEB]: uploaderWeb,
};

export const uploaderBridge = bridges[resolveBridgeType()];
