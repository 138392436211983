import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

import { binaryClient } from "../binary";
import { commonBridge } from "../bridges";
import { offerClient } from "../offer";
import { getCsrfToken } from "./csrf/csrf.api";
import { commonClient, modClient, gameClient } from "./index";

const tokenInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  try {
    const accessToken = await commonBridge.getAccessToken();

    config.headers["Authorization"] = `Bearer ${accessToken}`;
  } catch (e) {
    console.error("Missing token");

    throw e;
  }

  return config;
};

export const configureApiClient = (
  apiClient: AxiosInstance,
  csrfToken: string
) => {
  apiClient.interceptors.request.use(tokenInterceptor);
  apiClient.defaults.withCredentials = true;

  apiClient.defaults.headers.post["x-csrf-token"] = csrfToken;
  apiClient.defaults.headers.put["x-csrf-token"] = csrfToken;
  apiClient.defaults.headers.delete["x-csrf-token"] = csrfToken;
  apiClient.defaults.headers.patch["x-csrf-token"] = csrfToken;
};

const apiClients = [
  commonClient,
  modClient,
  gameClient,
  binaryClient,
  offerClient,
];

export const configureAxios = async () => {
  axios.interceptors.request.use(tokenInterceptor);

  const csrfToken = await getCsrfToken();

  apiClients.forEach((apiClient) => configureApiClient(apiClient, csrfToken));
};
