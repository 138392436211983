import { UEBridgeName, createBridgeCaller } from "../BridgeApi";
import {
  DownloadBridge,
  DownloadParams,
  DownloadProgressState,
} from "../downloaderBridge";

const executeDownloadBridgeCall = createBridgeCaller(UEBridgeName.Download, {
  log: () => {},
});

export const downloadLauncher: DownloadBridge = {
  registerSignInCompleteCallback: (callback) =>
    executeDownloadBridgeCall<void>("registersignincompletecallback", [
      callback,
    ]),
  registerDownloadCompleteCallback: (callback) =>
    executeDownloadBridgeCall<void>("registerdownloadcompletecallback", [
      callback,
    ]),
  onCancel: () => executeDownloadBridgeCall<void>("canceldownload"),
  onPause: () => executeDownloadBridgeCall<void>("pausedownload"),
  onResume: () => executeDownloadBridgeCall<void>("resumedownload"),
  onClose: () => executeDownloadBridgeCall<void>("onclose"),
  onOpenDownloadFolder: () =>
    executeDownloadBridgeCall<void>("opendownloadfolder"),
  onChangeDownloadFolder: () =>
    executeDownloadBridgeCall<string>("askuserfordownloadfolder"),
  openSupportPage: (supportUrl) =>
    executeDownloadBridgeCall<void>("opensupportpage", [supportUrl]),
  getDownloadFolder: () =>
    executeDownloadBridgeCall<string>("getdownloadfolder"),
  getDownloadProgressState: () =>
    executeDownloadBridgeCall<DownloadProgressState>(
      "getdownloadprogressstate"
    ),
  download: (params: DownloadParams) =>
    executeDownloadBridgeCall<void>("download", [params]),
};
