import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Check } from "@epic-ui/icons";

import { Button } from "../../../../../components/epic-ui-components";
import {
  Description,
  Title,
  SafeContainer,
  FolderPath,
} from "../../../../../components";
import { Page } from "../../../../common";
import { selectUploadFolder } from "../../../../../store/selectors/upload.selectors";
import { selectEntityTitle } from "../../../../../store/selectors/entity.selectors";
import { closeApp } from "../../../../../store/reducers/app.reducer";

export const UploadCompletePage: React.FC = () => {
  const { t } = useTranslation();
  const title = useSelector(selectEntityTitle);
  const uploadFolderPath = useSelector(selectUploadFolder);
  const dispatch = useDispatch();

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(closeApp())}
          endIcon={<Check />}
          fullWidth
        >
          {t("common.done")}
        </Button>
      }
    >
      <SafeContainer>
        <Description mb={6}>{t("upload.complete")}</Description>
        <FolderPath value={uploadFolderPath} />
      </SafeContainer>
    </Page>
  );
};
