import * as React from "react";

import { IconProps, IconWrapper } from "../IconWrapper";

export const PendingApprovalIcon: React.FC<IconProps> = ({
  title,
  ...props
}) => (
  <IconWrapper {...props}>
    <title>{title}</title>
    <path
      d="M133.29 121.863l-25.21-25.21V44.848a8.079 8.079 0 10-16.159 0V100c0 2.143.852 4.198 2.367 5.713l27.576 27.576a8.053 8.053 0 005.713 2.366 8.08 8.08 0 005.713-13.792zM100 0c26.71 0 51.823 10.402 70.71 29.29C189.599 48.176 200 73.29 200 100c0 26.71-10.402 51.823-29.29 70.71-18.887 18.888-44 29.29-70.71 29.29-26.71 0-51.823-10.402-70.71-29.29C10.401 151.824 0 126.71 0 100c0-26.71 10.402-51.823 29.29-70.71C48.176 10.401 73.29 0 100 0z"
      fillRule="nonzero"
    />
  </IconWrapper>
);
