import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

import { Description } from "../../components";

interface LoadingPageProps {
  description?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({ description }) => {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <div>
        {description && <Description>{description}</Description>}
        <Box width="100%" mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      </div>
    </Box>
  );
};
