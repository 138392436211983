import React from "react";
import { Box, makeStyles } from "@material-ui/core";

interface Props {
  header: React.ReactNode;
  footer: React.ReactNode;
}

const MAIN_CONTENT_OFFSET_HEIGHT = 200; // header + footer + spacing

const useClasses = makeStyles({
  mainContent: {
    height: `calc(100vh - ${MAIN_CONTENT_OFFSET_HEIGHT}px)`,
  },
});

export const Page: React.FC<Props> = ({ header, children, footer }) => {
  const classes = useClasses();

  return (
    <Box
      width="100%"
      height="100vh"
      maxHeight="100vh"
      py={6}
      px={8}
      textAlign="center"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box component="header" width="100%">
        {header}
      </Box>
      <Box width="100%" maxWidth={450} my={6} className={classes.mainContent}>
        {children}
      </Box>
      <Box width="100%" maxWidth={450}>
        <footer>{footer}</footer>
      </Box>
    </Box>
  );
};
