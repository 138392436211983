import { createSelector } from "@reduxjs/toolkit";
import { Mod, ModRestrictions } from "@epic-mod-market/core";

import { sortModsByStatus } from "../../utils/mod.utils";
import { RootState } from "../reducers";
import { ModState } from "../reducers/mod.reducer";
import { selectAppParams } from "./app.selectors";

const selectModSlice = (state: RootState): ModState => state.mod;

const selectItems = createSelector(
  selectModSlice,
  (mod): Record<string, Mod> => mod.mods.items
);

export const selectModSlug = createSelector(
  selectAppParams,
  (params): string | undefined => params.entityid
);

export const selectModItem = createSelector(
  selectItems,
  selectModSlug,
  (items, modSlug): Mod | undefined => {
    if (!modSlug) {
      return undefined;
    }

    return items[modSlug];
  }
);

export const selectModTitle = createSelector(
  selectModItem,
  (mod): string => mod?.title || ""
);

export const selectRestrictions = createSelector(
  selectModSlice,
  ({ restrictions }): ModRestrictions => restrictions.data
);

export const selectModItems = createSelector(selectItems, (items): Mod[] => {
  return sortModsByStatus(Object.values(items));
});
