import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModErrorReason } from "@epic-mod-market/core";
import { transformProductSlug } from "@epic-mod-market/ui";
import { Box, Button, Link, makeStyles, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";

import { ErrorField } from "./types";
import { uploaderBridge } from "../../../services";
import { selectAppError } from "../../../store/selectors/app.selectors";
import { AppError, closeApp } from "../../../store/reducers/app.reducer";
import { Page } from "../Page";

export const ErrorReasonMap: Record<
  string,
  Record<ErrorField, (error: AppError) => React.ReactNode>
> = {
  [ModErrorReason.MODS_CREATION_DISALLOWED]: {
    [ErrorField.HEADER]: ({ productName }) => {
      return i18n.t("common.error.cant_upload_builds", { productName });
    },
    [ErrorField.MESSAGE]: ({ productName, productSlug }) => {
      const transformedSlug = transformProductSlug(productSlug);
      const storeProductURL = `${process.env.REACT_APP_EPIC_STORE_URL}/p/${transformedSlug}`;

      const handleOpenStore = () => {
        uploaderBridge.openSupportPage(storeProductURL);
      };

      return (
        <Trans
          i18nKey="common.error.product_not_purchased"
          values={{ productName }}
          components={{
            a: (
              <Link
                onClick={handleOpenStore}
                href="#"
                underline="none"
                color="primary"
              />
            ),
          }}
        />
      );
    },
    [ErrorField.CODE]: () => "",
  },
  [ModErrorReason.MODS_NOT_ENABLED]: {
    [ErrorField.HEADER]: ({ productName }) => {
      return i18n.t("common.error.cant_upload_builds", { productName });
    },
    [ErrorField.MESSAGE]: ({ productName }) => {
      return i18n.t("common.error.mods_not_supported", { productName });
    },
    [ErrorField.CODE]: () => "",
  },
  [ModErrorReason.MODS_ARE_SUSPENDED]: {
    [ErrorField.HEADER]: ({ productName }) => {
      return i18n.t("common.error.cant_upload_builds", { productName });
    },
    [ErrorField.MESSAGE]: ({ productName }) => {
      return i18n.t("common.error.mods_suspended", { productName });
    },
    [ErrorField.CODE]: () => "",
  },
};

const getErrorHeader = (error: AppError) =>
  ErrorReasonMap[error.reason!][ErrorField.HEADER](error);
const getErrorDescription = (error: AppError) =>
  ErrorReasonMap[error.reason!][ErrorField.MESSAGE](error);

const useClasses = makeStyles(() => ({
  root: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
}));

export const ReasonErrorView: React.FC = () => {
  const error = useSelector(selectAppError);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useClasses();

  const errorReason = ErrorReasonMap[error?.reason || ""];

  if (!errorReason) {
    return null;
  }

  const handleCloseApp = () => {
    dispatch(closeApp());
  };

  const errorHeader = getErrorHeader(error);
  const errorDescription = getErrorDescription(error);

  return (
    <Page
      header={
        <>
          {errorHeader && (
            <Box mb={4} className={classes.root}>
              <Typography variant="h2" color="textPrimary">
                {errorHeader}
              </Typography>
            </Box>
          )}
        </>
      }
      footer={
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleCloseApp}
        >
          {t("common.close")}
        </Button>
      }
    >
      <Box height={290} color="text.secondary">
        <Typography variant="h4">{errorDescription}</Typography>
      </Box>
    </Page>
  );
};
