import { AppParams } from "../services";
import { AppMode, EntityType } from "../models";

export const adaptAppParams = (appParams: AppParams): AppParams => {
  return {
    ...appParams,
    entitytype: appParams.entitytype?.toLowerCase() as EntityType,
    action: appParams.action?.toLowerCase() as AppMode,
    title: appParams.title ? appParams.title.replace(/_/g, " ") : "",
  };
};

export const adaptProgress = (progress: number): number => {
  if (progress === -1) {
    return 0;
  }

  return Math.round(progress * 100);
};

export const isModEntity = (entityType: EntityType | null) => {
  return entityType === EntityType.MOD;
};

export const isGameEntity = (entityType?: EntityType | null) => {
  return entityType === EntityType.GAME;
};
