import { createSelector } from "@reduxjs/toolkit";
import { SandboxModel } from "@epic-mod-market/core";
import { isGameEntity, isModEntity } from "../../utils/app.utils";

import {
  selectEntityType,
  selectTitle as selectTitleAppParam,
} from "./app.selectors";
import { selectModTitle } from "./mod.selectors";
import { selectGameTitle } from "./game.selectors";
import { selectProductSandboxId } from "./product.selectors";

export const selectEntityTitle = createSelector(
  selectProductSandboxId,
  selectEntityType,
  (state) => state,
  (productSandboxId, entityType, state): string | undefined => {
    if (isModEntity(entityType)) {
      return SandboxModel.isPrivateSandbox(productSandboxId)
        ? selectTitleAppParam(state)
        : selectModTitle(state);
    }
    if (isGameEntity(entityType)) return selectGameTitle(state);
  }
);
