import React from "react";

import { DownloadState, downloaderBridge } from "../../services";
import { adaptProgress } from "../../utils/app.utils";

export interface DownloadProgressState {
  state: DownloadState | undefined;
  downloadProgress: number;
  downloadRate: number;
  writeRate: number;
  readRate: number;
}

export interface DownloadStateManager {
  updateProgress: () => void;
  pauseDownload: () => void;
  resumeDownload: () => void;
  resetDownloadState: () => void;
  cancelDownload: () => void;
  downloadProgressState: DownloadProgressState;
}

const POLLING_INTERVAL = 100;

const defaultDownloadProgressState: DownloadProgressState = {
  state: undefined,
  downloadProgress: 0,
  downloadRate: 0,
  writeRate: 0,
  readRate: 0,
};

const DownloadStateContext = React.createContext<DownloadStateManager>({
  updateProgress: () => {},
  cancelDownload: () => {},
  resetDownloadState: () => {},
  pauseDownload: () => {},
  resumeDownload: () => {},
  downloadProgressState: defaultDownloadProgressState,
});

export const DownloadStateProvider: React.FC = ({ children }) => {
  const [downloadProgressState, setDownloadProgressState] = React.useState<
    DownloadProgressState
  >(defaultDownloadProgressState);
  let timerId: number | undefined;

  const stopUpdateProgress = () => {
    timerId && clearTimeout(timerId);
  };

  const updateProgress = () => {
    const pollProgress = async () => {
      stopUpdateProgress();

      timerId = setTimeout(async () => {
        const {
          state,
          downloadprogress,
          downloadrate,
          writerate,
          readrate,
        } = await downloaderBridge.getDownloadProgressState();

        setDownloadProgressState({
          state,
          downloadProgress: adaptProgress(downloadprogress),
          downloadRate: downloadrate,
          writeRate: writerate,
          readRate: readrate,
        });

        const isDownloadFinished =
          state === DownloadState.Completed ||
          state === DownloadState.Cancelled;

        if (isDownloadFinished) {
          stopUpdateProgress();
          return;
        }

        pollProgress();
      }, POLLING_INTERVAL);
    };

    pollProgress();
  };

  const resetDownloadState = () => {
    stopUpdateProgress();
    setDownloadProgressState(defaultDownloadProgressState);
  };

  const cancelDownload = () => {
    resetDownloadState();
    downloaderBridge.onCancel();
  };

  const pauseDownload = () => {
    downloaderBridge.onPause();
  };

  const resumeDownload = () => {
    downloaderBridge.onResume();
  };

  const context = {
    updateProgress,
    pauseDownload,
    resumeDownload,
    cancelDownload,
    resetDownloadState,
    downloadProgressState,
  };

  return (
    <DownloadStateContext.Provider value={context}>
      {children}
    </DownloadStateContext.Provider>
  );
};

export const useDownloadState = (): DownloadStateManager => {
  return React.useContext(DownloadStateContext);
};
