export enum EntityType {
  MOD = "mod",
  GAME = "game",
}

export enum AppMode {
  DOWNLOAD = "download",
  UPLOAD = "upload",
}

export enum SandboxPurposeType {
  FOR_MOD_MARKETPLACE = "FOR_MOD_MARKETPLACE",
}
