import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Box,
  makeStyles,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Mod, ModModel } from "@epic-mod-market/core";

import { Page } from "../../../common";
import {
  Title,
  SafeContainer,
  Description,
  Autocomplete,
} from "../../../../components";
import { selectModItems } from "../../../../store/selectors/mod.selectors";
import {
  fetchCreatedMods,
  updateModAndSelect,
  createModAndSelect,
  selectMod,
} from "../../../../store/reducers/mod.reducer";
import { closeApp } from "../../../../store/reducers/app.reducer";
import { Button } from "../../../../components/epic-ui-components";
import { ModStatusIcon } from "../../../../components/mods/ModStatusIcon";
import {
  selectEntityId,
  selectOrganizationId,
} from "../../../../store/selectors/app.selectors";
import {
  selectProductSandboxId,
  selectProductId,
} from "../../../../store/selectors/product.selectors";
import { useActionHandler } from "../../../hooks";
import { ModSelectionPageFooter } from "./ModSelectionPageFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      textAlign: "left",
    },
  },

  statusIcon: {
    "&&&": {
      padding: theme.spacing(0, 0, 3),
    },
  },

  createModButton: {
    marginTop: 20,
    fontSize: 14,
    textTransform: "uppercase",
  },

  box: {
    minHeight: 60,
  },
}));

export const ModSelectionPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isActionInProgress,
    handleAction,
    handleActionWithRedirect,
  } = useActionHandler();
  const mods = useSelector(selectModItems);
  const organizationId = useSelector(selectOrganizationId);
  const productId = useSelector(selectProductId);
  const productSandboxId = useSelector(selectProductSandboxId);
  const entityId = useSelector(selectEntityId);

  const classes = useStyles();
  const [selectedModSlug, setSelectedModSlug] = React.useState(entityId);
  const [isAutocompletePopupOpen, setIsAutocompletePopupOpen] = React.useState(
    false
  );

  const selectedMod = mods.find(({ modSlug }) => modSlug === selectedModSlug);

  React.useEffect(() => {
    if (organizationId) {
      handleAction(
        fetchCreatedMods({ organizationId, productId, productSandboxId })
      );
    }
  }, [dispatch, organizationId, productId, productSandboxId]);

  const handleCreateMod = () => {
    handleActionWithRedirect(
      createModAndSelect({ organizationId, productId, productSandboxId })
    );
  };

  const handleModSelection = () => {
    if (!selectedMod) {
      return;
    }

    if (ModModel.isDraft(selectedMod)) {
      handleActionWithRedirect(
        selectMod({
          modSlug: selectedModSlug,
          productId,
        })
      );
    }

    if (ModModel.isChangesNeeded(selectedMod)) {
      handleActionWithRedirect(
        updateModAndSelect({
          organizationId,
          productId,
          productSandboxId,
          modSlug: selectedModSlug,
        })
      );
    }
  };

  return (
    <Page
      header={<Title>{t("mod.choose_your_mod")}</Title>}
      footer={
        <ModSelectionPageFooter
          selectedMod={selectedMod}
          disabled={!selectedModSlug || isActionInProgress}
          isLoading={isActionInProgress}
          handleModSelection={handleModSelection}
          onClose={() => dispatch(closeApp())}
        />
      }
    >
      <SafeContainer>
        <Box mt={4} className={classes.root}>
          <Description mb={6}>{t("mod.upload_mod_name")}</Description>

          <Autocomplete
            id="mod-autocomplete"
            onOpen={() => setIsAutocompletePopupOpen(true)}
            onClose={() => setIsAutocompletePopupOpen(false)}
            loading={isActionInProgress}
            options={mods}
            defaultValue={selectedMod}
            onChange={(e, value) =>
              setSelectedModSlug((value as Mod)?.modSlug || "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Mod"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectedMod && !isAutocompletePopupOpen && (
                    <InputAdornment
                      variant="outlined"
                      position="start"
                      classes={{ root: classes.statusIcon }}
                    >
                      <ModStatusIcon mr={2} mod={selectedMod} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            renderOption={(mod) => (
              <Box width="100%" display="flex" alignItems="center">
                <ModStatusIcon mr={2} mod={mod} />
                <Typography noWrap>{mod.title}</Typography>
              </Box>
            )}
            getOptionSelected={(option, value) =>
              option.modSlug === value.modSlug
            }
            getOptionLabel={(mod) => mod.title}
          />

          <Box mt={3} className={classes.box}>
            <Description>
              {selectedMod &&
                ModModel.isPublished(selectedMod) &&
                t("mod.create_draft.description", {
                  title: selectedMod?.title,
                })}
              {selectedMod &&
                ModModel.isPendingApproval(selectedMod) &&
                t("mod.recall.description")}
            </Description>
          </Box>

          <Button
            variant="text"
            onClick={handleCreateMod}
            disabled={isActionInProgress}
            className={classes.createModButton}
          >
            {isActionInProgress ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              t("mod.create_new")
            )}
          </Button>
        </Box>
      </SafeContainer>
    </Page>
  );
};
