import { ModErrorReason, ModProduct } from "@epic-mod-market/core";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GlobalTypes } from "./common";
import { commonApi } from "../../services";
import { clientError, serverError } from "./app.reducer";

export interface ProductState {
  product?: ModProduct;
}

export const fetchProduct = createAsyncThunk(
  `${GlobalTypes.GetData}/product/fetchProduct`,
  async (
    params: {
      organizationId: string;
      productId: string;
      productSandboxId?: string;
    },
    { dispatch }
  ): Promise<ModProduct | never> => {
    try {
      const product = await commonApi.getProduct(params);

      const {
        isModMarketSuspended,
        isModCreationAllowed,
        isModMarketEnabled,
        productName,
        slug,
      } = product;

      if (!productName || !slug) {
        dispatch(clientError({ errorCode: 404 }));
        return product;
      }

      if (!isModMarketEnabled) {
        dispatch(
          clientError({
            errorCode: 403,
            reason: ModErrorReason.MODS_NOT_ENABLED,
            productName: product.productName,
            productSlug: product.slug,
          })
        );
        return product;
      }

      if (!isModCreationAllowed) {
        dispatch(
          clientError({
            errorCode: 403,
            reason: ModErrorReason.MODS_CREATION_DISALLOWED,
            productName: product.productName,
            productSlug: product.slug,
          })
        );
        return product;
      }

      if (isModMarketSuspended) {
        dispatch(
          clientError({
            errorCode: 403,
            reason: ModErrorReason.MODS_ARE_SUSPENDED,
            productName: product.productName,
            productSlug: product.slug,
          })
        );
        return product;
      }

      return product;
    } catch (err) {
      dispatch(serverError(err));
      throw err;
    }
  }
);

const initialState: ProductState = {
  product: undefined,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.product = action.payload;
    });
  },
});

export default productSlice.reducer;
