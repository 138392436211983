import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  FolderSelector,
  FooterButtons,
  SafeContainer,
  Title,
} from "../../../../components";
import { Page } from "../../../common";
import { BuildList } from "./components";
import { Build, useAdaptArtifacts } from "./hooks";
import {
  selectAvailableBuilds,
  selectDownloadFolder,
} from "../../../../store/selectors/download.selectors";
import { openDownloadFolderDialog } from "../../../../store/reducers/download.reducer";
import { closeApp } from "../../../../store/reducers/app.reducer";
import { startModDownload } from "../../../../store/reducers/mod.reducer";
import { selectModTitle } from "../../../../store/selectors/mod.selectors";
import { useActionHandler } from "../../../hooks";

const FolderSelectorLabel = styled(Typography)`
  && {
    margin-right: ${(p) => p.theme.spacing(2)}px;
  }

  white-space: nowrap;
`;

export const DownloadBuildsPage: React.FC = () => {
  const { t } = useTranslation();
  const availableBuilds = useSelector(selectAvailableBuilds);
  const downloadFolder = useSelector(selectDownloadFolder);
  const title = useSelector(selectModTitle);
  const dispatch = useDispatch();
  const { isActionInProgress, handleActionWithRedirect } = useActionHandler();

  const { builds, adaptBuildsToDownload } = useAdaptArtifacts(availableBuilds);
  const [selectedBuilds, setSelectedBuilds] = React.useState<Build[]>([]);

  const isBuildsLoaded = builds.length > 0;

  // select all builds by default
  React.useEffect(() => {
    setSelectedBuilds(builds);
  }, [isBuildsLoaded]);

  const isDownloadEnabled = selectedBuilds.length > 0;
  const isBuildSelected = (id: string): boolean =>
    !!selectedBuilds.find((build) => build.id === id);

  const handleSelectBuild = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      setSelectedBuilds([
        ...selectedBuilds,
        builds.find(({ id }) => id === target.value) as Build,
      ]);
      return;
    }

    setSelectedBuilds(selectedBuilds.filter(({ id }) => id !== target.value));
  };

  const handleDownloadBuilds = () => {
    const artifacts = adaptBuildsToDownload(selectedBuilds);

    handleActionWithRedirect(startModDownload(artifacts), "progress");
  };

  const handleChangeFolder = () => {
    dispatch(openDownloadFolderDialog());
  };

  return (
    <Page
      header={<Title>{title}</Title>}
      footer={
        <Box>
          <FooterButtons
            confirmLabel={t("download")}
            closeLabel={t("common.close")}
            onClose={() => dispatch(closeApp())}
            onConfirm={handleDownloadBuilds}
            disabled={!isDownloadEnabled}
            isLoading={isActionInProgress}
          />
        </Box>
      }
    >
      <SafeContainer>
        <Box mb={2}>
          <Typography variant="body1" color="textSecondary">
            {t("download.choose_builds_to_download")}
          </Typography>
        </Box>
        <BuildList
          builds={builds}
          isBuildSelected={isBuildSelected}
          handleSelectBuild={handleSelectBuild}
        />
        <Box mx={2}>
          <Box display="flex" alignItems="center">
            <FolderSelectorLabel variant="caption" color="textSecondary">
              {t("common.save_as")}
            </FolderSelectorLabel>
            <FolderSelector
              value={downloadFolder}
              onSelect={handleChangeFolder}
              inputProps={{ disabled: true }}
            />
          </Box>
        </Box>
      </SafeContainer>
    </Page>
  );
};
