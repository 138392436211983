import * as React from "react";
import { ArtifactItem, Platform } from "@epic-mod-market/core";

import { Downloadable } from "../../../../../services/api/bridges";

export interface Build {
  id: string;
  version: string;
  platform: Platform;
  platformLabel: string;
  created: string;
}

const platformToLabelsMap: Record<Platform, string> = {
  [Platform.Mac]: "Mac",
  [Platform.Win64]: "Win64",
  [Platform.Win32]: "Win32",
};

export interface ArtifactsAdaptor {
  builds: Build[];
  adaptBuildsToDownload: (builds: Build[]) => Downloadable[];
}

export const useAdaptArtifacts = (
  artifacts: ArtifactItem[]
): ArtifactsAdaptor => {
  const [adaptedBuilds, setAdaptedBuilds] = React.useState<Build[]>([]);

  React.useEffect(() => {
    if (!artifacts.length) {
      return;
    }

    let builds: Build[] = [];

    artifacts.forEach(({ buildVersion, labels, created }) => {
      labels.forEach((label) => {
        const id = `${buildVersion}-${label.platform}`;

        builds = [
          ...builds,
          {
            id,
            version: buildVersion,
            platform: label.platform,
            created,
            platformLabel: platformToLabelsMap[label.platform],
          },
        ];
      });
    });

    setAdaptedBuilds(builds);
  }, [artifacts]);

  const adaptBuildsToDownload = (builds: Build[]): Downloadable[] =>
    builds.reduce(
      (buildsToDownload: Downloadable[], { version, platform }: Build) => {
        const buildIndex = buildsToDownload.findIndex(
          (build) => build.version === version
        );

        if (buildIndex === -1) {
          buildsToDownload.push({
            version,
            platforms: [platform],
          });
        } else {
          const buildPlatforms = buildsToDownload[buildIndex].platforms;
          buildsToDownload[buildIndex].platforms = [
            ...buildPlatforms,
            platform,
          ];
        }

        return buildsToDownload;
      },
      []
    );

  return {
    builds: adaptedBuilds,
    adaptBuildsToDownload,
  };
};
