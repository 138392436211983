import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  selectAppMode,
  selectEntityType,
} from "../../store/selectors/app.selectors";

interface ActionHandlerParams {
  isActionInProgress: boolean;
  handleAction: (action: any) => void;
  handleActionWithRedirect: (action: any, to?: string) => void;
}

export const useActionHandler = (): ActionHandlerParams => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appMode = useSelector(selectAppMode);
  const entityType = useSelector(selectEntityType);
  const [isActionInProgress, setIsActionInProgress] = React.useState(false);

  const handleAction = React.useCallback(
    async (action: any): Promise<any> => {
      setIsActionInProgress(true);
      const result = await dispatch(action);
      setIsActionInProgress(false);

      return result;
    },
    [dispatch]
  );

  const handleActionWithRedirect = React.useCallback(
    (action: any, to: string = "") => {
      handleAction(action)
        .then(unwrapResult)
        .then(() => {
          history.push(`/${appMode}/${entityType}/${to}`);
        });
    },
    [appMode, entityType, handleAction, history]
  );

  return {
    isActionInProgress,
    handleAction,
    handleActionWithRedirect,
  };
};
