import { AppMode } from "../../../models";

export enum ErrorField {
  HEADER = "HEADER",
  CODE = "CODE",
  MESSAGE = "MESSAGE",
}

export const AppModeErrorPrefix = {
  [AppMode.DOWNLOAD]: "DL",
  [AppMode.UPLOAD]: "UP",
};
export interface ServerError {
  [ErrorField.CODE]?: string;
  [ErrorField.HEADER]: string;
  [ErrorField.MESSAGE]: string;
}

export interface ClientError extends ServerError {}

export const BridgeErrorMap: Record<string, string> = {
  "UP-01-001": "common.error.system_error",
  "UP-01-002": "upload.error.upload_failed",
  "UP-01-003": "upload.error.upload_failed",
  "UP-01-004": "upload.error.upload_failed",
  "UP-02-001": "common.error.network_error",
  "UP-03-001": "common.error.upload_error",
  "UP-03-002": "common.error.upload_error",
  "UP-04-001": "common.error.authorization_error",
  "UP-04-002": "common.error.authorization_error",
  "UP-04-003": "common.error.authorization_error",
  "UP-04-004": "common.error.authorization_error",
  "UP-04-005": "common.error.authorization_error",
  "UP-04-006": "common.error.authorization_error",
  "UP-05-001": "upload.error.upload_failed",
  "UP-05-002": "upload.error.upload_failed",
  "DL-01-001": "common.error.system_error",
  "DL-01-002": "download.error.download_failed",
  "DL-01-003": "download.error.download_failed",
  "DL-01-004": "download.error.download_failed",
  "DL-02-001": "common.error.network_error",
  "DL-03-001": "download.error.download_error",
  "DL-03-002": "download.error.download_error",
  "DL-04-001": "common.error.authorization_error",
  "UP-UC-CANCEL": "common.error.cancel_upload",
};

export const ServerErrorMap: Record<number, ServerError> = {
  401: {
    [ErrorField.HEADER]: "common.error.authorization_error",
    [ErrorField.CODE]: "04-401",
    [ErrorField.MESSAGE]: "common.error.unauthorized",
  },
  403: {
    [ErrorField.HEADER]: "common.error.system_error",
    [ErrorField.CODE]: "01-403",
    [ErrorField.MESSAGE]: "common.error.forbidden",
  },
  404: {
    [ErrorField.HEADER]: "common.error.system_error",
    [ErrorField.CODE]: "01-404",
    [ErrorField.MESSAGE]: "common.error.not_found",
  },
  500: {
    [ErrorField.HEADER]: "common.error.system_error",
    [ErrorField.CODE]: "01-500",
    [ErrorField.MESSAGE]: "common.error.server_error",
  },
};

export const ClientErrorMap: Record<string, ClientError> = {
  "03-003": {
    [ErrorField.HEADER]: "common.error.upload_error",
    [ErrorField.CODE]: "03-003",
    [ErrorField.MESSAGE]: "upload.error.wrong_mod_status",
  },
};
