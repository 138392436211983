import * as React from "react";
import { useSelector } from "react-redux";
import { Platform } from "@epic-mod-market/core";

import {
  BinaryUploadFoldersFormFields,
  FilesAdditionalInformationValue,
} from "../pages";
import { selectAppParams } from "../../../../store/selectors/app.selectors";
import { uploaderBridge, UploadParams } from "../../../../services";
import { UseBinariesLists, useBinariesLists } from "../hooks";
import { Status } from "../types";

interface BinaryUploadContextProps {
  folderPaths: BinaryUploadFoldersFormFields;
  additionalInformation: FilesAdditionalInformationValue;
  setAdditionalInformation: React.Dispatch<
    React.SetStateAction<FilesAdditionalInformationValue>
  >;
  setFolderPaths: React.Dispatch<
    React.SetStateAction<BinaryUploadFoldersFormFields>
  >;
  uploadParams: UploadParams;
  shouldConfirmationPageBeShown: boolean;
  setShouldConfirmationPageBeShown: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedPlatformsState: Platform[];
  setSelectedPlatformsState: React.Dispatch<React.SetStateAction<Platform[]>>;
  binaries: UseBinariesLists;
  generateCloudDirStatus: Status;
}

const MAX_ALLOWED_BINARY_SIZE_BYTES = 107_374_182_400;

export const BUILD_VERSION_CHARACTER_LIMITS = { max: 100, min: 1 };

export const initialFolderPathState = {
  buildVersion: "",
  appLaunch: "",
  localCacheFolder: "",
  binaryFolder: "",
};

export const initialAdditionalInformationState = {
  attributesList: "",
  ignoreList: "",
  launchArguments: "",
};

export const BinaryUploadContext = React.createContext<
  BinaryUploadContextProps
>({} as any);

export const BinaryUploadProvider: React.FC = ({ children }): JSX.Element => {
  const [folderPaths, setFolderPaths] = React.useState<
    BinaryUploadFoldersFormFields
  >(initialFolderPathState);

  const [additionalInformation, setAdditionalInformation] = React.useState<
    FilesAdditionalInformationValue
  >(initialAdditionalInformationState);

  const appParams = useSelector(selectAppParams);
  const { action, ...defaultUploadParams } = appParams;
  const [uploadParams, setUploadParams] = React.useState<UploadParams>({
    ...defaultUploadParams,
    maxbuildsize: MAX_ALLOWED_BINARY_SIZE_BYTES,
    supportedplatforms: [],
  });

  const [generateCloudDirStatus, setGenerateCloudDirStatus] = React.useState<
    Status
  >(Status.INACTIVE);

  const [
    shouldConfirmationPageBeShown,
    setShouldConfirmationPageBeShown,
  ] = React.useState<boolean>(false);

  const [selectedPlatformsState, setSelectedPlatformsState] = React.useState<
    Platform[]
  >([]);

  const binaries = useBinariesLists();

  const context: BinaryUploadContextProps = {
    folderPaths,
    setFolderPaths,
    additionalInformation,
    setAdditionalInformation,
    uploadParams,
    shouldConfirmationPageBeShown,
    setShouldConfirmationPageBeShown,
    selectedPlatformsState,
    setSelectedPlatformsState,
    binaries,
    generateCloudDirStatus,
  };

  React.useEffect(() => {
    setGenerateCloudDirStatus(Status.REQUEST);

    uploaderBridge
      .generateCloudDirectory()
      .then((cloudDir) => {
        setFolderPaths({
          ...folderPaths,
          localCacheFolder: cloudDir,
        });

        setGenerateCloudDirStatus(Status.SUCCESS);
      })
      .catch(() => setGenerateCloudDirStatus(Status.ERROR));
  }, []);

  React.useEffect(() => {
    const {
      binaryFolder,
      localCacheFolder,
      appLaunch,
      buildVersion,
    } = folderPaths;
    const {
      ignoreList,
      attributesList,
      launchArguments,
    } = additionalInformation;

    const parsedAppLaunch = appLaunch.slice(binaryFolder.length + 1);

    const newUploadParams = {
      ...uploadParams,
      buildversion: buildVersion,
      buildroot: binaryFolder,
      clouddir: localCacheFolder,
      applaunch: parsedAppLaunch,
      fileignorelist: ignoreList,
      fileattributelist: attributesList,
      appargs: launchArguments,
    };

    setUploadParams(newUploadParams);
  }, [folderPaths, additionalInformation]);

  return (
    <BinaryUploadContext.Provider value={context}>
      {children}
    </BinaryUploadContext.Provider>
  );
};

export const useBinaryUploadData = (): BinaryUploadContextProps => {
  const context = React.useContext(BinaryUploadContext);

  if (context === undefined) {
    throw new Error(
      "useBinaryUploadData must be used within an BinaryUploadContext"
    );
  }

  return context;
};
