import { Platform } from "@epic-mod-market/core";

import { EntityType } from "../../../models";
import { BridgeType, resolveBridgeType } from "./BridgeApi";
import { downloadLauncher } from "./launcher/download.launcher";
import { downloadWeb } from "./web/download.web";
import { ErrorParams, SignInCompleteCallback } from "./common";

// ok - download success
// MU-UC-CANCEL - user canceled download
export enum DownloadErrorCode {
  OK = "ok",
  CanceledByUser = "DL-UC-CANCEL",
}

export enum DownloadState {
  Started = "Started",
  Cancelled = "Canceled",
  Paused = "Paused",
  Completed = "Completed",
}

export interface DownloadProgressState {
  state: DownloadState | undefined;
  downloadprogress: number;
  downloadrate: number;
  readrate: number;
  writerate: number;
}

export interface Downloadable {
  version: string;
  platforms: Platform[];
}

export interface DownloadParams {
  entityid: string;
  entitytype: EntityType;
  signedtoken: string;
  productid: string;
  sandboxid: string;
  catalogitemid: string;
  artifactid: string;
  title: string;
  artifacts: Downloadable[];
  downloadfolder: string;
}

interface DownloadCompleteDataParams {
  artifacts: Downloadable[];
}

export type DownloadCompleteCallback = (
  params: DownloadCompleteDataParams,
  errorParams: ErrorParams<DownloadErrorCode>
) => void;

export interface DownloadBridge {
  registerSignInCompleteCallback: (
    signInCompleteCallback: SignInCompleteCallback<DownloadErrorCode>
  ) => Promise<void>;
  registerDownloadCompleteCallback: (
    downloadCompleteCallback: DownloadCompleteCallback
  ) => Promise<void>;
  onCancel: () => Promise<void>;
  onPause: () => Promise<void>;
  onResume: () => Promise<void>;
  onClose: () => Promise<void>;
  onOpenDownloadFolder: () => Promise<void>;
  onChangeDownloadFolder: () => Promise<string>;
  openSupportPage: (supportUrl: string) => Promise<void>;
  getDownloadFolder: () => Promise<string>;
  getDownloadProgressState: () => Promise<DownloadProgressState>;
  download: (params: DownloadParams) => Promise<void>;
}

const bridges = {
  [BridgeType.LAUNCHER]: downloadLauncher,
  [BridgeType.WEB]: downloadWeb,
};

export const downloaderBridge = bridges[resolveBridgeType()];
