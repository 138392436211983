import { Action, AnyAction } from "@reduxjs/toolkit";

export enum GlobalTypes {
  GetData = "global/getData",
}

interface ActionCreator<T extends string = string> {
  (...args: any[]): Action<T>;
  type: T;
}

export const isOneOf = <T extends ActionCreator>(actionCreators: T[]) => (
  action: AnyAction
): action is ReturnType<T> =>
  actionCreators.map(({ type }) => type).includes(action.type);

const isPending = (action: AnyAction) => action.type.endsWith("/pending");

const isFulfilled = (action: AnyAction) => action.type.endsWith("/fulfilled");

const isRejected = (action: AnyAction) => action.type.endsWith("/rejected");

const hasPrefix = (action: AnyAction, prefix: string) =>
  action.type.startsWith(prefix);

export const isPendingAction = <T extends ActionCreator>(prefix: string) => (
  action: AnyAction
): action is ReturnType<T> => hasPrefix(action, prefix) && isPending(action);

export const isRejectedAction = <T extends ActionCreator>(prefix: string) => (
  action: AnyAction
): action is ReturnType<T> => hasPrefix(action, prefix) && isRejected(action);

export const isFulfilledAction = <T extends ActionCreator>(prefix: string) => (
  action: AnyAction
): action is ReturnType<T> => hasPrefix(action, prefix) && isFulfilled(action);
