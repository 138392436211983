import * as React from "react";
import { Platform } from "@epic-mod-market/core";

import { BinariesLists, Binary, TakenPlatforms } from "../types";

const extractTakenPlatforms = (binary: Binary) =>
  binary.labels.reduce(
    (acc, label) => ({
      ...acc,
      [label.platform]: binary.buildVersion,
    }),
    {}
  );

const extractSelectedBinaryPlatforms = (binary: Binary) => {
  return binary.labels.map((label) => label.platform);
};

export const useArtifactBinariesPlatforms = (
  binariesLists: BinariesLists,
  currentBuild: string
): {
  binaryPlatforms: Platform[];
  takenPlatforms: TakenPlatforms;
  takenPlatformsExcludeCurrent: TakenPlatforms;
} => {
  const { activeBinaries } = binariesLists;

  const selectedBinary = activeBinaries.find(
    (binary) => binary.buildVersion === currentBuild
  );

  const binaryPlatforms = React.useMemo(() => {
    return selectedBinary ? extractSelectedBinaryPlatforms(selectedBinary) : [];
  }, [selectedBinary]);

  const takenPlatforms = React.useMemo(
    () =>
      activeBinaries.reduce(
        (platforms, binary) => ({
          ...platforms,
          ...extractTakenPlatforms(binary),
        }),
        {}
      ),
    [activeBinaries, currentBuild]
  );

  const takenPlatformsExcludeCurrent = Object.fromEntries(
    Object.entries(takenPlatforms).filter(
      ([platform]) => !binaryPlatforms.includes(platform as Platform)
    )
  );

  return {
    binaryPlatforms,
    takenPlatforms,
    takenPlatformsExcludeCurrent,
  };
};
