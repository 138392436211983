import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

// Opacity hack is used to avoid content flickering when label disappears
const LabelWrapper = styled(Box)<{ isVisible: boolean }>`
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  pointer-events: ${(p) => (p.isVisible ? "auto" : "none")};
`;

interface Props {
  isVisible: boolean;
}

export const MisconfiguredPlatformsLabel: React.FC<Props> = ({ isVisible }) => {
  const { t } = useTranslation();

  return (
    <LabelWrapper isVisible={isVisible} color="warning.light">
      <Typography variant="body1">
        {t("upload.warning.platforms.misconfigured")}
      </Typography>
    </LabelWrapper>
  );
};
