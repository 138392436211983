import React from "react";
import { Platform } from "@epic-mod-market/core";
import { useSelector } from "react-redux";
import { flatMap } from "lodash";

import { selectAppParams } from "../../../../../../store/selectors/app.selectors";
import { useBinaryUploadData } from "../../../data";

const checkIsPlatformAllowed = (
  alreadyAssignedPlatforms: Platform[],
  platform: Platform
): boolean => {
  if (platform === Platform.Mac) {
    return alreadyAssignedPlatforms.includes(platform);
  }

  const isWindows = [Platform.Win32, Platform.Win64].includes(platform);

  if (isWindows) {
    return (
      alreadyAssignedPlatforms.includes(Platform.Win64) ||
      alreadyAssignedPlatforms.includes(Platform.Win32)
    );
  }

  return false;
};

export const useIsPlatformDisabled = (platform: Platform): boolean => {
  const { bliveupdate } = useSelector(selectAppParams);
  const {
    binaries: {
      isListLoading,
      binariesLists: { activeBinaries },
    },
  } = useBinaryUploadData();

  return React.useMemo(() => {
    if (isListLoading) {
      return true;
    }

    if (!bliveupdate) {
      return false;
    }

    const platformsUsedInActiveBinaries = flatMap(activeBinaries, (binary) => {
      return binary.labels.map((label) => label.platform);
    });

    return !checkIsPlatformAllowed(platformsUsedInActiveBinaries, platform);
  }, [platform, isListLoading, activeBinaries, bliveupdate]);
};
