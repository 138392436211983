import * as React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useProgressStyles = makeStyles({
  root: {
    height: 50,
  },
});

interface ProgressProps {
  value: number;
  children: React.ReactNode;
}

export const Progress: React.FC<ProgressProps> = ({ value, children }) => {
  const progressClasses = useProgressStyles();

  return (
    <Box position="relative">
      <LinearProgress
        variant="determinate"
        value={value}
        classes={progressClasses}
      />
      <Box
        height="100%"
        width="100%"
        position="absolute"
        display="flex"
        alignItems="center"
        top={0}
        left={0}
      >
        {children}
      </Box>
    </Box>
  );
};
