import { InstallerBridge } from "../installerBridge";
import { InstallerBridgeMockService } from "../InstallerBridgeMockService";

const MockService = new InstallerBridgeMockService();

export const installerWeb: InstallerBridge = {
  setFileOperationView: MockService.setFileOperationView,
  subscribe: MockService.subscribe,
  unsubscribe: MockService.unsubscribe,
};
