import axios, { CancelToken } from "axios";
import {
  Offer,
  UpdateOfferBody,
  BulkItems,
  SandboxTag,
  MAX_TAGS_COUNT,
  MAX_OFFERS_COUNT,
  DEFAULT_OFFERS_COUNTRY,
} from "../../../pages/upload/product-binaries/types";

export const offerClient = axios.create({
  baseURL: `${process.env.REACT_APP_EPIC_DEVPORTAL_URL}/api/v2`,
});

const catalogUrl = "/services/catalog";

export function getOffersWithPLSStatuses(
  sandboxId: string,
  cancelToken?: CancelToken
): Promise<{
  elements: Offer[];
}> {
  return offerClient
    .get(`${catalogUrl}/${sandboxId}/offers`, {
      params: {
        count: MAX_OFFERS_COUNT,
        country: DEFAULT_OFFERS_COUNTRY,
        showExpiry: true,
      },
      cancelToken,
    })
    .then((res) => res.data);
}

export interface BulkGetItemsOptions {
  includeSubItemDetails: boolean;
  id: string[];
}

export function bulkGetItems(
  sandboxId: string,
  options: BulkGetItemsOptions
): Promise<BulkItems> {
  return offerClient
    .get(`${catalogUrl}/${sandboxId}/bulk/items`, { params: options })
    .then((res) => res.data.data);
}

export function getSandboxTags(
  sandboxId: string,
  cancelToken?: CancelToken
): Promise<{ elements: SandboxTag[] }> {
  return offerClient
    .get(`${catalogUrl}/${sandboxId}/tags`, {
      params: {
        includeStore: true,
        count: MAX_TAGS_COUNT,
      },
      cancelToken,
    })
    .then((res) => res.data.data);
}

export function putOffer(
  sandboxId: string,
  offerId: string,
  body: UpdateOfferBody
): Promise<Offer> {
  return offerClient
    .put<{ data: Offer }>(
      `${catalogUrl}/${sandboxId}/offers/${offerId}/updateOffer`,
      body
    )
    .then((res) => res.data.data);
}
