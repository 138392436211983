import styled from "styled-components";
import { Link } from "@material-ui/core";
import { ExternalLink } from "@epic-ui/icons";

export const StyledExternalLink = styled(ExternalLink)`
  vertical-align: middle;
`;

export const StyledLearnMoreLink = styled(Link)`
  cursor: pointer;
`;
