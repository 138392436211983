export enum AppSignInState {
  LOADED = 1,
  LOADING = -1,
  ERROR = 0,
}

// TODO: move this logic to redux and delete /managers folder
export class AppSignInStateManager {
  public static isError = (state: AppSignInState) => {
    return state === AppSignInState.ERROR;
  };

  public static isLoaded = (state: AppSignInState) => {
    return state === AppSignInState.LOADED;
  };

  public static isLoading = (state: AppSignInState) => {
    return state === AppSignInState.LOADING;
  };
}
