import * as React from "react";
import { Typography, Box, Collapse, Link, TextField } from "@material-ui/core";
import { useFormikContext, Field } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { FolderSelector, FooterButtons } from "../../../../../components";
import { BinaryUploadPage } from "../../../../common";
import { BinaryUploadField, BinaryUploadHeader } from "../../components";
import { uploaderBridge } from "../../../../../services";
import { FilesAdditionalInformationValue } from "./FilesAdditionalInformationPage";
import { useBinaryUploadData } from "../../data";

export const FilesAdditionalInformationForm: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setAdditionalInformation,
    shouldConfirmationPageBeShown,
    setShouldConfirmationPageBeShown,
  } = useBinaryUploadData();

  const [expanded, setExpanded] = React.useState(false);

  const { values, setFieldValue, handleSubmit } = useFormikContext<
    FilesAdditionalInformationValue
  >();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const selectFile = async (name) => {
    const [uploadFilePath] = await uploaderBridge.openFileDialog();

    setFieldValue(name, uploadFilePath);
  };

  const handleCancel = () => {
    history.push("/upload/game/folders");
  };

  React.useEffect(() => {
    setAdditionalInformation(values);
  }, [values, setAdditionalInformation]);

  React.useEffect(() => {
    if (values.ignoreList || values.attributesList) {
      setExpanded(true);
    }

    !shouldConfirmationPageBeShown && setShouldConfirmationPageBeShown(true);
  }, []);

  return (
    <BinaryUploadPage
      header={<BinaryUploadHeader />}
      footer={
        <FooterButtons
          onClose={handleCancel}
          onConfirm={handleSubmit}
          closeLabel={t("upload_binaries.go_back")}
          confirmLabel={t("upload_binaries.button.next.upload_binary")}
          size="large"
        />
      }
    >
      <form>
        <Box textAlign="center" mb={10}>
          <Box clone color="text.disabled">
            <Typography variant="body1">
              {t("upload_binaries.optional_step")}
            </Typography>
          </Box>
          <Typography variant="body1">
            {t("upload_binaries.additional_information")}
          </Typography>
        </Box>
        <Box mb={6}>
          <BinaryUploadField
            label={t("upload_binaries.launch_arguments")}
            description={
              <Box whiteSpace="pre-line">
                {t("upload_binaries.launch_arguments.description")}
              </Box>
            }
          >
            <Field name="launchArguments">
              {({ field: { value, name } }) => (
                <TextField
                  placeholder={t(
                    "upload_binaries.launch_arguments_placeholder"
                  )}
                  value={value}
                  multiline
                  rows={6}
                  fullWidth
                  margin="dense"
                  onChange={(event) => setFieldValue(name, event.target.value)}
                />
              )}
            </Field>
          </BinaryUploadField>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={6}
        >
          <Typography variant="h4">
            {t("upload_binaries.advanced_settings")}
          </Typography>
          <Link
            component="button"
            type="button"
            color="primary"
            onClick={handleExpandClick}
          >
            {expanded ? t("upload_binaries.hide") : t("upload_binaries.show")}
          </Link>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box mb={6}>
            <BinaryUploadField
              label={t("upload_binaries.attributes_list")}
              description={
                <Box whiteSpace="pre-line">
                  {t("upload_binaries.attributes_list.description")}
                </Box>
              }
            >
              <Field name="attributesList">
                {({ field: { value, name } }) => (
                  <FolderSelector
                    value={value}
                    onSelect={() => selectFile(name)}
                    inputProps={{
                      disabled: true,
                      variant: "outlined",
                      label: value
                        ? ""
                        : t("upload_binaries.path_to_special_file"),
                    }}
                  />
                )}
              </Field>
            </BinaryUploadField>
          </Box>
          <BinaryUploadField
            label={t("upload_binaries.ignore_list")}
            description={
              <Box whiteSpace="pre-line">
                {t("upload_binaries.ignore_list.description")}
              </Box>
            }
          >
            <Field name="ignoreList">
              {({ field: { value, name } }) => (
                <FolderSelector
                  value={value}
                  onSelect={() => selectFile(name)}
                  inputProps={{
                    disabled: true,
                    variant: "outlined",
                    label: value
                      ? ""
                      : t("upload_binaries.path_to_ignore_file"),
                  }}
                />
              )}
            </Field>
          </BinaryUploadField>
        </Collapse>
      </form>
    </BinaryUploadPage>
  );
};
