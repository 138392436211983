import * as React from "react";

interface ContentProps {
  isXOverflow: boolean;
}

type RefObject<T extends HTMLElement = HTMLInputElement> = React.RefObject<T>;

export const useInputOverflow = (ref: RefObject): ContentProps => {
  const [isXOverflow, setIsXOverflow] = React.useState(false);

  React.useEffect(() => {
    if (!ref || !ref.current) {
      setIsXOverflow(false);
      return;
    }

    setIsXOverflow(ref.current.scrollWidth > ref.current.clientWidth);
  }, [ref?.current?.value]);

  return { isXOverflow };
};
