import * as React from "react";
import { Switch, Route, Redirect } from "react-router";
import { useSelector } from "react-redux";

import { PageWrapper } from "../../components/PageWrapper";
import { ModDownloadPage } from "./mod/ModDownloadPage";
import { GameDownloadPage } from "./game/GameDownloadPage";
import {
  selectEntityType,
  selectOrganizationId,
} from "../../store/selectors/app.selectors";

export const DownloadContainer: React.FC = () => {
  const entityType = useSelector(selectEntityType);
  const organizationId = useSelector(selectOrganizationId);

  return (
    <PageWrapper>
      <Switch>
        <Route path="/download/mod">
          <ModDownloadPage />
        </Route>
        <Route path="/download/game">
          <GameDownloadPage />
        </Route>

        {!organizationId && <Redirect to="/organizations" />}
        <Redirect to={`/download/${entityType}`} />
      </Switch>
    </PageWrapper>
  );
};
