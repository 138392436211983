import { Platform } from "@epic-mod-market/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AppParams } from "../../../../services";
import { updateBinaryPlatforms } from "../../../../services/api/binary";
import { selectAppParams } from "../../../../store/selectors/app.selectors";
import { ErrorReferrerPage } from "../types";

export const useUpdateBinaryPlatforms = (buildVersion: string) => {
  const history = useHistory();
  const appParams = useSelector(selectAppParams) as AppParams;

  const handleUpdateBinaryPlatforms = React.useCallback(
    async (selectedPlatforms: Platform[]) => {
      const { organizationid, productid, sandboxid, artifactid } = appParams;
      try {
        await updateBinaryPlatforms({
          ...{
            organizationId: organizationid as string,
            productId: productid as string,
            sandboxId: sandboxid as string,
            artifactId: artifactid as string,
          },
          version: buildVersion as string,
          fields: { platforms: selectedPlatforms },
        });
        history.push("/upload/game/activate-binary-success");
      } catch (e) {
        history.push(`/upload/game/error`, {
          errorcode: e?.code,
          errormessages: e?.message,
          supporturl: "",
          referrerPage: ErrorReferrerPage.ACTIVATE_BINARY,
          buildVersion,
        });
      }
    },
    [appParams, buildVersion, history]
  );
  return handleUpdateBinaryPlatforms;
};
