import { resolveBridgeType, BridgeType } from "./BridgeApi";
import { installerLauncher } from "./launcher/installer.launcher";
import { installerWeb } from "./web/installer.web";

export interface FileOperationView {
  linesprefocus: number;
  linespostfocus: number;
  minlinethreshold: number;
  maxlinethreshold: number;
  width: number;
  bytesperunit: number;
  minbarsize: number;
  maxbarsize: number;
}

export interface FileOperationArgs {
  inrangedrawfileoperations: number;
  totaldownloaded: number;
  totaldownloadrequired: number;
  samplingdatetime: string;
}

export type SubscribeCallback = (args: FileOperationArgs) => void;

export interface InstallerBridge {
  setFileOperationView(view: FileOperationView): Promise<void>;
  subscribe(type: string, callback: SubscribeCallback): Promise<number>;
  unsubscribe(subscriptionId: number): Promise<boolean>;
}

const bridges = {
  [BridgeType.LAUNCHER]: installerLauncher,
  [BridgeType.WEB]: installerWeb,
};

export const installerBridge: InstallerBridge = bridges[resolveBridgeType()];
