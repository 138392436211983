import * as React from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

import {
  BUILD_VERSION_CHARACTER_LIMITS,
  useBinaryUploadData,
} from "../../../../../data";
import { BinaryUploadFoldersFormFields } from "../../../types";
import { BinaryUploadField } from "../../../../../components";

export const BinaryVersionField: React.FC = () => {
  const { setFieldValue, setFieldTouched } = useFormikContext<
    BinaryUploadFoldersFormFields
  >();
  const { t } = useTranslation();

  const {
    binaries: {
      binariesLists: { inactiveBinaries, activeBinaries },
    },
  } = useBinaryUploadData();

  const usedBinaryVersions = React.useMemo(() => {
    return [...inactiveBinaries, ...activeBinaries].map(
      ({ buildVersion }) => buildVersion
    );
  }, [inactiveBinaries, activeBinaries]);

  const validateBinaryVersion = React.useCallback(
    (binaryVersion: string) => {
      const { max, min } = BUILD_VERSION_CHARACTER_LIMITS;
      const regexpPattern = `^[a-z0-9-+_.]{${min},${max}}$`;
      const allowedSymbolsRegExp = new RegExp(regexpPattern, "gi");

      if (!binaryVersion) {
        return t("common.error.required");
      }

      if (!allowedSymbolsRegExp.test(binaryVersion)) {
        return t("upload_binaries.folders.errors.binary_version.characters", {
          characterLimit: max,
        });
      }

      if (usedBinaryVersions.includes(binaryVersion)) {
        return t("upload_binaries.folders.errors.binary_version.duplicate");
      }
    },
    [t, usedBinaryVersions]
  );

  return (
    <BinaryUploadField
      asterisk
      label={t("upload_binaries.folders.binary_version.label")}
      description={t("upload_binaries.folders.binary_version.description")}
    >
      <Field name="buildVersion" validate={validateBinaryVersion}>
        {({ field: { value, name }, form: { errors } }: FieldProps) => {
          const error = String(errors.buildVersion ?? "");

          return (
            <TextField
              placeholder={t("upload_binaries.folders.binary_version.label")}
              value={value}
              error={Boolean(error)}
              helperText={error}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(event) => setFieldValue(name, event.target.value)}
              fullWidth
            />
          );
        }}
      </Field>
    </BinaryUploadField>
  );
};
