import { DownloadBridge } from "../downloaderBridge";
import { DownloadBridgeMockService } from "../DownloadBridgeMockService";

const MockService = new DownloadBridgeMockService();

export const downloadWeb: DownloadBridge = {
  registerSignInCompleteCallback: MockService.registerSignInCompleteCallback,
  registerDownloadCompleteCallback:
    MockService.registerDownloadCompleteCallback,
  onCancel: MockService.onCancel,
  onPause: MockService.onPause,
  onResume: MockService.onResume,
  onClose: MockService.onClose,
  onOpenDownloadFolder: MockService.onOpenDownloadFolder,
  onChangeDownloadFolder: MockService.onChangeDownloadFolder,
  openSupportPage: MockService.openSupportPage,
  getDownloadFolder: MockService.getDownloadFolder,
  getDownloadProgressState: MockService.getDownloadProgressState,
  download: MockService.download,
};
