import React from "react";
import { createGlobalStyle, css } from "styled-components";

const customScrollbar = css`
  /* FF */
  & {
    scrollbar-color: #8b8b8b;
    scrollbar-width: thin;
  }

  /* Webkit */
  &::-webkit-scrollbar {
    width: 14px;
    height: 16px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: #8b8b8b;
  }
`;

const Style = createGlobalStyle`
  * {
    box-sizing: border-box;
    ${customScrollbar}
  }

  body {
    margin: 0;
    font-family: 'Brutal', 'Helvetica Neue',sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #212121;
  }
`;

const getFontSetByType = (type: "Regular" | "Medium" | "Bold"): string => {
  const name = "BrutalType";
  const path = `${name}-${type}/${name}-${type}`;

  // Use 'require' to avoid async dynamic imports
  /* eslint-disable @typescript-eslint/no-var-requires */
  const woff = require(`./assets/fonts/${path}.woff`);
  const woff2 = require(`./assets/fonts/${path}.woff2`);
  /* eslint-enable  @typescript-eslint/no-var-requires */

  return `url('${woff2}') format('woff2'), url('${woff}') format('woff');`;
};

// Avoid to use styled-component here, as it leads to font blinking in Chrome on hover
const Font: React.FC = () => (
  <style>{`
    @font-face {
      font-family: 'Brutal';
      font-weight: 400;
      src: ${getFontSetByType("Regular")}
    }

    @font-face {
      font-family: 'Brutal';
      font-weight: 500;
      src: ${getFontSetByType("Medium")}
    }

    @font-face {
      font-family: 'Brutal';
      font-weight: 700;
      src: ${getFontSetByType("Bold")}
    }
  `}</style>
);

export const GlobalStyles = () => (
  <>
    <Font />
    <Style />
  </>
);
