import styled from "styled-components";

export const ProgressBar = styled.div<{ progress: number }>`
  position: relative;
  height: 5px;
  background: #484848;
  width: 100%;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #0078ff;
    transform: translateX(${({ progress }) => progress}%);
    transition: transform 0.3s ease-in-out;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
