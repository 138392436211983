import styled from "styled-components";

export const NotificationContainer = styled.div`
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(p) => p.theme.spacing(-12, -12, 8)};
  & .MuiSvgIcon-root {
    color: ${(p) => p.theme.palette.text.primary};
    font-size: 2rem;
    margin-right: ${(p) => p.theme.spacing(2)}px;
  }
`;
