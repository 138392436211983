import * as React from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  FormControl,
  TextField,
  TextFieldProps,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ellipsis } from "../styles";
import { useOverflow, useInputOverflow } from "../pages/hooks";

const TextFieldStyled = styled(TextField)`
  & .MuiInputBase-input {
    width: 80%;

    &:not(:focus) {
      direction: rtl;
      text-align: left;
      unicode-bidi: plaintext;
      ${ellipsis()}
    }
  }

  & .MuiInputBase-root {
    font-size: ${(p) => p.theme.typography.caption.fontSize};
    border-radius: 4px;

    &.Mui-disabled {
      opacity: 1;
    }
  }

  & .MuiFilledInput-underline:before {
    display: none;
  }

  & .MuiFormHelperText-root.Mui-disabled {
    opacity: 1;
  }
`;

const Adornment = styled(Button)`
  && {
    color: ${(p) => p.theme.palette.primary.main};
    font-weight: normal;
    text-transform: none;

    &:hover {
      background: none;
    }
  }

  &&& {
    font-size: ${(p) => p.theme.typography.caption.fontSize};
  }
`;

const FolderPath = styled.div`
  width: 80%;
  direction: rtl;
  text-align: left;
  ${ellipsis()}
`;

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: 250,
    margin: theme.spacing(2, 0),
  },
}));

interface FolderSelectorProps {
  value: string;
  onSelect: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: TextFieldProps;
  error?: string;
}

export const FolderSelector: React.FC<FolderSelectorProps> = ({
  value,
  onSelect,
  onChange,
  inputProps = {},
  error,
}) => {
  const classes = useStyles();
  const folderPathPreviewRef: React.RefObject<HTMLDivElement> = React.useRef<
    HTMLDivElement
  >(null);
  const folderPathInputRef: React.RefObject<HTMLInputElement> = React.useRef<
    HTMLInputElement
  >(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOnMouseEnter = () => {
    setIsOpen(true);
  };

  const handleOnMouseLeave = () => {
    setIsOpen(false);
  };

  const { isXOverflow: shouldFolderPathTooltipOpen } = useOverflow(
    folderPathPreviewRef
  );
  const { isXOverflow: shouldFolderInputTooltipOpen } = useInputOverflow(
    folderPathInputRef
  );

  const [
    isFolderPathInputFocused,
    setIsFolderPathInputFocused,
  ] = React.useState(false);

  const isTooltipOpen = inputProps?.disabled
    ? isOpen
    : isOpen && !isFolderPathInputFocused;

  return (
    <FormControl fullWidth>
      <Tooltip
        title={
          shouldFolderPathTooltipOpen || shouldFolderInputTooltipOpen
            ? value
            : ""
        }
        classes={{ tooltip: classes.tooltip }}
        disableHoverListener
        disableFocusListener
        disableTouchListener
        open={isTooltipOpen}
        arrow
      >
        <TextFieldStyled
          variant="outlined"
          fullWidth
          error={Boolean(error)}
          helperText={error}
          InputProps={
            inputProps?.disabled
              ? {
                  inputComponent: () => (
                    <Box
                      display="flex"
                      width="100%"
                      height="60px"
                      alignItems="center"
                      pl={3}
                      pr={1}
                    >
                      <FolderPath
                        ref={folderPathPreviewRef}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                      >
                        {value}
                      </FolderPath>
                      <Adornment variant="text" size="small" onClick={onSelect}>
                        {t("common.change")}
                      </Adornment>
                    </Box>
                  ),
                }
              : {
                  endAdornment: (
                    <Adornment variant="text" size="small" onClick={onSelect}>
                      {t("common.change")}
                    </Adornment>
                  ),
                  disableUnderline: true,
                  onMouseEnter: handleOnMouseEnter,
                  onMouseLeave: handleOnMouseLeave,
                  onFocus: () => setIsFolderPathInputFocused(true),
                  onBlur: () => setIsFolderPathInputFocused(false),
                }
          }
          value={value}
          inputRef={folderPathInputRef}
          type="string"
          onChange={onChange}
          {...inputProps}
        />
      </Tooltip>
    </FormControl>
  );
};
